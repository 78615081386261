export const SAVE_ACTIVE_ITEMS = 'SAVE_ACTIVE_ITEMS';
export const GET_ACTIVE_ITEMS = 'GET_ACTIVE_ITEMS';
export const REMOVE_ACTIVE_ITEM = 'REMOVE_ACTIVE_ITEM';

export const saveExpandedItems = items => ({
    type: SAVE_ACTIVE_ITEMS,
    payload: {
        items,
    },
});

export const removeItem = (id, view) => ({
    type: REMOVE_ACTIVE_ITEM,
    payload: {
        id,
        view,
    },
});
