import _ from 'lodash';
import { calculateSpecificity } from 'clear-cut';

// Almost like Atoms CommandRegistry https://atom.io/docs/api/v0.196.0/CommandRegistry

export default class CommandRegistry {
    constructor() {
        this.commands = [];
        this.inlineCommands = [];
        // command: { target, name, callback }
    }

    add(target, commandName, callback) {
        let command = {
            target,
            name: commandName,
            callback,
        };

        let commands;
        if (target instanceof HTMLElement) {
            this.inlineCommands.unshift(command);
            commands = this.inlineCommands;
        } else {
            this.commands.unshift(command);
            commands = this.commands;
        }

        return {
            dispose() {
                let idx = commands.indexOf(command);
                if (idx !== -1) {
                    commands.splice(idx, 1);
                }
            },
        };
    }

    findCommands(params) {
        console.warning('Method CommandRegistry::findCommands is not implemented yet.');
    }

    dispatch(target, commandName) {
        // Todo: should create a full syntetic event here...
        let event = {
            target: target,
            preventDefault: () => {},
        };

        this.dispatchEvent(event, commandName);
    }

    dispatchEvent(event, commandName) {
        let inlineCommands = _.filter(this.inlineCommands, { name: commandName });

        let commands = _.chain(this.commands)
            .filter({ name: commandName })
            .sortBy(cmd => calculateSpecificity(cmd.target))
            .value();

        if (commands.length === 0 && inlineCommands.length === 0) {
            console.warn(
                'Tried to dispatch non-existing command:',
                commandName,
                ' You probably plan to add a listener to that command?'
            );
            //then we call the function defined in KeymapResolver.onKeyDown (event.abortKeyBinding = () => { abort = true; };)
            //which results in event.preventDefault _not_ being called by KeyMapResolver.onKeyDown
            if (typeof event.abortKeyBinding === 'function') {
                event.abortKeyBinding();
            }
            return;
        }

        let shouldStop = false;
        event.stopPropagation = () => {
            shouldStop = true;
        };

        let dispatchedCommands = [];
        let dispatchQueue = [];
        let currentTarget = event.target;
        while (true) {
            inlineCommands.map(command => {
                if (shouldStop) {
                    return;
                }

                if (currentTarget === command.target) {
                    command.callback.call(currentTarget, event);
                    dispatchedCommands.push(command);
                }
            });

            commands.map(command => {
                if (shouldStop) {
                    return;
                }

                if (currentTarget.matches && currentTarget.matches(command.target)) {
                    command.callback.call(currentTarget, event);
                    dispatchedCommands.push(command);
                }
            });

            // Don't dispatch a command more than once.
            commands = _.filter(commands, command => dispatchedCommands.indexOf(command) === -1);
            inlineCommands = _.filter(inlineCommands, command => dispatchedCommands.indexOf(command) === -1);

            if (currentTarget === window || shouldStop || (commands.length === 0 && inlineCommands.length === 0)) {
                break;
            }
            currentTarget = currentTarget.parentNode || window;
        }
    }
}
