import React from 'react';
import _ from 'lodash';

export const connectBackbone = (propsToCollection, collectionToProps, dontFetch = false) => component => {
    return class extends React.PureComponent {
        static displayName = `connectBackbone(${component.displayName || component.name || 'Component'})`;

        state = {
            props: {},
        };

        UNSAFE_componentWillMount() {
            this.willUnmount = false;
            this.somethingChangedDebounced = _.debounce(this.somethingChanged);

            this.collection = propsToCollection(this.props);

            if (process.env.NODE_ENV !== 'production') {
                this.constructor.displayName = 'connectBackbone(' + this.collection.getTypeForDebugging() + ')';
            }

            if (!this.collection) {
                console.warn('Somewhere, a connectBackbone propsToCollection function returned null');
                this.somethingChanged();
                return;
            }

            this.collection.on('sync update reset change', this.somethingChangedDebounced, this);

            if (!dontFetch && !this.collection.hasSynced()) {
                this.collection.fetch();
            }

            this.somethingChanged();
        }

        UNSAFE_componentWillReceiveProps(nextProps) {
            let nextCollection = propsToCollection(nextProps);
            if (nextCollection !== this.collection) {
                this.collection.off(null, null, this);

                if (this.collection.unsubscribe) {
                    this.collection.unsubscribe();
                }

                this.collection = nextCollection;
                this.collection.on('sync update reset change', this.somethingChangedDebounced, this);

                if (!dontFetch && !this.collection.hasSynced()) {
                    this.collection.fetch();
                }
                this.somethingChanged();
            }
        }

        componentWillUnmount() {
            this.willUnmount = true;
            this.collection.off(null, null, this);

            // Since the somethingChanged function is called through _.debounce,
            // it can be called _after_ component was unmounted even though we
            // removed the event listener. So we'll just replace the
            // somethingChanged function with a noop to prevent React warnings
            // about setting state on unmounted component.
            this.somethingChanged = () => {};

            if (this.collection.unsubscribe) {
                this.collection.unsubscribe();
            }

            this.collection = null;
        }

        somethingChanged = (event = 'unknown') => {
            // When component is about to unmount, it still happens
            // that the warning mentioned in the comment in componentWillUnmount()
            // happens. This fixes the problem, preventing state to be set
            if (!this.willUnmount) {
                this.setState({
                    props: collectionToProps(this.collection, this.props),
                });
            }
        };

        render() {
            return React.createElement(component, {
                ...this.props,
                ...this.state.props,
            });
        }
    };
};

export const referenceMaps = {
    listOfRefMap: [],
    getNewRefMap() {
        this.listOfRefMap.push({});
        return this.listOfRefMap[this.listOfRefMap.length - 1];
    },
    clearAllRefMaps() {
        // loop through all the maps
        for (let i = 0; i < this.listOfRefMap.length; i++) {
            // and delete all entrys in each map
            for (const key of Object.keys(this.listOfRefMap[i])) {
                delete this.listOfRefMap[i][key];
            }
            // the maps still exists, which they need to, because makeUnsubscribableCollection would throw if its suddely undefined,
            // but they are now empty
        }
    },
};

// Lets you reference count Backbone Collections so they can be removed when
// they are not used any more.
//
// usage:
//
// set up a subscribable collection:
//
// const subscribeTicketSalesCollection = makeUnsubscribableCollection(
//     ['partnerId', 'eventId'],
//     (partnerId, eventId) => new TicketSalesCollection(null, { partnerId, eventId })
// )
//
// get a subscribable collection:
//
// const collection = subscribeTicketSalesCollection(113, 10042);
//
// unsubscribe when not used anymore
//
// collection = collection.unsubscribe();
//

export const makeUnsubscribableCollection = (requiredArgs, generator) => {
    let refMap = referenceMaps.getNewRefMap();
    let refCountMap = referenceMaps.getNewRefMap();

    return (...args) => {
        _.forEach(requiredArgs, (arg, index) => {
            if (args.length <= index) {
                throw new Error(`Argument ${arg} is required when subscribing to collection. See stacktrace.`);
            }
        });

        const id = args.join('-');
        if (refMap[id]) {
            refCountMap[id]++;
            return refMap[id];
        } else {
            // The collection isn't instantiated, so we'll do that now
            refCountMap[id] = 1;
            refMap[id] = generator(...args);

            // Add the unsubscribe method on the object:
            refMap[id].unsubscribe = () => {
                refCountMap[id]--;

                if (refCountMap[id] < 1) {
                    delete refCountMap[id];
                    delete refMap[id];
                }

                return null;
            };

            return refMap[id];
        }
    };
};

export const makeUnsubscribableModel = makeUnsubscribableCollection;
