// Load configs from .env file.

/* eslint-disable */
function warn(msg) {
    if (console && console.warn) {
        console.warn(msg);
    }
}
/* eslint-enable */

if (!process.env.REACT_APP_SEGMENT_KEY) {
    warn('Config REACT_APP_SEGMENT_KEY is not set in .env. No usage statistics will be logged.');
}
if (!process.env.REACT_APP_SENTRY_DSN) {
    warn('Config REACT_APP_SENTRY_DSN is not set in .env. No errors will be logged to Sentry.');
}
if (!process.env.REACT_APP_SENTRY_RELEASE_NAME) {
    warn(
        'Config REACT_APP_SENTRY_RELEASE_NAME is not set. Errors will not be sorted under release and will not contain readable stacktraces on Sentry.'
    );
}

export default {
    segmentKey: process.env.REACT_APP_SEGMENT_KEY,
    sentryDsn: process.env.REACT_APP_SENTRY_DSN,
    releaseName: process.env.REACT_APP_SENTRY_RELEASE_NAME,

    // HTTP status codes (instead of having these as magic numbers around everywhere)
    status: {
        FORBIDDEN: 403,
    },

    api: {
        // Sessions expires after 120 minutes according to laravel
        // config, so we'll do a ping a little before that...
        sessionTimeout: 110 * 60 * 1000,
    },

    // Animation curve that we can use everywhere (?)
    anim: {
        curve: [0.55, 0, 0.1, 1],
    },

    // Api endpoints.
    // DEPRECATED!
    // Just write the url directly in your model in /global/models/
    url: {
        api: {
            auth: '/api/v1/auth/',
            crm: '/api/v1/crm/',
            renters: '/api/v1/renters/',
            rentershow: '/api/v1/show/',
            rentershows: '/api/v1/shows/',
            rentersales: '/api/v1/sales/tickets',
            tickets: '/api/v1/sales/tickets/',
            users: '/api/v1/users/',
            user: '/api/v1/user/',
            test: 'api/bare-tullball',
            modules: '/api/v1/modules/',
            module: '/api/v1/module/',
            partners: '/api/v1/partners/',
            partner: '/api/v1/partner/',
            useradmin: '/api/v1/useradmin/',
            useradminUsers: '/api/v1/useradmin/users/',
            useradminUser: '/api/v1/useradmin/user/',
            locations: '/api/v1/locations/',
            location: '/api/v1/location/',
            ticketcategorytemplates: '/api/v1/ticketcategorytemplates/',
            ticketcategorytemplate: '/api/v1/ticketcategorytemplate/',
        },
    },
};
