import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';

/*
 * General DX Delete button component
 */

class DeleteButton extends React.PureComponent {
    static propTypes = {
        onClick: PropTypes.func,
        text: PropTypes.string,
        disabled: PropTypes.bool,
    };

    static defaultProps = {
        onClick: () => {},
        text: 'Slett',
    };

    render() {
        return (
            <Button
                onClick={this.props.onClick}
                text={this.props.text}
                icon="fa fa-trash"
                disabled={this.props.disabled}
                type="delete"
            />
        );
    }
}

export default DeleteButton;
