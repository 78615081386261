const initalState = {
    serviceWorkerFirstTimeInstallation: false,
    registrationWaiting: null,
};

export const SW_INIT = 'SW_INIT';
export const SW_UPDATED = 'SW_UPDATED';
export const SW_WAITING = 'SW_WAITING';

export const serviceWorkerStatus = (state = initalState, action) => {
    switch (action.type) {
        // happens only at first visit to page with a SW compatible browser
        // when sw is installed for the first time in the browser
        case SW_INIT:
            return {
                ...state,
                serviceWorkerFirstTimeInstallation: true,
            };
        // waiting happens if user navigates to dxweb and there is a waiting SW,
        // typically if they have ignored the SW_UPDATE notification,
        case SW_WAITING:
        // when we have downloaded a neew version of the SW
        case SW_UPDATED:
            return {
                ...state,
                registrationWaiting: action.payload,
            };
        default:
            return state;
    }
};
