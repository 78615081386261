import { combineReducers } from 'redux';

import sidebar from './sidebar';
import screen from './screen';
import GDPRRoadblock from './GDPRRoadblock';

export default combineReducers({
    sidebar,
    screen,
    GDPRRoadblock,
});
