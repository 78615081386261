import React from 'react';
import PropTypes from 'prop-types';
import GDPR from './components/Agreement/GDPR';

class Roadblock extends React.Component {
    static propTypes = {
        /* {bool} show roadblock **/
        displayRoadblock: PropTypes.bool.isRequired,
        /* {func} Update state of road block **/
        updateGDPRDisplayRoadBlock: PropTypes.func.isRequired,
    };
    render() {
        return (
            <React.Fragment>
                {this.props.displayRoadblock && (
                    <GDPR updateGDPRDisplayRoadBlock={this.props.updateGDPRDisplayRoadBlock} />
                )}
            </React.Fragment>
        );
    }
}

export default Roadblock;
