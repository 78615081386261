import * as types from '../actions/touchGroups';

const initialTouchGroups = {
    touchGroups: [],
};

/**
 *
 * @param {salesPerProductPerDay} state
 * @param action
 * @returns {salesPerProductPerDayReducer}
 */
const touchGroups = (state = initialTouchGroups, action) => {
    switch (action.type) {
        case types.RECEIVE_TOUCH_GROUPS: {
            const touchGroups = action.payload;
            return {
                ...state,
                touchGroups,
            };
        }
        default:
            return state;
    }
};

export default touchGroups;
