import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from './Button';

export default class PrimaryButton extends Component {
    static propTypes = {
        onClick: PropTypes.func,
        text: PropTypes.string.isRequired,
        disabled: PropTypes.bool,
        icon: PropTypes.string,
    };

    static defaultProps = {
        onClick: () => {},
    };

    render() {
        return (
            <Button
                onClick={this.props.onClick}
                text={this.props.text}
                disabled={this.props.disabled}
                type="primary"
                icon={this.props.icon}
            />
        );
    }
}
