import {
    REQUEST_NKN_CATEGORY,
    RECEIVE_NKN_CATEGORY,
    REQUEST_NKN_CATEGORIES,
    RECEIVE_NKN_CATEGORIES,
} from '../actions/nknCategory';

const initialState = {
    items: [],
    isLoading: false,
};

const nknCategory = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_NKN_CATEGORY:
            return {
                ...state,
                itemsLoading: false,
            };
        case RECEIVE_NKN_CATEGORY:
            return {
                ...state,
                itemsLoading: false,
                items: state.items.map(item => {
                    if (action.response.id !== item.id) {
                        return {
                            ...item,
                        };
                    }
                    return action.response;
                }),
            };
        //FETCH all nknCategory categories
        case REQUEST_NKN_CATEGORIES:
            return {
                ...state,
                itemsLoading: true,
            };
        case RECEIVE_NKN_CATEGORIES:
            return {
                ...state,
                items: action.response.data,
                itemsLoading: false,
            };
        default:
            return state;
    }
};

export default nknCategory;
