import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ModuleLink from './ModuleLink';
import UserContextMenu from './UserContextMenu';
import AnnounceKit from 'announcekit-react';

const Spacer = () => <li>&nbsp;</li>;

export default class ModuleList extends Component {
    static propTypes = {
        modules: PropTypes.arrayOf(PropTypes.shape({})),
        tooltip: PropTypes.bool,
        partnerId: PropTypes.number,
        renterId: PropTypes.number,
        onClickLink: PropTypes.func.isRequired,
    };

    static defaultProps = {
        modules: [],
        tooltip: true,
    };

    render() {
        return (
            <div className="partner">
                <ul className="nav nav-pills nav-stacked">
                    <UserContextMenu />
                    <li id="announcekit-badge-text">
                        <AnnounceKit widget={'https://changelog.dx.no/widgets/v2/1ZOXoA'}>
                            <span>
                                <i className="fa fa-info-circle" />
                                &nbsp; Oppdateringer
                            </span>
                        </AnnounceKit>
                    </li>
                    <Spacer />
                    {this.props.modules.map((module, key) =>
                        module.type === 'spacer' ? (
                            <Spacer key={'spacer' + key} />
                        ) : (
                            <ModuleLink
                                partnerId={this.props.partnerId}
                                renterId={this.props.renterId}
                                {...module}
                                key={module.url}
                                tooltip={this.props.tooltip}
                                onClickLink={this.props.onClickLink}
                            />
                        )
                    )}
                </ul>
            </div>
        );
    }
}
