import * as types from '../../actions/products';

const InitialSalesReport = {
    isLoading: false,
    salesPerDate: [],
    salesPerProductPerDay: [],
};

const lastYearAndThisMonthLastYearInitialSalesReport = {
    isLoading: false,
    thisMonthLastYearGrossSalesExVat: 0,
    lastYearGrossSalesExVat: 0,
    currentMonthTotalRevenue: 0,
    currentYearTotalRevenue: 0,
    todayTotalRevenue: 0,
    yesterdayTotalRevenue: 0,
};

/**
 *
 * @param {lastMonthSalesReportReducer} state
 * @param action
 * @returns {lastMonthSalesReportReducer}
 */
const productSalesReportSoFarThisYear = (state = InitialSalesReport, action) => {
    switch (action.type) {
        case types.REQUEST_SO_FAR_THIS_YEAR_SALES_REPORT: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case types.RECEIVE_SO_FAR_THIS_YEAR_SALES_REPORT: {
            const report = action.payload.data;
            const salesPerProductPerDay = report.salesPerProductPerDay;
            const salesPerDate = report.salesPerDate;
            return {
                ...state,
                isLoading: false,
                salesPerProductPerDay,
                salesPerDate,
            };
        }
        default:
            return state;
    }
};

/**
 *
 * @param {lastMonthSalesReportReducer} state
 * @param action
 * @returns {lastMonthSalesReportReducer}
 */
const lastYearAndThisMonthLastYearSalesReport = (state = lastYearAndThisMonthLastYearInitialSalesReport, action) => {
    switch (action.type) {
        case types.REQUEST_LAST_YEAR_AND_CURRENT_MONTHS_LAST_YEAR_SALES_REPORT: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case types.RECEIVE_LAST_YEAR_AND_CURRENT_MONTHS_LAST_YEAR_SALES_REPORT: {
            return {
                ...state,
                isLoading: false,
                ...action.payload.data,
            };
        }
        default:
            return state;
    }
};
export { productSalesReportSoFarThisYear, lastYearAndThisMonthLastYearSalesReport };
