import dx from '../dx';

import {
    INTERCOM_UNREAD_COUNT_CHANGE,
    RECEIVE_USER_DATA,
    SET_CONTEXT,
    SET_MODULES,
    SET_PARTNERS,
    SET_USER_ROLES,
} from '../actions/auth';

export const updateIntercomUnreadCount = count => ({
    type: INTERCOM_UNREAD_COUNT_CHANGE,
    count,
});

const setUser = (user, isSudo = false) => ({
    type: RECEIVE_USER_DATA,
    user,
    isSudo,
});

const setRoles = roles => ({
    type: SET_USER_ROLES,
    roles,
});

const setPartners = partners => ({
    type: SET_PARTNERS,
    partners,
});

const setModules = modules => ({
    type: SET_MODULES,
    modules,
});

const setContext = ({ partner, renter }) => ({
    type: SET_CONTEXT,
    partner,
    renter,
});

export const setInitialAuthData = auth => dispatch => {
    const ninjaModeId = dx.utils.getNinjaId();
    dispatch(setUser(auth.auth, ninjaModeId));
    dispatch(setRoles(auth.roles));
    dispatch(setPartners(auth.partners));
    dispatch(setModules(auth.modules));
    dispatch(setContext(auth.lastContext));
};
