import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Toggler extends Component {
    static propTypes = {
        isExpanded: PropTypes.bool,
        isMobile: PropTypes.bool,
        onToggle: PropTypes.func,
    };

    static defaultProps = {
        isExpanded: true,
        isMobile: false,
        onToggle: () => {
            console.warn('No toggle function passed to Toggler component');
        },
    };

    render() {
        return (
            <span role="button" className="sidebar-toggle" onClick={this.props.onToggle}>
                {this.props.isExpanded && (
                    <span>
                        <i id="icon-compress" className="fa fa-caret-left" />{' '}
                    </span>
                )}

                <i id="main-icon" className="fa fa-bars" />

                {!this.props.isExpanded && !this.props.isMobile && (
                    <span>
                        {' '}
                        <i id="icon-expand" className="fa fa-caret-right" />
                    </span>
                )}
            </span>
        );
    }
}
