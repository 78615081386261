import * as types from '../../actions/products';

const initialKioskReportState = {
    isLoading: false,
    from: '',
    to: '',
    salesPerDate: [],
    salesPerProductGroup: [],
    salesPerProduct: [],
    salesPerProductPerDay: [],
};

/**
 *
 * @param {KioskReportReducer} state
 * @param action
 * @returns {KioskReportReducer}
 */
const kioskReport = (state = initialKioskReportState, action) => {
    switch (action.type) {
        case types.REQUEST_KIOSKREPORT: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case types.RECEIVE_KIOSKREPORT: {
            const kioskReport = action.payload.data;
            const salesPerProductGroup = kioskReport.salesPerProductGroup;
            const salesPerProduct = kioskReport.salesPerProduct;
            const salesPerProductPerDay = kioskReport.salesPerProductPerDay;
            const salesPerDate = kioskReport.salesPerDate;
            return {
                ...state,
                isLoading: false,
                from: action.payload.from,
                to: action.payload.to,
                salesPerProductGroup,
                salesPerProduct,
                salesPerProductPerDay,
                salesPerDate,
            };
        }
        default:
            return state;
    }
};

export default kioskReport;
