const SELECT_TEMPLATE = 'SELECT_TEMPLATE';
const CREATE_TEMPLATE = 'CREATE_TEMPLATE';

const CREATE_NEW_ZONE = 'CREATE_NEW_ZONE';

const FETCH_TEMPLATES = 'FETCH_TEMPLATES';
const SAVE_TEMPLATES = 'SAVE_TEMPLATES';

// seatmap logic should be moved to global
const REQUEST_SEATMAP = 'REQUEST_SEATMAP';
const RECEIVE_SEATMAP = 'RECEIVE_SEATMAP';

const SEATS_WAS_SELECTED = 'SEATS_WAS_SELECTED';
const ADD_SEAT_TO_SELECTION = 'ADD_SEAT_TO_SELECTION';
const REMOVE_SEAT_FROM_SELECTION = 'REMOVE_SEAT_FROM_SELECTION';
const ZONE_SETTINGS_WAS_CHANGED = 'ZONE_SETTINGS_WAS_CHANGED';

const SEATS_WAS_ADDED_TO_ZONE = 'SEATS_WAS_ADDED_TO_ZONE';
const SEATS_WAS_REMOVED_FROM_ZONE = 'SEATS_WAS_REMOVED_FROM_ZONE';

const zoneColors = ['#98d5ff', '#8ddd98', '#ffc914', '#2499e8', '#52aa5e', '#f78e00', '#09609b', '#2b7e67', '#d31000'];

export {
    RECEIVE_SEATMAP,
    REQUEST_SEATMAP,
    CREATE_TEMPLATE,
    SELECT_TEMPLATE,
    zoneColors,
    CREATE_NEW_ZONE,
    SEATS_WAS_SELECTED,
    SEATS_WAS_ADDED_TO_ZONE,
    SEATS_WAS_REMOVED_FROM_ZONE,
    FETCH_TEMPLATES,
    SAVE_TEMPLATES,
    ADD_SEAT_TO_SELECTION,
    REMOVE_SEAT_FROM_SELECTION,
    ZONE_SETTINGS_WAS_CHANGED,
};
