import {
    SEARCH_INPUT_WAS_FOCUSED,
    SEARCH_INPUT_WAS_UNFOCUSED,
    SIDEBAR_TOGGLED,
    TOGGLER_TOGGLED,
} from '../../actions/sidebarActions';

const getSidebarStateFromStorage = () => {
    // The expanded state is saved in both sessionStorage and localStorage.
    // When the user navigates to another page, we first check the
    // sessionStorage, so the state of the sidebar will be consistent per
    // browser tab/window. But if he opens a new window, it will be read
    // from localStorage.
    let expanded = null;

    // Sidebar should always be collapsed when using mobile
    if (window.matchMedia('only screen and (max-device-width: 1024px)').matches) {
        expanded = false;
    }

    // Sidebar should always be collapsed when using mobile
    if (expanded === null && window.sessionStorage) {
        let value = window.sessionStorage.getItem('sidebarExpanded');

        if (value !== null) {
            expanded = value === 'true';
        }
    }
    // If it wasn't set for this window, we'll check if it is set in
    // localStorage
    if (expanded === null && window.localStorage) {
        let value = window.localStorage.getItem('sidebarExpanded');

        if (value !== null) {
            expanded = value === 'true';
        }
    }
    // Default value for expanded
    if (expanded === null) {
        expanded = true;
    }

    try {
        // We also need to ensure that the current state is stored in
        // sessionStorage, so that if the user toggles the sidebar in another
        // window, that should not affect this window on next pageload.
        if (window.sessionStorage) {
            window.sessionStorage.setItem('sidebarExpanded', expanded);
        }
    } catch (err) {
        // This is probably just Safari in Private Mode whining about
        // QuotaExceeded. It is safe to ignore that error, but it is almost
        // impossible to detect it:
        // http://chrisberkhout.com/blog/localstorage-errors/
    }
    return expanded;
};

const sidebar = (
    state = {
        expanded: getSidebarStateFromStorage(),
        togglerVisible: true,
        /* this belongs in a 'header' reducer */
        searchHasFocus: false,
    },
    action
) => {
    switch (action.type) {
        case SIDEBAR_TOGGLED: {
            return {
                ...state,
                expanded: action.isExpanded,
            };
        }
        case TOGGLER_TOGGLED: {
            return {
                ...state,
                togglerVisible: action.visible,
            };
        }
        case SEARCH_INPUT_WAS_FOCUSED: {
            return {
                ...state,
                searchHasFocus: true,
            };
        }
        case SEARCH_INPUT_WAS_UNFOCUSED: {
            return {
                ...state,
                searchHasFocus: false,
            };
        }
        default: {
            return state;
        }
    }
};

export default sidebar;
