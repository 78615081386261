import {
    INTEGRATIONS_FETCH_REQUEST,
    INTEGRATIONS_FETCH_SUCCEEDED,
    INTEGRATIONS_FETCH_FAILED,
    INTEGRATION_REMOVE_REQUEST,
    INTEGRATION_REMOVE_SUCCEEDED,
    INTEGRATION_REMOVE_FAILED,
    INTEGRATION_ERROR_CLEAR,
    INTEGRATION_ADD_REQUEST,
    INTEGRATION_ADD_SUCCEEDED,
    INTEGRATION_ADD_FAILED,
} from '../actions/integrations';
import { t } from '../locale';

const initialState = {
    items: [],
    isLoadingIntegrations: false,
    isLoadingRemoveIntegration: false,
    isLoadingAddIntegration: false,
    error: '',
};

const integrations = (state = initialState, action) => {
    switch (action.type) {
        case INTEGRATIONS_FETCH_REQUEST:
            return {
                ...state,
                isLoadingIntegrations: true,
            };
        case INTEGRATIONS_FETCH_SUCCEEDED:
            return {
                ...state,
                isLoadingIntegrations: false,
                items: action.response,
            };
        case INTEGRATIONS_FETCH_FAILED:
            return {
                ...state,
                isLoadingIntegrations: false,
                error: t('Kunne ikke hente integrasjoner, får ikke kontakt med server.'),
            };

        case INTEGRATION_REMOVE_REQUEST:
            return {
                ...state,
                isLoadingRemoveIntegration: true,
                items: [
                    ...state.items.filter(i => i.integrationId !== action.integrationId),
                    {
                        ...state.items.find(i => i.integrationId === action.integrationId),
                        isAdded: false,
                        apiKey: null,
                    },
                ],
            };
        case INTEGRATION_REMOVE_SUCCEEDED:
            return {
                ...state,
                isLoadingRemoveIntegration: false,
                //nothing more to do because we did it optimistically
            };
        case INTEGRATION_REMOVE_FAILED:
            return {
                ...state,
                isLoadingRemoveIntegration: false,
                items: [
                    ...state.items.filter(i => i.integrationId !== action.integrationId),
                    { ...state.items.find(i => i.integrationId === action.integrationId), isAdded: false },
                ],
                error: t('Kunne ikke fjerne integrasjon, får ikke kontakt med server.'),
            };
        //CLEAR ERROR
        case INTEGRATION_ERROR_CLEAR:
            return {
                ...state,
                error: '',
            };
        //ADD integration (simple with copy-pasted-api-key, mailchimp adds in another way)
        case INTEGRATION_ADD_REQUEST:
            return {
                ...state,
                items: [
                    ...state.items.filter(i => i.integrationId !== action.integrationId),
                    {
                        ...state.items.find(i => i.integrationId === action.integrationId),
                        isAdded: true,
                        apiKey: action.apiKey,
                    },
                ],
                isLoadingAddIntegration: true,
            };
        case INTEGRATION_ADD_SUCCEEDED:
            return {
                ...state,
                isLoadingAddIntegration: false,
            };
        case INTEGRATION_ADD_FAILED:
            return {
                ...state,
                items: [
                    ...state.items.filter(i => i.integrationId !== action.integrationId),
                    {
                        ...state.items.find(i => i.integrationId === action.integrationId),
                        isAdded: false,
                        apiKey: null,
                    },
                ],
                isLoadingAddIntegration: false,
                error: t('Kunne ikke legge til integrasjon, får ikke kontakt med server.'),
            };
        default:
            return state;
    }
};
export default integrations;
