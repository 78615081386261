export const MAILCHIMP_SYNC_ENABLED_FETCH_REQUEST = 'MAILCHIMP_SYNC_ENABLED_FETCH_REQUEST';
export const MAILCHIMP_SYNC_ENABLED_FETCH_SUCCEEDED = 'MAILCHIMP_SYNC_ENABLED_FETCH_SUCCEEDED';
export const MAILCHIMP_SYNC_ENABLED_FETCH_FAILED = 'MAILCHIMP_SYNC_ENABLED_FETCH_FAILED';

export const MAILCHIMP_SYNC_ENABLED_UPDATE_REQUEST = 'MAILCHIMP_SYNC_ENABLED_UPDATE_REQUEST';
export const MAILCHIMP_SYNC_ENABLED_UPDATE_SUCCEEDED = 'MAILCHIMP_SYNC_ENABLED_UPDATE_SUCCEEDED';
export const MAILCHIMP_SYNC_ENABLED_UPDATE_FAILED = 'MAILCHIMP_SYNC_ENABLED_UPDATE_FAILED';

export const MAILCHIMP_SYNC_ONLY_SMARKETING_FETCH_REQUEST = 'MAILCHIMP_SYNC_ONLY_SMARKETING_FETCH_REQUEST';
export const MAILCHIMP_SYNC_ONLY_SMARKETING_FETCH_SUCCEEDED = 'MAILCHIMP_SYNC_ONLY_SMARKETING_FETCH_SUCCEEDED';
export const MAILCHIMP_SYNC_ONLY_SMARKETING_FETCH_FAILED = 'MAILCHIMP_SYNC_ONLY_SMARKETING_FETCH_FAILED';

export const MAILCHIMP_SYNC_ONLY_SMARKETING_UPDATE_REQUEST = 'MAILCHIMP_SYNC_ONLY_SMARKETING_UPDATE_REQUEST';
export const MAILCHIMP_SYNC_ONLY_SMARKETING_UPDATE_SUCCEEDED = 'MAILCHIMP_SYNC_ONLY_SMARKETING_UPDATE_SUCCEEDED';
export const MAILCHIMP_SYNC_ONLY_SMARKETING_UPDATE_FAILED = 'MAILCHIMP_SYNC_ONLY_SMARKETING_UPDATE_FAILED';

export const MAILCHIMP_NEVER_UNSUBSCRIBE_FETCH_REQUEST = 'MAILCHIMP_NEVER_UNSUBSCRIBE_FETCH_REQUEST';
export const MAILCHIMP_NEVER_UNSUBSCRIBE_FETCH_SUCCEEDED = 'MAILCHIMP_NEVER_UNSUBSCRIBE_FETCH_SUCCEEDED';
export const MAILCHIMP_NEVER_UNSUBSCRIBE_FETCH_FAILED = 'MAILCHIMP_NEVER_UNSUBSCRIBE_FETCH_FAILED';

export const MAILCHIMP_NEVER_UNSUBSCRIBE_UPDATE_REQUEST = 'MAILCHIMP_NEVER_UNSUBSCRIBE_UPDATE_REQUEST';
export const MAILCHIMP_NEVER_UNSUBSCRIBE_UPDATE_SUCCEEDED = 'MAILCHIMP_NEVER_UNSUBSCRIBE_UPDATE_SUCCEEDED';
export const MAILCHIMP_NEVER_UNSUBSCRIBE_UPDATE_FAILED = 'MAILCHIMP_NEVER_UNSUBSCRIBE_UPDATE_FAILED';

export const MAILCHIMP_STATUSES_FETCH_REQUEST = 'MAILCHIMP_STATUSES_FETCH_REQUEST';
export const MAILCHIMP_STATUSES_FETCH_SUCCEEDED = 'MAILCHIMP_STATUSES_FETCH_SUCCEEDED';
export const MAILCHIMP_STATUSES_FETCH_FAILED = 'MAILCHIMP_STATUSES_FETCH_FAILED';

export const SYNCHRONIZE_NOW_REQUEST = 'SYNCHRONIZE_NOW_REQUEST';
export const SYNCHRONIZE_NOW_SUCCEEDED = 'SYNCHRONIZE_NOW_SUCCEEDED';
export const SYNCHRONIZE_NOW_FAILED = 'SYNCHRONIZE_NOW_FAILED';

export const MAILCHIMP_ERROR_CLEAR = 'MAILCHIMP_ERROR_CLEAR';

export const MAILCHIMP_CREATE_LIST_FIELDS_FETCH_REQUEST = 'MAILCHIMP_CREATE_LIST_FIELDS_FETCH_REQUEST';
export const MAILCHIMP_CREATE_LIST_FIELDS_FETCH_SUCCEEDED = 'MAILCHIMP_CREATE_LIST_FIELDS_FETCH_SUCCEEDED';
export const MAILCHIMP_CREATE_LIST_FIELDS_FETCH_FAILED = 'MAILCHIMP_CREATE_LIST_FIELDS_FETCH_FAILED';

export const MAILCHIMP_CREATE_LIST_REQUEST = 'MAILCHIMP_CREATE_LIST_REQUEST';
export const MAILCHIMP_CREATE_LIST_SUCCEEDED = 'MAILCHIMP_CREATE_LIST_SUCCEEDED';
export const MAILCHIMP_CREATE_LIST_FAILED = 'MAILCHIMP_CREATE_LIST_FAILED';

export const MAILCHIMP_LIST_STATUS_REQUEST = 'MAILCHIMP_LIST_STATUS_REQUEST';
export const MAILCHIMP_LIST_STATUS_SUCCEEDED = 'MAILCHIMP_LIST_STATUS_SUCCEEDED';
export const MAILCHIMP_LIST_STATUS_FAILED = 'MAILCHIMP_LIST_STATUS_FAILED';

export const MAILCHIMP_CREATE_LIST_FIELDS_UPDATE_LOCALLY = 'MAILCHIMP_CREATE_LIST_FIELDS_UPDATE_LOCALLY';

export const MAILCHIMP_SET_LIST_ID_REQUEST = 'MAILCHIMP_SET_LIST_ID_REQUEST';
export const MAILCHIMP_SET_LIST_ID_SUCCEEDED = 'MAILCHIMP_SET_LIST_ID_SUCCEEDED';
export const MAILCHIMP_SET_LIST_ID_FAILED = 'MAILCHIMP_SET_LIST_ID_FAILED';
