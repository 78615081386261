import { combineReducers } from 'redux';
import kioskReport from './kioskReport';
import {
    productSalesReportSoFarThisYear,
    lastYearAndThisMonthLastYearSalesReport,
} from './productModuleDashboardReports';

export default combineReducers({
    kioskReport,
    lastYearAndThisMonthLastYearSalesReport,
    productSalesReportSoFarThisYear,
});
