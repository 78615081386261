import { configureApiEntity } from '../adapter/index';
import { schema } from 'normalizr';

const omit = (entity, key) => {
    const ent = { ...entity };
    delete ent[key];
    return ent;
};

const seatSchema = new schema.Entity('seat');

const seatGroupSchema = new schema.Entity(
    'seatGroup',
    {},
    {
        processStrategy: entity => omit(entity, 'seats'),
    }
);
const seatSectionSchema = new schema.Entity(
    'seatSection',
    {},
    {
        processStrategy: entity => omit(entity, 'seatGroups'),
    }
);

const seatmapSchema = new schema.Entity('seatmap', {
    seatSections: [seatSectionSchema],
    seatGroups: [seatGroupSchema],
    seats: [seatSchema],
});

export const seats = configureApiEntity('SEATS', 'seats', seatSchema);
export const seatGroups = configureApiEntity('SEATMAPS', 'seatGroups', seatGroupSchema);
export const seatSections = configureApiEntity('SEATMAPS', 'seatSections', seatSectionSchema);
export const seatmaps = configureApiEntity('SEATMAPS', 'seatMaps', seatmapSchema);
