import React, { Component } from 'react';

import store from '../../store/createStore';
import { Provider } from 'react-redux';

import SidebarComponent from './SidebarComponent';
import dx from '../../dx';
import { withRouter, matchPath } from 'react-router-dom';
import { t } from '../../locale';

const partnerId = dx.utils.getPartnerId();
const renterId = dx.utils.getRenterId();
const modules = [
    {
        type: 'module',
        url: `/partners/${partnerId}/renters/${renterId}/shows`,
        label: t('Arrangementer'),
        icon: 'calendar',
        needs: { module: 'shows', role: ['leietakere'] },
    },
    {
        type: 'module',
        url: `/partners/${partnerId}/dashboard`,
        label: t('Dashbord Kino'),
        icon: 'dashboard',
        needs: { module: 'filmscheduler', role: ['kulturhussjef'] },
    },
    {
        type: 'module',
        url: `/partners/${partnerId}/shows`,
        label: t('Arrangementer'),
        icon: 'calendar',
        needs: { module: 'shows', role: ['kulturhussjef', 'culture_full_read_only'] },
    },
    {
        type: 'module',
        url: `/partners/${partnerId}/culture`,
        label: t('Kalender'),
        icon: 'calendar',
        needs: { module: 'culture', role: ['kulturhussjef', 'culture_full_read_only'] },
    },
    {
        type: 'module',
        url: `/partners/${partnerId}/campaigns`,
        label: t('Markedsføring'),
        icon: 'bullhorn',
        needs: { module: 'culture', role: ['kulturhussjef'] },
    },
    {
        type: 'module',
        url: `/partners/${partnerId}/products`,
        label: t('Produkter'),
        icon: 'shopping-cart',
        needs: { module: 'dxpos', role: ['kulturhussjef'] },
    },
    {
        type: 'module',
        url: `/partners/${partnerId}/filmscheduler`,
        label: t('Filmprogrammering'),
        icon: 'film',
        needs: { module: 'filmscheduler', role: ['kulturhussjef'] },
    },
    {
        type: 'module',
        url: `/partners/${partnerId}/renteradmin`,
        label: t('Leietakere'),
        icon: 'sitemap',
        needs: { module: 'renters', role: ['kulturhussjef'] },
    },
    {
        type: 'module',
        url: `/partners/${partnerId}/orders`,
        label: t('Prosjekter'),
        icon: 'shopping-basket',
        needs: { module: 'renters', role: ['kulturhussjef'] },
    },
    {
        type: 'module',
        url: `/partners/${partnerId}/mediabase`,
        label: t('Mediabase'),
        icon: 'film',
        needs: { module: 'productions', role: ['kulturhussjef'] },
    },
    {
        type: 'module',
        url: `/partners/${partnerId}/crm`,
        label: t('Kunder'),
        icon: 'users',
        needs: { module: 'crm', role: ['kulturhussjef'] },
    },
    {
        type: 'module',
        url: `/partners/${partnerId}/partneradmin`,
        label: t('Partneradmin'),
        icon: 'building',
        needs: { module: 'partneradmin', role: ['kulturhussjef'] },
    },
];

const getSidebarItems = (sudoPrefix, activePath) => {
    const authUser = dx.utils.getAuthUser();
    let sidebaritems = [];
    if (authUser.partners.length > 1) {
        sidebaritems = sidebaritems.concat([
            { type: 'module', url: '/home', label: t('Hjem'), icon: 'home' },
            { type: 'spacer' },
        ]);
    }
    sidebaritems = sidebaritems.concat(
        modules.filter(m => dx.utils.hasModule(m.needs.module) && dx.utils.hasRole(m.needs.role))
    );
    if (dx.utils.hasRole(['kulturhussjef'])) {
        sidebaritems.push({
            type: 'module',
            url: `/partners/${partnerId}/report`,
            label: t('Rapporter'),
            icon: 'file-text',
        });
    }
    if (partnerId > 1 && dx.utils.hasRole(['kulturhussjef'])) {
        sidebaritems.push({
            type: 'module',
            url: `/partners/${partnerId}/partnerprofile`,
            label: t('Innstillinger'),
            icon: 'cog',
        });
    }
    if (authUser.partners.find(p => p.partnerID === 1)) {
        sidebaritems = sidebaritems.concat([
            { type: 'spacer' },
            { type: 'module', url: '/dashboard?1', label: t('Dashboard'), icon: 'dashboard' },
            { type: 'module', url: '/partners/1/partneradmin', label: t('Partneradmin'), icon: 'building' },
            { type: 'module', url: '/partners/1/reportsadmin', label: t('Rapportadmin (beta)'), icon: 'line-chart' },
            { type: 'module', url: '/partners/1/useradmin', label: t('Useradmin'), icon: 'users' },
            { type: 'module', url: '/partners/1/mediabase', label: t('Mediabase (som admin)'), icon: 'film' },
            {
                type: 'module',
                url: (process.env.REACT_APP_BACKEND_URL || '') + '/health',
                label: t('Health status'),
                icon: 'medkit',
                aHrefLink: true,
            },
        ]);
    }
    return sidebaritems.map(sbi =>
        sbi.url
            ? {
                  ...sbi,
                  url: dx.utils.getPartnerIdFromUrl(sbi.url) > 1 ? sudoPrefix + sbi.url : sbi.url,
                  active: !!matchPath(activePath, { path: sbi.url }),
              }
            : sbi
    );
};

@withRouter
class Sidebar extends Component {
    render() {
        return (
            <Provider store={store}>
                <SidebarComponent
                    partnerId={this.props.partnerId}
                    renterId={this.props.renterId}
                    items={
                        this.props.waitingForAuth
                            ? []
                            : getSidebarItems(this.props.sudoPrefix, this.props.location.pathname)
                    }
                    waitingForAuth={this.props.waitingForAuth}
                />
            </Provider>
        );
    }
}

export default Sidebar;
