import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';

/*
 * General DX Cancel button component
 */

class CancelButton extends React.PureComponent {
    static propTypes = {
        onClick: PropTypes.func,
        text: PropTypes.string,
    };

    static defaultProps = {
        onClick: () => {},
        text: 'Avbryt',
    };
    render() {
        return <Button onClick={this.props.onClick} text={this.props.text} icon="fa fa-times" type="secondary" />;
    }
}

export default CancelButton;
