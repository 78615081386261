import ReactDOM from 'react-dom';

// Helper to render a React component in document.body and wait for it to
// resolve something. Useful for rapid modal dialogs outside the React tree.
//
// example:
//
// renderModal((resolve, reject) => (
//     <div>
//         <p>Are you sure you want to delete?</p>
//         <button onClick={() => resolve(true)}>Yes!</button>
//         <button onClick={() => reject(true)}>No...</button>
//     </div>
// )).then((agree) => {
//     if (agree) // go on and delete...
// })
//
export default function(callback) {
    const rootElement = window.document.createElement('div');
    window.document.body.appendChild(rootElement);

    let unmounted = false;

    const unmount = () => {
        if (unmounted) {
            return;
        }

        if (rootElement) {
            ReactDOM.unmountComponentAtNode(rootElement);
            window.document.body.removeChild(rootElement);
            unmounted = true;
        }
    };

    return new Promise((resolve, reject) => {
        const userResolve = (...args) => {
            unmount();
            resolve(...args);
        };

        const userReject = (...args) => {
            unmount();
            reject(...args);
        };

        ReactDOM.render(callback(userResolve, userReject), rootElement);
    });
}
