export default {
    body: {
        'ctrl-p': 'core:move-up',
        'ctrl-n': 'core:move-down',
        'ctrl-b': 'core:move-left',
        'ctrl-f': 'core:move-right',

        'cmd-alt-s': 'sidebar:toggle',
        'ctrl-alt-s': 'native!',

        'ctrl-a': 'native!',
        'cmd-a': 'core:select-all',
        'ctrl-shift-a': 'core:deselect-all',
        'cmd-shift-a': 'core:deselect-all',

        'ctrl-z': 'native!',
        'ctrl-y': 'native!',
        'cmd-z': 'core:undo',
        'cmd-shift-z': 'core:redo',
    },

    '.film-scheduler': {
        'ctrl-a': 'native!',
        'cmd-a': 'film-scheduler:select-all-on-current-day',
    },
};
