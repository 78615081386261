import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';

/*
 * General DX Confirm button component
 */

class ConfirmButton extends React.PureComponent {
    static propTypes = {
        onClick: PropTypes.func,
        text: PropTypes.string,
        disabled: PropTypes.bool,
        tooltip: PropTypes.string,
    };

    static defaultProps = {
        onClick: () => {},
        text: 'OK',
    };
    render() {
        return (
            <Button
                onClick={this.props.onClick}
                text={this.props.text}
                disabled={this.props.disabled}
                icon="fa fa-check"
                type="primary"
                tooltip={this.props.tooltip}
            />
        );
    }
}

export default ConfirmButton;
