import {
    REQUEST_MEDIABASE_PARTNER_LANGUAGES,
    RECEIVE_MEDIABASE_PARTNER_LANGUAGES,
    MEDIABASE_PARTNER_LANGUAGES_FETCH_FAILED,
} from '../actions/mediabasePartnerLanguage';

const initialState = {
    items: [],
    isLoading: false,
};

const mediabasePartnerLanguage = (state = initialState, action) => {
    switch (action.type) {
        //FETCH all mediabasePartnerLanguages
        case REQUEST_MEDIABASE_PARTNER_LANGUAGES:
            return {
                ...state,
                itemsLoading: true,
            };
        case RECEIVE_MEDIABASE_PARTNER_LANGUAGES:
            return {
                ...state,
                items: action.payload,
                fetchError: null,
                fetchNotAsked: false,
            };
        case MEDIABASE_PARTNER_LANGUAGES_FETCH_FAILED:
            return {
                ...state,
                fetchError: action.payload.status,
                fetchNotAsked: false,
            };
        default:
            return state;
    }
};

export default mediabasePartnerLanguage;
