import { t } from '../../../global/locale';
import {
    MAILCHIMP_STATUSES_FETCH_REQUEST,
    MAILCHIMP_STATUSES_FETCH_SUCCEEDED,
    MAILCHIMP_STATUSES_FETCH_FAILED,
    MAILCHIMP_SYNC_ENABLED_FETCH_REQUEST,
    MAILCHIMP_SYNC_ENABLED_FETCH_SUCCEEDED,
    MAILCHIMP_SYNC_ENABLED_FETCH_FAILED,
    SYNCHRONIZE_NOW_REQUEST,
    SYNCHRONIZE_NOW_SUCCEEDED,
    SYNCHRONIZE_NOW_FAILED,
    MAILCHIMP_SYNC_ENABLED_UPDATE_REQUEST,
    MAILCHIMP_SYNC_ENABLED_UPDATE_SUCCEEDED,
    MAILCHIMP_SYNC_ENABLED_UPDATE_FAILED,
    MAILCHIMP_ERROR_CLEAR,
    MAILCHIMP_CREATE_LIST_FIELDS_FETCH_REQUEST,
    MAILCHIMP_CREATE_LIST_FIELDS_FETCH_SUCCEEDED,
    MAILCHIMP_CREATE_LIST_FIELDS_FETCH_FAILED,
    MAILCHIMP_LIST_STATUS_REQUEST,
    MAILCHIMP_LIST_STATUS_SUCCEEDED,
    MAILCHIMP_LIST_STATUS_FAILED,
    MAILCHIMP_CREATE_LIST_REQUEST,
    MAILCHIMP_CREATE_LIST_SUCCEEDED,
    MAILCHIMP_CREATE_LIST_FAILED,
    MAILCHIMP_CREATE_LIST_FIELDS_UPDATE_LOCALLY,
    MAILCHIMP_SET_LIST_ID_REQUEST,
    MAILCHIMP_SET_LIST_ID_SUCCEEDED,
    MAILCHIMP_SET_LIST_ID_FAILED,
    MAILCHIMP_SYNC_ONLY_SMARKETING_FETCH_REQUEST,
    MAILCHIMP_SYNC_ONLY_SMARKETING_FETCH_SUCCEEDED,
    MAILCHIMP_SYNC_ONLY_SMARKETING_FETCH_FAILED,
    MAILCHIMP_SYNC_ONLY_SMARKETING_UPDATE_REQUEST,
    MAILCHIMP_SYNC_ONLY_SMARKETING_UPDATE_SUCCEEDED,
    MAILCHIMP_SYNC_ONLY_SMARKETING_UPDATE_FAILED,
    MAILCHIMP_NEVER_UNSUBSCRIBE_FETCH_REQUEST,
    MAILCHIMP_NEVER_UNSUBSCRIBE_FETCH_SUCCEEDED,
    MAILCHIMP_NEVER_UNSUBSCRIBE_FETCH_FAILED,
    MAILCHIMP_NEVER_UNSUBSCRIBE_UPDATE_REQUEST,
    MAILCHIMP_NEVER_UNSUBSCRIBE_UPDATE_SUCCEEDED,
    MAILCHIMP_NEVER_UNSUBSCRIBE_UPDATE_FAILED,
} from '../actions/mailChimpSettings';

const initialState = {
    isLoadingMailChimpSync: false,
    isLoadingMailChimpStatuses: false,
    isLoadingSyncNow: false,
    isLoadingMailChimpListFields: false,
    isLoadingListStatus: false,
    isLoadingCreateMailChimpList: false,
    isLoadingSetListId: false,
    isLoadingSyncSmarketing: false,
    isLoadingNeverUnsubscribe: false,

    mailChimpStatusesFetched: false,

    mailChimpSyncEnabled: false,
    mailChimpSyncSmarketingOnly: false,
    mailChimpNeverUnsubscribe: false,

    //statuses, queryed often to show partner status of synchronization
    mailChimpSyncRunning: false,
    mailChimpSyncLastStatus: '',
    mailChimpSyncLastRun: null,

    mailChimpSyncIsQueued: false,

    //fields partner needs to
    createListFields: {},

    listId: null,
    allLists: [],

    error: '',
    listCreationError: null,
    listStatusApiError: null,

    silentStatusFetchFailed: false,
};

const mailChimpSettings = (state = initialState, action) => {
    switch (action.type) {
        //FETCHING setting for syncronizing only patrons that have accepted segregated marketing
        case MAILCHIMP_NEVER_UNSUBSCRIBE_FETCH_REQUEST:
            return {
                isLoadingNeverUnsubscribe: true,
                ...state,
            };
        case MAILCHIMP_NEVER_UNSUBSCRIBE_FETCH_FAILED:
            return {
                ...state,
                isLoadingNeverUnsubscribe: false,
                error: t('Kunne ikke hente MailChimp konfigurasjon. Får ikke svar fra server.'),
            };
        case MAILCHIMP_NEVER_UNSUBSCRIBE_FETCH_SUCCEEDED:
            return {
                ...state,
                isLoadingNeverUnsubscribe: false,
                mailChimpNeverUnsubscribe: action.response,
            };
        //SETTING setting for syncronizing only patrons that have accepted segregated marketing
        case MAILCHIMP_NEVER_UNSUBSCRIBE_UPDATE_REQUEST:
            return {
                ...state,
                mailChimpNeverUnsubscribe: action.value,
            };
        case MAILCHIMP_NEVER_UNSUBSCRIBE_UPDATE_SUCCEEDED:
            return {
                ...state,
                mailChimpNeverUnsubscribe: action.response,
            };
        case MAILCHIMP_NEVER_UNSUBSCRIBE_UPDATE_FAILED:
            return {
                ...state,
                mailChimpNeverUnsubscribe: action.oldValue,
                error: t('Kunne ikke oppdatere MailChimp konfigurasjon. Får ikke svar fra server.'),
            };
        //FETCHING setting for syncronizing only patrons that have accepted segregated marketing
        case MAILCHIMP_SYNC_ONLY_SMARKETING_FETCH_REQUEST:
            return {
                isLoadingSyncSmarketing: true,
                ...state,
            };
        case MAILCHIMP_SYNC_ONLY_SMARKETING_FETCH_FAILED:
            return {
                ...state,
                isLoadingSyncSmarketing: false,
                error: t('Kunne ikke hente MailChimp konfigurasjon. Får ikke svar fra server.'),
            };
        case MAILCHIMP_SYNC_ONLY_SMARKETING_FETCH_SUCCEEDED:
            return {
                ...state,
                isLoadingSyncSmarketing: false,
                mailChimpSyncSmarketingOnly: action.response,
            };
        //SETTING setting for syncronizing only patrons that have accepted segregated marketing
        case MAILCHIMP_SYNC_ONLY_SMARKETING_UPDATE_REQUEST:
            return {
                ...state,
                mailChimpSyncSmarketingOnly: action.value,
            };
        case MAILCHIMP_SYNC_ONLY_SMARKETING_UPDATE_SUCCEEDED:
            return {
                ...state,
                mailChimpSyncSmarketingOnly: action.response,
            };
        case MAILCHIMP_SYNC_ONLY_SMARKETING_UPDATE_FAILED:
            return {
                ...state,
                mailChimpSyncSmarketingOnly: action.oldValue,
                error: t('Kunne ikke oppdatere MailChimp konfigurasjon. Får ikke svar fra server.'),
            };
        //set list id
        case MAILCHIMP_SET_LIST_ID_REQUEST:
            return {
                ...state,
                isLoadingSetListId: true,
            };
        case MAILCHIMP_SET_LIST_ID_FAILED:
            return {
                ...state,
                isLoadingSetListId: false,
                error: t('Kunne ikke sette MailChimp liste'),
            };
        case MAILCHIMP_SET_LIST_ID_SUCCEEDED:
            return {
                ...state,
                isLoadingSetListId: false,
                listId: action.response.listId,
            };
        //local only
        case MAILCHIMP_CREATE_LIST_FIELDS_UPDATE_LOCALLY:
            return {
                ...state,
                createListFields: action.values,
            };
        //Create MailChimp List
        case MAILCHIMP_CREATE_LIST_REQUEST:
            return {
                ...state,
                isLoadingCreateMailChimpList: true,
                listCreationError: null,
            };
        case MAILCHIMP_CREATE_LIST_SUCCEEDED:
            return {
                ...state,
                listId: action.response.error ? null : action.response.payload.list.id,
                isLoadingCreateMailChimpList: false,
                listCreationError: action.response.error ? action.response.payload : null,
                allLists: action.response.error ? state.allLists : [...state.allLists, action.response.payload.list],
            };
        case MAILCHIMP_CREATE_LIST_FAILED:
            return {
                ...state,
                isLoadingCreateMailChimpList: false,
                listCreationError: t('Noe gikk galt under opprettelsen av liste i MailChimp.'),
            };

        //get status about chosen list, also returns all lists available on the mailchimp account
        case MAILCHIMP_LIST_STATUS_REQUEST:
            return {
                ...state,
                isLoadingListStatus: true,
            };
        case MAILCHIMP_LIST_STATUS_SUCCEEDED:
            return {
                ...state,
                isLoadingListStatus: false,
                listId: action.response.error ? null : action.response.payload.listId,
                allLists: action.response.error ? [] : action.response.payload.allLists,
                listStatusApiError: action.response.error ? action.response.payload : null,
            };
        case MAILCHIMP_LIST_STATUS_FAILED:
            return {
                ...state,
                isLoadingListStatus: false,
                error: t('Kunne ikke validere at mailchimp listen var opprettet'),
            };
        //fetch fields necessary for creating mailchimp list, with default values
        case MAILCHIMP_CREATE_LIST_FIELDS_FETCH_REQUEST:
            return {
                ...state,
                isLoadingMailChimpListFields: true,
            };
        case MAILCHIMP_CREATE_LIST_FIELDS_FETCH_SUCCEEDED:
            return {
                ...state,
                isLoadingMailChimpListFields: false,
                createListFields: action.response,
            };
        case MAILCHIMP_CREATE_LIST_FIELDS_FETCH_FAILED:
            return {
                ...state,
                isLoadingMailChimpListFields: false,
                error: t('Kunne ikke hentet MailChimp liste-data. Får ikke svar fra server.'),
            };
        //MAILCHIMP_STATUSES
        case MAILCHIMP_STATUSES_FETCH_REQUEST:
            // don't register as isLoading if silent is true
            return action.silent
                ? state
                : {
                      ...state,
                      isLoadingMailChimpStatuses: true,
                  };
        case MAILCHIMP_STATUSES_FETCH_SUCCEEDED:
            return {
                ...state,
                //indicate that we now have real values, and not defaultValues
                mailChimpStatusesFetched: true,
                //dont influence loading if silent is true
                isLoadingMailChimpStatuses: action.silent ? state.isLoadingMailChimpStatuses : false,
                mailChimpSyncRunning: action.response.isRunning,
                //if partner has never run mailchimpsync, LAST_STATUS_MSG.value will be null so just set to success.
                mailChimpSyncLastStatus: action.response.lastStatusMsg,
                mailChimpSyncLastRun: action.response.lastRunTime ? new Date(action.response.lastRunTime) : null,
                mailChimpSyncIsQueued: state.mailChimpSyncIsQueued
                    ? // set isQueued to false if mailchimpsync is now running, or there was a status update
                      // (probably an error if mailChimpSyncRunning is false)
                      action.response.isRunning || action.response.lastStatusMsg !== state.mailChimpSyncLastStatus
                        ? false
                        : state.mailChimpSyncIsQueued
                    : state.mailChimpSyncIsQueued,
            };
        case MAILCHIMP_STATUSES_FETCH_FAILED:
            //silent should do anything here
            return action.silent
                ? {
                      ...state,
                      silentStatusFetchFailed: true,
                  }
                : {
                      ...state,
                      isLoadingMailChimpStatuses: false,
                      error: t('Kunne ikke hentet MailChimp status. Får ikke svar fra server.'),
                  };

        //SYNCHRONIZE_NOW
        case SYNCHRONIZE_NOW_REQUEST:
            return {
                ...state,
                mailChimpSyncIsQueued: true,
                isLoadingSyncNow: true,
            };
        case SYNCHRONIZE_NOW_SUCCEEDED:
            return {
                ...state,
                isLoadingSyncNow: false,
            };
        case SYNCHRONIZE_NOW_FAILED:
            return {
                ...state,
                isLoadingSyncNow: false,
                error: t('Kunne ikke starte MailChimp synkronisering. Får ikke svar fra server.'),
            };

        //MAILCHIMP_SYNC_ENABLED
        case MAILCHIMP_SYNC_ENABLED_FETCH_REQUEST:
            return {
                ...state,
                isLoadingMailChimpSync: true,
            };
        case MAILCHIMP_SYNC_ENABLED_FETCH_SUCCEEDED:
            return {
                ...state,
                isLoadingMailChimpSync: false,
                mailChimpSyncEnabled: action.response,
            };
        case MAILCHIMP_SYNC_ENABLED_FETCH_FAILED:
            return {
                ...state,
                isLoadingMailChimpSync: false,
                error: t('Kunne ikke hente MailChimp synkroniserings konfigurasjon. Får ikke svar fra server.'),
            };
        //SET_MAILCHIMP_SYNC_ENABLED
        case MAILCHIMP_SYNC_ENABLED_UPDATE_REQUEST:
            return {
                ...state,
                mailChimpSyncEnabled: action.value,
                //todo maybe indicate that its not properly set yet
            };
        case MAILCHIMP_SYNC_ENABLED_UPDATE_SUCCEEDED:
            return {
                ...state,
                mailChimpSyncEnabled: action.response,
            };
        case MAILCHIMP_SYNC_ENABLED_UPDATE_FAILED:
            return {
                ...state,
                mailChimpSyncEnabled: action.oldValue,
                error: t(
                    'Kunne ikke hente MailChimp automatisk-synkronisering konfigurasjon. Får ikke svar fra server.'
                ),
            };
        //CLEAR ERROR
        case MAILCHIMP_ERROR_CLEAR:
            return {
                ...state,
                error: '',
            };
        default:
            return state;
    }
};

export default mailChimpSettings;
