import React from 'react';
import PropTypes from 'prop-types';
import { t } from '../../global/locale';

const LoadingIndicator = ({ message = t('Laster inn'), size = 'medium', style = {}, ...rest }) => (
    <div className={`loading-indicator ${size}`} style={style} {...rest}>
        <div className="sk-circle">
            <div className="sk-circle1 sk-child" />
            <div className="sk-circle2 sk-child" />
            <div className="sk-circle3 sk-child" />
            <div className="sk-circle4 sk-child" />
            <div className="sk-circle5 sk-child" />
            <div className="sk-circle6 sk-child" />
            <div className="sk-circle7 sk-child" />
            <div className="sk-circle8 sk-child" />
            <div className="sk-circle9 sk-child" />
            <div className="sk-circle10 sk-child" />
            <div className="sk-circle11 sk-child" />
            <div className="sk-circle12 sk-child" />
        </div>

        {message ? <p>{message}</p> : null}
    </div>
);

LoadingIndicator.propTypes = {
    message: PropTypes.string,
    style: PropTypes.object,
};

export default LoadingIndicator;
