import React from 'react';
import { Provider } from 'react-redux';
import store from './store/createStore';
import ActiveHeader from './components/ActiveHeader';
import { t } from './locale';
import PrimaryButton from './components/ui/buttons/PrimaryButton';
import PropTypes from 'prop-types';

export const ErrorPartialView = ({ error }) => (
    <div style={{ margin: '0 auto', width: '80%' }}>
        <h2>{t('Feilmelding:')}</h2>
        <pre>{JSON.stringify(error, 0, 4)}</pre>
        <PrimaryButton text={t('Last inn på nytt')} onClick={() => window.location.reload()} />
    </div>
);
ErrorPartialView.propTypes = { error: PropTypes.any.isRequired };

const ErrorView = ({ title, error }) => (
    <Provider store={store}>
        <ActiveHeader title={title} />
        <ErrorPartialView error={error} />
    </Provider>
);
ErrorView.propTypes = { title: PropTypes.string.isRequired, error: PropTypes.any.isRequired };

export default ErrorView;
