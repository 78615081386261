import { combineReducers } from 'redux';
import {
    INTERCOM_UNREAD_COUNT_CHANGE,
    RECEIVE_USER_DATA,
    SET_CONTEXT,
    SET_MODULES,
    SET_PARTNERS,
    SET_USER_ROLES,
} from '../actions/auth';

const intercom = (state = { unread: 0 }, action) => {
    switch (action.type) {
        case INTERCOM_UNREAD_COUNT_CHANGE: {
            return {
                unread: action.count,
            };
        }
        default: {
            return state;
        }
    }
};

const user = (
    state = {
        id: false,
        username: '',
        email: '',
        displayName: '',
        sudo: false,
    },
    action
) => {
    switch (action.type) {
        case RECEIVE_USER_DATA: {
            const newState = {
                sudo: !!action.isSudo,
            };
            if (action.isSudo) {
                newState.sudoId = action.isSudo;
            }
            return {
                ...state,
                ...action.user,
                ...newState,
            };
        }
        default:
            return state;
    }
};

const roles = (state = [], action) => {
    switch (action.type) {
        case SET_USER_ROLES: {
            return action.roles;
        }
        default:
            return state;
    }
};

const partners = (state = [], action) => {
    switch (action.type) {
        case SET_PARTNERS: {
            return action.partners;
        }
        default:
            return state;
    }
};

const modules = (state = [], action) => {
    switch (action.type) {
        case SET_MODULES: {
            return action.modules;
        }
        default:
            return state;
    }
};

const context = (state = { partner: {}, renter: {} }, action) => {
    switch (action.type) {
        case SET_CONTEXT: {
            return {
                partner: action.partner,
                renter: action.renter,
            };
        }
        default:
            return state;
    }
};

/**
 * Experiment - all of this could easily be in one reducer - but
 * I'm experimenting with splitting them up to make each individual reducer
 * easier to reason about.
 */
export default combineReducers({
    intercom,
    user,
    roles,
    partners,
    modules,
    context,
});
