export const CHANGE_PERIOD = 'CHANGE_PERIOD';

export const changePeriod = param => ({
    type: CHANGE_PERIOD,
    payload: {
        from: param.from,
        to: param.to,
        periodType: { ...param.periodType },
    },
});
