import ErrorView from './ErrorView';
import React from 'react';
import PropTypes from 'prop-types';
import Raven from 'raven-js';

//function from https://medium.com/@FezVrasta/service-worker-updates-and-error-handling-with-react-1a3730800e6a
const maybeForceNewSWAndReload = async err => {
    console && console.log('apocalypse!');
    // Since we are handling the error here, we must make
    // sure we log it into the console nonetheless, otherwise
    // it will be very difficult to understand why your app
    // is crashing.
    console && console.error && console.error(err);

    // If no service worker is available, our work ends here
    // because we don't need to unregister the service worker
    // to make sure the user is able to get a newer version of
    // our application.
    if (!navigator.serviceWorker) {
        console && console.log('no navigator.serviceWorker');
        return;
    }

    // On development builds of React, error boundaries don't stop
    // errors from bubbling up to the window error handler, so we don't
    // want to execute this code here because it would be unreliable
    // https://github.com/facebook/react/issues/12897#issuecomment-410036991
    if (process.env.NODE_ENV !== 'development') {
        // We want to run this code only if we detect a new service worker
        // is getting installed or is installed but waiting to be activated.
        // This will make sure we don't run this code on a sane environment
        // that is crashing for an error not related to stale app cache.
        const registration = await navigator.serviceWorker.ready;
        await registration.update();
        if (registration.installing || registration.waiting) {
            navigator.serviceWorker.ready.then(async registration => {
                await registration.unregister();
                // Once the service worker is unregistered, we can reload
                // the page to let the browser download a fresh copy of our app
                window.location.reload();
            });
        }
    }
};

export default class ErrorBoundary extends React.Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
    };
    state = { hasError: false, error: null };
    static getDerivedStateFromError(error) {
        return {
            error: error + '',
            hasError: true,
        };
    }
    componentDidCatch(error, errorInfo) {
        maybeForceNewSWAndReload(error);
        Raven.captureException(error, errorInfo);
    }
    render() {
        if (this.state.hasError) {
            return <ErrorView title={this.props.title} error={this.state.error} />;
        }
        return this.props.children;
    }
}
