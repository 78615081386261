import { configureApiEntity } from '../adapter';
import { schema } from 'normalizr';

const priceCategoryDefinitionSchema = new schema.Entity('operator');

export const priceCategoryDefinitions = configureApiEntity(
    'PRICECATEGORYDEFINITIONS',
    'pricecategorydefinitions',
    priceCategoryDefinitionSchema
);
