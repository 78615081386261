import _ from 'lodash';
import { calculateSpecificity } from 'clear-cut';
import { Disposable, CompositeDisposable, makeDisposableListener } from './disposable';

let deepExtend = (target, source) => {
    if (typeof source === 'object') {
        for (let prop in source) {
            if (prop in target) {
                deepExtend(target[prop], source[prop]);
            } else {
                target[prop] = source[prop];
            }
        }
    }
    return source || target;
};

let modifiers = {
    cmd: 'metaKey',
    ctrl: 'ctrlKey',
    alt: 'altKey',
    shift: 'shiftKey',
};

let specialKeys = {
    enter: 13,
    space: 32,
    up: 38,
    down: 40,
    left: 37,
    right: 39,
    delete: 46,
    escape: 27,
    backspace: 8,
    '+': 187,
};

// TODO: This function needs huge improvements in which keybindings it
// understands.
let keybindingMatches = function(event, keybinding) {
    let keys = keybinding.split('-');

    let modifiersWanted = _.filter(keys, key => !!modifiers[key]);
    let modifiersPressed = _.reduce(
        modifiers,
        (memo, prop, key) => {
            if (event[prop]) {
                memo.push(key);
            }
            return memo;
        },
        []
    );
    if (
        _.difference(modifiersWanted, modifiersPressed).length > 0 ||
        _.difference(modifiersPressed, modifiersWanted).length > 0
    ) {
        return false;
    }

    for (let i in keys) {
        let key = keys[i];

        if (modifiers[key]) {
            // Do nothing. We already has confirmed modifier keys.
            continue;
        } else if (specialKeys[key]) {
            if (event.keyCode !== specialKeys[key]) {
                return false;
            }
        } else if (String.fromCharCode(event.keyCode).toLowerCase() !== key) {
            return false;
        }
    }
    return true;
};

export default class KeymapResolver {
    constructor(commandRegistry) {
        this.keymap = {};
        this.commandRegistry = commandRegistry;
        this.disposable = new CompositeDisposable();
        this.defaultFocus = document.body;
    }

    // The default focus in a webpage is document.body. So when you blur current
    // focus, body is focused.
    //
    // But in DXWeb, we have modules that acts like programs. So when you for
    // example is in the filmscheduler, you don't want the filmscheduler viewport
    // to loose its focus.
    //
    // Use setDefaultFocus in your componentDidMount, and don't forget to dispose
    // it in componentWillUnmount!
    setDefaultFocus(domElement) {
        this.defaultFocus = domElement;

        return new Disposable(() => {
            if (this.defaultFocus === domElement) {
                this.defaultFocus = window.body;
            }
        });
    }

    add(name, keymap) {
        deepExtend(this.keymap, keymap);
    }

    onKeyDown(ev) {
        // ev.target is read only, so I need to copy the SyntheticEvent:
        let event = {
            bubbles: ev.bubbles,
            cancelable: ev.cancelable,
            currentTarget: ev.currentTarget,
            defaultPrevented: ev.defaultPrevented,
            eventPhase: ev.eventPhase,
            isTrusted: ev.isTrusted,
            nativeEvent: ev.nativeEvent,
            preventDefault: ev.preventDefault.bind(ev),
            stopPropagation: ev.stopPropagation.bind(ev),
            target: ev.target,
            timestamp: ev.timestamp,
            type: ev.type,
            altKey: ev.altKey,
            charCode: ev.charCode,
            ctrlKey: ev.ctrlKey,
            key: ev.key,
            keyCode: ev.keyCode,
            locale: ev.locale,
            location: ev.location,
            metaKey: ev.metaKey,
            repeat: ev.repeat,
            shiftKey: ev.shiftKey,
            which: ev.which,
        };

        if (ev.getModifierState) {
            event.getModifierState = ev.getModifierState.bind(ev);
        }

        let stop = false;
        event.stopPropagation = () => {
            stop = true;
        };
        let currentTarget = event.target;

        if (event.target === document.body && this.defaultFocus) {
            delete event.target;
            event.target = this.defaultFocus;
            currentTarget = this.defaultFocus;
        }

        // TODO: For now we just ignore all keydown in input elements, but we should
        // rather check how Atom handles these. It can be useful to be able to bind
        // keys even when inside an input.
        if (currentTarget.matches('input, textarea, [contenteditable=true]')) {
            return;
        }

        let keymaps = _.chain(this.keymap)
            .map((keymap, selector) => {
                return { keymap, selector };
            })
            .sortBy(item => {
                return calculateSpecificity(item.selector);
            })
            .value();
        keymaps.reverse();

        // Loop from currentTarget an up to body. bubbling up
        while (true) {
            // Loop through keymaps (which are sorted by specificity) and see if any of
            // them matches.
            for (let i in keymaps) {
                let { keymap, selector } = keymaps[i];

                // Do we match the selector from keymap?
                if (currentTarget.matches && currentTarget.matches(selector)) {
                    // These commands should be runned:
                    let commands = _.filter(keymap, (command, keybinding) => {
                        return keybindingMatches(event, keybinding);
                    });
                    if (commands.length > 0) {
                        if (commands[0] === 'native!') {
                            return;
                        } else if (commands[0] === 'unset!') {
                            event.preventDefault();
                            return;
                        } else {
                            let abort = false;
                            event.abortKeyBinding = () => {
                                abort = true;
                            };
                            this.commandRegistry.dispatchEvent(event, commands[0]);
                            if (abort === false) {
                                event.preventDefault();
                                return;
                            }
                        }
                    }
                }
            }

            if (currentTarget === window || stop || !currentTarget.parentNode) {
                break;
            }
            currentTarget = currentTarget.parentNode;
        }
    }

    start() {
        return this.disposable.add(makeDisposableListener(window, 'keydown', this.onKeyDown.bind(this)));
    }
}

export const _internals = {
    deepExtend,
    keybindingMatches,
};
