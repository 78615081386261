import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MenuComponent from '../../../global/components/MenuComponent';
import { t } from '../../../global/locale';
import dx from '../../../global/dx';
import classnames from 'classnames';

const tabs = {
    events: {
        key: 'events',
        title: t('Arrangementer'),
    },
    timeline: {
        key: 'timeline',
        title: t('Tidslinje'),
    },
    orders: {
        key: 'orders',
        title: t('Ordre'),
    },
    resources: {
        key: 'resources',
        title: t('Ressurser'),
    },
};

class MainViewHeaderMenu extends Component {
    static propTypes = {
        /** @type {String} activeTab - the key for the currently active tab */
        activeTab: PropTypes.string.isRequired,
        /** @type {Function} onClick - the handler for when a tab is clicked */
        onClick: PropTypes.func.isRequired,
    };
    state = {
        isOpen: false,
    };
    toggle = event => {
        event.preventDefault();
        this.setState(state => ({
            isOpen: !state.isOpen,
        }));
    };
    handleClick = key => {
        this.props.onClick(key);
        this.setState(state => ({
            isOpen: !state.isOpen,
        }));
    };
    render() {
        const { activeTab } = this.props;

        const menuComponents = Object.keys(tabs).map(key => {
            const c = tabs[key];
            return (
                <MenuComponent onClick={() => this.handleClick(c.key)} active={activeTab === c.key} key={c.key}>
                    {c.title}
                </MenuComponent>
            );
        });
        const wrapperClasses = classnames({
            'menu-component-wrapper': true,
            open: this.state.isOpen,
        });
        const containerClasses = classnames({
            'menu-components-container': true,
            open: this.state.isOpen,
        });
        return (
            <div className={wrapperClasses}>
                <a className="header-menu-dropdown-toggler" href="#" onClick={this.toggle}>
                    {tabs[activeTab].title}
                </a>
                <div className={containerClasses}>{menuComponents}</div>
            </div>
        );
    }
}

export default MainViewHeaderMenu;
