import {
    LIST_EVENTS_SHOW_CREATE_POPUP,
    LIST_EVENTS_SHOW_EDIT_POPUP,
    LIST_EVENTS_HIDE_ALL_POPUPS,
} from '../actions/listEventTypes';
import {
    OPTIMISTIC_CREATE_EVENT_TYPE,
    OPTIMISTIC_UPDATE_EVENT_TYPE,
    OPTIMISTIC_DELETE_EVENT_TYPE,
} from '../../../global/actions/eventType';
const initialState = {
    showEditPopup: false,
    showCreatePopup: false,
};

const listPriceCategoryDefinitions = (state = initialState, action) => {
    switch (action.type) {
        case LIST_EVENTS_SHOW_CREATE_POPUP:
            return {
                ...state,
                showCreatePopup: true,
            };
        case LIST_EVENTS_SHOW_EDIT_POPUP:
            return {
                ...state,
                showEditPopup: action.id,
            };
        case OPTIMISTIC_CREATE_EVENT_TYPE:
        case OPTIMISTIC_UPDATE_EVENT_TYPE:
        case OPTIMISTIC_DELETE_EVENT_TYPE:
        case LIST_EVENTS_HIDE_ALL_POPUPS:
            return {
                ...state,
                showCreatePopup: false,
                showEditPopup: false,
            };
        default:
            return state;
    }
};

export default listPriceCategoryDefinitions;
