import {
    MODE_REPORTS_CREATE_FAILED,
    MODE_REPORTS_CREATE_PENDING,
    MODE_REPORTS_CREATE_SUCCESS,
    MODE_REPORTS_FETCH_FAILED,
    MODE_REPORTS_FETCH_PENDING,
    MODE_REPORTS_FETCH_SUCCESS,
    MODE_REPORTS_PARAM_TYPES_FETCH_SUCCESS,
    MODE_REPORTS_ROLES_FAILED,
    MODE_REPORTS_ROLES_PENDING,
    MODE_REPORTS_ROLES_SUCCESS,
    MODE_REPORTS_SET_SIGNED_URL,
    MODE_REPORTS_UPDATE_SUCCESS,
} from '../actions/modeReports';

const initialState = {
    items: {},
    fetchNotAsked: true,
    fetchPending: false,
    fetchError: null,
    createPending: false,
    createError: null,
    fetchRolesPending: false,
    fetchRolesError: null,
    roles: {},
    signedUrl: null,
    paramTypes: {},
};

const modeReports = (state = initialState, action) => {
    switch (action.type) {
        case MODE_REPORTS_CREATE_PENDING:
            return {
                ...state,
                createPending: true,
                createError: null,
            };
        case MODE_REPORTS_CREATE_FAILED:
            return {
                ...state,
                createPending: false,
                createError: action.payload.status,
            };
        case MODE_REPORTS_CREATE_SUCCESS:
            return {
                ...state,
                items: {
                    ...state.items,
                    [action.payload.id]: action.payload,
                },
                createPending: false,
                createError: null,
            };
        case MODE_REPORTS_FETCH_PENDING:
            return {
                ...state,
                fetchPending: true,
                fetchError: null,
                fetchNotAsked: false,
            };
        case MODE_REPORTS_FETCH_FAILED:
            return {
                ...state,
                fetchPending: false,
                fetchError: action.payload.status,
                fetchNotAsked: false,
            };
        case MODE_REPORTS_FETCH_SUCCESS:
            return {
                ...state,
                items: action.payload.reduce((items, item) => {
                    items[item.id] = item;
                    return items;
                }, {}),
                fetchPending: false,
                fetchError: null,
                fetchNotAsked: false,
            };
        case MODE_REPORTS_ROLES_PENDING:
            return {
                ...state,
                fetchRolesPending: true,
                fetchRolesError: null,
            };
        case MODE_REPORTS_ROLES_FAILED:
            return {
                ...state,
                fetchRolesPending: false,
                fetchRolesError: action.payload,
            };
        case MODE_REPORTS_ROLES_SUCCESS:
            return {
                ...state,
                fetchRolesPending: false,
                fetchRolesError: null,
                roles: action.payload.reduce((items, item) => {
                    items[item.id] = item;
                    return items;
                }, {}),
            };
        case MODE_REPORTS_UPDATE_SUCCESS:
            return {
                ...state,
                items: {
                    ...state.items,
                    [action.payload.id]: action.payload,
                },
            };
        case MODE_REPORTS_SET_SIGNED_URL:
            return {
                ...state,
                signedUrl: action.payload,
            };
        case MODE_REPORTS_PARAM_TYPES_FETCH_SUCCESS:
            return {
                ...state,
                paramTypes: action.payload.reduce((items, item) => {
                    items[item.id] = item;
                    return items;
                }, {}),
            };
        default:
            return state;
    }
};

export default modeReports;
