export const INTERCOM_UNREAD_COUNT_CHANGE = 'INTERCOM_UNREAD_COUNT_CHANGE';

export const RECEIVE_USER_DATA = 'RECEIVE_USER_DATA';

export const SET_USER_ROLES = 'SET_USER_ROLES';

export const SET_PARTNERS = 'SET_PARTNERS';

export const SET_MODULES = 'SET_MODULES';

export const SET_CONTEXT = 'SET_CONTEXT';
