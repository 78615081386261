import React from 'react';
import Axios from 'axios';
import { withRouter } from 'react-router';
import LoadingIndicator from '../global/components/LoadingIndicator';
import dx from '../global/dx';

class AutoSudoComponent extends React.Component {
    componentDidMount() {
        Axios.get(`/api/v1/auth/findsudouserforpartner?p_id=${dx.utils.getPartnerId()}`).then(response => {
            window.location = this.props.location.pathname.replace('/autosudo/', `/sudo/${response.data}/`);
        });
    }
    render() {
        return <LoadingIndicator message="Loading superpowers" />;
    }
}
export default withRouter(AutoSudoComponent);
