import { combineReducers } from 'redux';
import * as types from './types';
import { zoneColors } from './types';
import { getLocalStorage, setLocalStorage } from '../../../../../../global/utils/localStorageUtils';

/**
 * The Seat type
 *
 * @typedef {Object} Seat
 * @property {Number} id
 * @property {Number|String} name
 * @property {Number} number
 * @property {Number} partnerId
 * @property {Number} rotation
 * @property {Number} seatGroupId
 * @property {Number} seatSectionId
 * @property {String} type
 * @property {Number} x
 * @property {Number} y
 *
 * --------------------------------------------------------------------------
 *
 * The Pricezone type
 *
 * @typedef {Object} Pricezone
 * @property {Number} id
 * @property {Seat[]} seats
 * @property {String} color
 * @property {{amount: Number, type: String}} adjustment
 *
 * --------------------------------------------------------------------------
 *
 * The PricezoneTemplate type
 *
 * @typedef {Object} PricezoneTemplate
 * @property {Number} id
 * @property {String} name
 * @property {Pricezone[]} zones
 *
 * --------------------------------------------------------------------------
 *
 * The type for the pricezone templates reducer state
 *
 * @typedef {Object} pricezoneTemplatesState
 * @property {Boolean} isLoading
 * @property {Boolean|String|Number} selectedTemplate
 * @property {PricezoneTemplate[]} items
 *
 * --------------------------------------------------------------------------
 *
 * The type for the seatmap reducer
 *
 * @typedef {Object} SeatmapReducer
 * @property {Boolean} isLoading
 * @property {Seat[]} seats
 * @property {Array} groups
 * @property {Array} sections
 * @property {Array} selectedSeats
 *
 */

const initialSeatmapState = {
    isLoading: false,
    seats: [],
    groups: [],
    sections: [],
    selectedSeats: [],
};

/**
 *
 * @param {SeatmapReducer} state
 * @param action
 * @returns {SeatmapReducer}
 */
const seatMapReducer = (state = initialSeatmapState, action) => {
    switch (action.type) {
        case types.REQUEST_SEATMAP: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case types.RECEIVE_SEATMAP: {
            const seatmap = action.payload;
            // this will solve itself with normalizr
            const seats = [];
            const sections = [];
            const groups = [];
            for (let i in seatmap.seatSections) {
                const section = seatmap.seatSections[i];
                for (let j in section.seatGroups) {
                    const group = section.seatGroups[j];
                    for (let k in group.seats) {
                        seats.push(group.seats[k]);
                    }
                    groups.push(group);
                }
                sections.push(section);
            }
            return {
                ...state,
                isLoading: false,
                seats,
                groups,
                sections,
            };
        }
        case types.SEATS_WAS_SELECTED: {
            // consider updating each seats status instead
            return {
                ...state,
                selectedSeats: action.payload.seats,
            };
        }
        case types.ADD_SEAT_TO_SELECTION: {
            const selectedSeats = state.selectedSeats.slice();
            return {
                ...state,
                selectedSeats: selectedSeats.concat([action.payload.seatId]),
            };
        }
        case types.REMOVE_SEAT_FROM_SELECTION: {
            const { seatId } = action.payload;
            const seatIndex = state.selectedSeats.indexOf(seatId);
            if (seatIndex < 0) {
                // something is horribly wrong, bail
                return state;
            }
            const selectedSeats = state.selectedSeats.slice();
            selectedSeats.splice(seatIndex, 1);
            return {
                ...state,
                selectedSeats,
            };
        }
        default: {
            return state;
        }
    }
};

/**
 *
 * @type {pricezoneTemplatesState}
 */
const initialPricezoneTemplateState = {
    isLoading: false,
    selectedTemplate: false,
    items: [],
};

const removeSelectedSeats = (zoneSeats, actionSeats) => {
    return zoneSeats.reduce((seats, seat) => {
        if (actionSeats.indexOf(seat) === -1) {
            seats.push(seat);
        }
        return seats;
    }, []);
};

const addSelectedSeats = (zoneSeats, actionSeats) => {
    return actionSeats.reduce((zoneSeats, seat) => {
        if (zoneSeats.indexOf(seat) === -1) {
            zoneSeats.push(seat);
        }
        return zoneSeats;
    }, zoneSeats);
};

/**
 *
 * @param {pricezoneTemplatesState} state
 * @param {{type: String, payload: {id: Number, name: String}}} action
 * @returns {{isLoading: Boolean, selectedTemplate: Boolean|String|Number, items: [Object]}}
 */
export const pricezoneTemplateReducer = (state = initialPricezoneTemplateState, action) => {
    switch (action.type) {
        case types.CREATE_TEMPLATE: {
            return {
                ...state,
                items: state.items.concat([
                    {
                        id: action.payload.id,
                        name: action.payload.name,
                        zones: [],
                    },
                ]),
            };
        }
        case types.SELECT_TEMPLATE: {
            return {
                ...state,
                selectedTemplate: action.payload.id,
            };
        }
        case types.CREATE_NEW_ZONE: {
            const newItems = state.items.map(template => {
                if (template.id !== state.selectedTemplate) {
                    return {
                        ...template,
                    };
                }
                const newId = template.zones.length ? template.zones[template.zones.length - 1].id + 1 : 1;
                return {
                    ...template,
                    zones: template.zones.concat([
                        {
                            id: newId,
                            seats: [],
                            color: zoneColors[(newId - 1) % 9],
                            adjustment: {
                                amount: 0,
                                type: 'kroner',
                            },
                        },
                    ]),
                };
            });
            return {
                ...state,
                items: newItems,
            };
        }
        case types.SEATS_WAS_ADDED_TO_ZONE: {
            const newItems = state.items.map(template => {
                if (state.selectedTemplate !== template.id) {
                    return {
                        ...template,
                    };
                }
                const newZones = template.zones.map(zone => {
                    // first, remove seats from any other zone
                    if (action.payload.zoneId !== zone.id) {
                        return {
                            ...zone,
                            seats: removeSelectedSeats(zone.seats, action.payload.seats),
                        };
                    }
                    return {
                        ...zone,
                        seats: addSelectedSeats(zone.seats, action.payload.seats),
                    };
                });
                return {
                    ...template,
                    zones: newZones,
                };
            });
            return {
                ...state,
                items: newItems,
            };
        }
        case types.SEATS_WAS_REMOVED_FROM_ZONE: {
            const newItems = state.items.map(template => {
                if (state.selectedTemplate !== template.id) {
                    return {
                        ...template,
                    };
                }
                const newZones = template.zones.map(zone => {
                    // first, remove seats from any other zone
                    if (action.payload.zoneId !== zone.id) {
                        return {
                            ...zone,
                        };
                    }
                    return {
                        ...zone,
                        seats: removeSelectedSeats(zone.seats, action.payload.seats),
                    };
                });
                return {
                    ...template,
                    zones: newZones,
                };
            });
            return {
                ...state,
                items: newItems,
            };
        }
        case types.SAVE_TEMPLATES: {
            setLocalStorage(window.location.href, JSON.stringify(state));
            return state;
        }
        case types.FETCH_TEMPLATES: {
            const storedState = JSON.parse(getLocalStorage(window.location.href));
            if (storedState) {
                return storedState;
            }
            return state;
        }
        case types.ZONE_SETTINGS_WAS_CHANGED: {
            const { zoneId } = action.payload;
            let { key, value } = action.payload;
            const newItems = state.items.map(template => {
                if (template.id !== state.selectedTemplate) {
                    return {
                        ...template,
                    };
                }
                const newZones = template.zones.map(zone => {
                    if (zone.id !== zoneId) {
                        return {
                            ...zone,
                        };
                    }
                    if (key === 'direction' && value === 'billigere') {
                        value = zone.adjustment.amount * -1;
                        key = 'amount';
                    }
                    return {
                        ...zone,
                        adjustment: {
                            ...zone.adjustment,
                            [key]: value,
                        },
                    };
                });
                return {
                    ...template,
                    zones: newZones,
                };
            });
            return {
                ...state,
                items: newItems,
            };
        }
        default: {
            return state;
        }
    }
};

export default seatMapReducer;
