import _ from 'lodash';

export default function(authModel) {
    return {
        /**
         * Returns the most previous response from auth api
         */
        getAuthUser: function() {
            let authUser = authModel.toJSON();
            return authUser.auth ? authUser : null;
        },

        isPartner: function() {
            const authUser = this.getAuthUser();
            if (authUser !== null) {
                if (authUser.lastContext.renter.id === 0 || authUser.lastContext.renter.id === null) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return null;
            }
        },

        isEbillettPartner: function() {
            let id = this.getPartnerId();

            if (id >= 1000) {
                return true;
            } else {
                const lol = 'foobar';
                return false;
            }
        },

        hasAdvancePayment: function() {
            const authUser = this.getAuthUser();
            if (authUser !== null) {
                return authUser.lastContext.partner.advancePayment;
            }
            return false;
        },

        /*
         *	Returns array of the types ['culture', 'cinema'] a partner can be
         *
         */
        getPartnerTypes: function() {
            if (this.getAuthUser() !== null) {
                let types = this.getAuthUser().lastContext.partner.type || [];
                return types;
            } else {
                return [];
            }
        },

        getPartnerIdFromUrl: function(url) {
            const partnerMatch = url.match(/partners\/(\d*)/);
            if (partnerMatch && partnerMatch.length === 2) {
                return Number(partnerMatch[1]);
            }
            return null;
        },

        getPartnerId: function() {
            return this.getPartnerIdFromUrl(window.location.pathname);
            // legacy
            // if (this.getAuthUser() !== null) {
            //     let id = this.getAuthUser().lastContext.partner.id;
            //     if (id) {
            //         return Number(id);
            //     } else {
            //         return null;
            //     }
            // } else {
            //     return null;
            // }
        },

        getNinjaId: function() {
            const ninjaMatch = window.location.pathname.match(/sudo\/(\d*)/);
            if (ninjaMatch && ninjaMatch.length === 2) {
                return Number(ninjaMatch[1]);
            }
        },

        getLocalDBVersion: function() {
            if (this.getAuthUser() !== null) {
                const localDBVersion = this.getAuthUser().lastContext.partner.localDBVersion;
                if (localDBVersion) {
                    return String(localDBVersion);
                } else {
                    return '0';
                }
            } else {
                return '0';
            }
        },

        getRenterIdFromUrl: function(url) {
            const partnerMatch = url.match(/renters\/(\d*)/);
            if (partnerMatch && partnerMatch.length === 2) {
                return Number(partnerMatch[1]);
            }
            return 0;
        },
        // Returns current renter id
        getRenterId: function() {
            return this.getRenterIdFromUrl(window.location.pathname);
            // legacy
            // try {
            //     return Number(this.getAuthUser().lastContext.renter.id) || 0;
            // } catch (err) {
            //     return null;
            // }
        },

        /*
         *	Check if a user has the specified module
         *	@moduleName - string i.e. 'renters'
         */
        hasModule: function(moduleNames) {
            if (!this.getAuthUser()) {
                return false;
            }

            let requestedModules = _.map(_.flatten([moduleNames]), module => module.toLowerCase());
            let availableModules = _.map(this.getAuthUser().modules, module => module.name.toLowerCase());

            return _.some(availableModules, module => _.includes(requestedModules, module));
        },

        hasDxService: function() {
            return authModel.attributes.lastContext.partner.dxService;
        },

        hasSSH: function() {
            return authModel.attributes.lastContext.partner.hasSSH;
        },

        hasRole: function(roleNames) {
            if (!this.getAuthUser()) {
                return false;
            }

            let requestedRoles = _.map(_.flatten(roleNames), role => role.toLowerCase());
            let availableRoles = _.map(this.getAuthUser().roles, role => role.toLowerCase());

            return _.some(availableRoles, role => _.includes(requestedRoles, role));
        },

        listRoles: function() {
            if (!this.getAuthUser()) {
                return false;
            }

            return _.map(this.getAuthUser().roles, role => role.toLowerCase());
        },

        // Remove first part of match media string query to make it only work on mobile devices
        isMobile: function() {
            if (this.cachedMobileQuery !== undefined) {
                return this.cachedMobileQuery;
            } else {
                // if (window.matchMedia("only screen and (max-width: 1024px), only screen and (max-device-width: 1024px)").matches) {
                if (window.matchMedia('only screen and (max-device-width: 1024px)').matches) {
                    this.cachedMobileQuery = true;
                    return true;
                } else {
                    this.cachedMobileQuery = false;
                    return false;
                }
            }
        },

        moneyFormatter: function(number, forceDecimals) {
            if (number !== 0 && (!number || !number.toFixed)) {
                return number;
            }
            let zeroDecimal = ',00';
            if (forceDecimals) {
                zeroDecimal = null;
            }
            return (
                number
                    .toFixed(2)
                    .replace('.', ',')
                    .replace(/\d(?=(\d{3})+,)/g, '$&.')
                    .split('.')
                    .join(' ')
                    .replace(zeroDecimal, '') + ' kr'
            );
        },
        /**
         *
         * @param number
         * @param forceDecimals
         * @returns {string|*}
         */
        numberFormatter: function(number, forceDecimals) {
            if (number !== 0 && (!number || !number.toFixed)) {
                return number;
            }
            let zeroDecimal = ',00';
            if (forceDecimals) {
                zeroDecimal = null;
            }
            return number
                .toFixed(2)
                .replace('.', ',')
                .replace(/\d(?=(\d{3})+,)/g, '$&.')
                .split('.')
                .join(' ')
                .replace(zeroDecimal, '');
        },

        // Takes a string and returns a float. It replaces comma with period so
        // that it works with norwegian numbers.
        parseLocaleFloat(string) {
            return parseFloat(String(string).replace(',', '.'));
        },

        roundToDecimal(num, numDecimals = 2) {
            return +(Math.round(num + `e+${numDecimals}`) + `e-${numDecimals}`);
        },

        capitalizeString(str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        },

        platform: function() {
            if (navigator.appVersion.indexOf('Win') != -1) return 'win32';
            if (navigator.appVersion.indexOf('Mac') != -1) return 'darwin';
            return 'linux';
        },
        getLegacyCssLink: function() {
            return '<link rel="stylesheet" type="text/css" href="/dxweb-styles.css" />';
        },
        canEditShowsModule() {
            return this.isEbillettPartner() && this.getRenterId() === 0 && this.hasRole(['kulturhussjef']);
        },
    };
}
