export default {
    body: {
        up: 'core:move-up',
        down: 'core:move-down',
        left: 'core:move-left',
        right: 'core:move-right',
        delete: 'core:delete',
        escape: 'core:cancel',

        'ctrl-alt-s': 'sidebar:toggle',

        'ctrl-a': 'core:select-all',
        'ctrl-shift-a': 'core:deselect-all',

        'ctrl-z': 'core:undo',
        'ctrl-y': 'core:redo',
    },

    '.film-scheduler': {
        s: 'film-scheduler:focus-search',
        'ctrl-a': 'film-scheduler:select-all-on-current-day',
    },

    '.film-scheduler .resource-timeline': {
        'alt-left': 'film-scheduler:move-left-slow',
        'alt-right': 'film-scheduler:move-right-slow',
        'shift-left': 'film-scheduler:move-left-fast',
        'shift-right': 'film-scheduler:move-right-fast',
    },
};
