import * as types from '../actions/productStatusType';

const initialProductStatusType = {
    type: 'activeProducts',
};

const productStatusType = (state = initialProductStatusType, action) => {
    const type = action.payload;
    switch (action.type) {
        case types.SET_PRODUCTS_STATUS_TYPE:
            return {
                ...state,
                type,
            };
        default:
            return state;
    }
};

export default productStatusType;
