export const GET_PRODUCTS_STATUS_TYPE = 'GET_PRODUCTS_STATUS_TYPE';
export const GET_MENUS_STATUS_TYPE = 'GET_MENUS_STATUS_TYPE';

export const SET_PRODUCTS_STATUS_TYPE = 'SET_PRODUCTS_STATUS_TYPE';
export const SET_MENUS_STATUS_TYPE = 'SET_MENUS_STATUS_TYPE';

export const getProductsStatusType = () => ({
    type: GET_PRODUCTS_STATUS_TYPE,
});

export const getMenusStatusType = () => ({
    type: GET_MENUS_STATUS_TYPE,
});

export const setProductsStatusType = response => ({
    type: SET_PRODUCTS_STATUS_TYPE,
    payload: response,
});

export const setMenusStatusType = response => ({
    type: SET_MENUS_STATUS_TYPE,
    payload: response,
});
