import axios from 'axios';
import dx from '../../../global/dx';
import { schema, normalize, denormalize } from 'normalizr';
import ApiAdapterError from './ApiAdapterError';

const partnerId = dx.utils.getPartnerId();
export const baseUrl = '/api/v1/partners/' + partnerId + '/';

const nullifyPromise = p => p.then(() => null);

const defaultState = {
    _pending: false,
    _fetched: false,
    _error: false,
    items: {},
    result: [],
};

const ADD_ENTITIES = 'REST_API_ADAPTER_ADD_ENTITIES';
export const PATCH_ENTITIES = 'REST_API_ADAPTER_PATCH_ENTITIES';

/**
 * Returns a copy of `object` with only whitelisted properties.
 *
 * @param {object} object
 * @param {string[]} keys whitelist
 * @returns {object}
 */
const onlyKeys = (object, keys) => {
    const result = {};

    for (const key of keys) {
        result[key] = object[key];
    }

    return result;
};

/**
 * Returns a copy of `object` where given property is removed
 *
 * @param {object} object
 * @param {string} key
 * @return {object}
 */
const withoutKey = (object, key) => {
    const result = {};

    for (const index in object) {
        if (index != key) {
            result[index] = object[index];
        }
    }

    return result;
};

/*
TODONOTES:

- Complete the reducer builders
    - How to bind method, entity keys and status in reducers?
    - Esp. when dealing with custom actions

 */

const availableMethods = {
    GET: 'get',
    CREATE: 'create',
    UPDATE: 'update',
    DELETE: 'delete',
};

const requestStatuses = ['PENDING', 'FULFILLED', 'FAIL'];

/**
 * ReducerFactory takes no arguments and returns a redux reducer.
 *
 * @typedef {function(): function(object, object): object} ReducerFactory
 */

/**
 * ActionsFactory takes no arguments and returns a object with many redux action
 * creators.
 *
 * @typedef {function(): object} ActionCreatorsFactory
 */

/**
 * Takes a resource key, an api endpoint url and returns configured reducers and action creators
 * for the api endpoint
 *
 * @param {string} entityKey For example "RESOURCE_GROUPS"
 * @param {string} entityUrl The api url, for example "resourceGroups" (resolves to /api/v1/partner/:pid/resourceGroups)
 * @param {schema.Entity} entitySchema Normalizr entity
 * @param {object} customActions
 * @returns {{ reducer: ReducerFactory, actions: ActionCreatorsFactory }}
 */
export const configureApiEntity = (entityKey, entityUrl, entitySchema, customActions = {}) => {
    /**
     * Generates the correct action type for each method/endpoint pair
     * @param {string} method
     * @returns {string}
     */
    const getActionType = method => [method, entityKey].join('_').toUpperCase();

    /**
     * @type {string}
     */
    const _baseUrl = baseUrl;

    /**
     * Generic pending reducer
     * @param {object} state
     * @param {object} action
     */
    const reduceRequest = (state, action) => {
        return {
            ...state,
            _pending: true,
            ...action.payload,
        };
    };

    const addEntitiesReducer = (state, action) => {
        // Note! We're not using `getActionType` here since we want this to run
        // on _all_ ADD_ENTITIES actions. So if a api endpoint expands a lot of
        // models, every normalized model will be added to their respective
        // place in the store by this reducer.
        if (action.type === ADD_ENTITIES) {
            const result = action.requested === entityKey ? action.payload.result : state.result;

            const newState = {
                ...state,
                items: {
                    ...state.items,
                    ...action.payload.entities[entitySchema.key],
                },
                result: Array.isArray(result) ? result : [result],
            };

            return newState;
        }

        return state;
    };

    const patchEntityReducer = (state, action) => {
        if (action.type === PATCH_ENTITIES) {
            const patchedKeys = Object.keys(action.payload.entities[entitySchema.key] || []);
            const newItems = Object.keys(state.items).reduce((all, key) => {
                if (patchedKeys.indexOf(key) > -1) {
                    all[key] = {
                        ...state.items[key],
                        ...action.payload.entities[entitySchema.key][key],
                    };
                } else {
                    all[key] = { ...state.items[key] };
                }
                return all;
            }, {});
            const newState = {
                ...state,
                items: {
                    ...state.items,
                    ...newItems,
                },
            };
            // console.log(entitySchema.key);
            // console.log(state);
            // console.log(newState);
            return newState;
        }

        return state;
    };

    const removeEntityReducer = (state, action) => {
        if (action.type === getActionType('REMOVE')) {
            const id = action.payload;

            return {
                ...state,
                items: withoutKey(state.items, id),
            };
        }

        return state;
    };

    /**
     * Generic success reducer
     * @param {object} state
     * @param {object} action
     */
    const reduceSuccess = (state, action) => {
        return {
            ...state,
            _pending: false,
            _fetched: true,
            _error: false,
            _total: action.payload ? action.payload.total : state._total,
        };
    };

    /**
     * Generic error reducer
     * @param {object} state
     * @param {object} action
     */
    const reduceError = (state, action) => {
        return {
            ...state,
            _pending: false,
            _error: true,
            // maybe the error is available on the action object
            ...action.payload,
        };
    };

    /**
     * Generate a list of action types and pass the state and action to
     * the appropriate generic reducer.
     *
     * @param {string} operation - REST operations
     * @returns {function} the reducer function
     */
    const buildReducerForOperation = operation => (state, action) => {
        const actionTypes = requestStatuses.reduce((all, status) => {
            all[status] = getActionType(operation) + '_' + status.toUpperCase();
            return all;
        }, {});
        if (actionTypes.PENDING === action.type) {
            return reduceRequest(state, action);
        }
        if (actionTypes.FULFILLED === action.type) {
            return reduceSuccess(state, action);
        }
        if (actionTypes.FAIL === action.type) {
            return reduceError(state, action);
        }
        return state;
    };

    /**
     * Takes a options object and converts all properties to query parameters on
     * the given url.
     *
     * @param {string} url
     * @param {object} queryObject
     * @returns {string}
     */
    const parseGetOptions = (url, queryObject = null) => {
        if (!queryObject) {
            return url;
        }
        const optionsArray = Object.keys(queryObject).reduce((arr, optionKey) => {
            // need handling of arrays inside options object
            if (Array.isArray(queryObject[optionKey])) {
                return arr.concat(queryObject[optionKey].map(repeatedOption => optionKey + '[]=' + repeatedOption));
            }
            arr.push(optionKey + '=' + queryObject[optionKey]);
            return arr;
        }, []);
        return url + '?' + optionsArray.join('&');
    };

    return {
        /**
         * Builds the reducer for this resource. Loops over the available methods
         * and returns the reduced state
         *
         * @returns {function(*, *=)} The reducer function for this api resource
         */
        reducer: () => {
            const reducers = [
                buildReducerForOperation(availableMethods.GET),
                buildReducerForOperation(availableMethods.CREATE),
                buildReducerForOperation(availableMethods.UPDATE),
                buildReducerForOperation(availableMethods.DELETE),
                addEntitiesReducer,
                patchEntityReducer,
                removeEntityReducer,
            ];

            // need some way to pry customActions in here
            // HOW TO REDUCE THE NORMALIZED DATA...
            // - maybe only relevant for the success reducer
            // - also need to rename the default action types, this is
            //   getting confusing...
            // this is the REAL reducer
            return (state = defaultState, action) => {
                return reducers.reduce((state, reduxReducer) => reduxReducer(state, action), state);
            };
        },

        /**
         * Returns an object with action creators for use in components
         */
        actions: () => ({
            /**
             *
             * @param {String|Number} id
             */
            get: (id, options = {}) => dispatch => {
                if (typeof id !== 'string' && typeof id !== 'number') {
                    throw new Error('Rest adapter `get` function requires an id parameter');
                }
                const url = _baseUrl + entityUrl + '/' + id;
                const promise = axios.get(parseGetOptions(url, options));
                dispatch({
                    type: getActionType('get'),
                    payload: promise.then(response => {
                        const normalizedData = normalize(response.data, entitySchema);
                        dispatch({
                            type: ADD_ENTITIES,
                            requested: entityKey,
                            payload: {
                                entities: normalizedData.entities,
                                result: normalizedData.result,
                            },
                        });
                        return response.data;
                    }),
                });
                return nullifyPromise(promise);
            },
            /**
             *
             * @param {Object} options
             */
            search: (options = {}) => dispatch => {
                if (typeof options !== 'object') {
                    throw new Error('Rest adapter `search` method must be passed an object as options');
                }
                const url = _baseUrl + entityUrl;
                return new Promise((resolve, reject) => {
                    const promise = axios.get(parseGetOptions(url, options));
                    dispatch({
                        type: getActionType('get'),
                        payload: promise
                            .then(response => {
                                const normalizedData = normalize(response.data.data, [entitySchema]);
                                dispatch({
                                    type: ADD_ENTITIES,
                                    requested: entityKey,
                                    payload: {
                                        entities: normalizedData.entities,
                                        result: normalizedData.result,
                                    },
                                });
                                resolve(null);
                                return response.data;
                            })
                            .catch(error => {
                                reject(error);
                                throw new ApiAdapterError(
                                    `Request to ${error.config.url} failed with status ${error.status}`
                                );
                            }),
                    });
                });
            },
            create: (data, opts = { afterActions: {}, beforeActions: {} }) => dispatch => {
                const url = _baseUrl + entityUrl;
                const promise = axios.post(url, data);
                dispatch({
                    type: getActionType('create'),
                    payload: promise.then(response => {
                        const normalizedData = normalize(response.data, entitySchema);
                        dispatch({
                            type: ADD_ENTITIES,
                            requested: entityKey,
                            payload: {
                                entities: normalizedData.entities,
                                result: normalizedData.result,
                            },
                        });
                        return response.data;
                    }),
                });
                return promise.then(response => response.data.id);
            },

            /**
             * Optimistic update
             */
            update: patch => (dispatch, getState) => {
                const url = _baseUrl + entityUrl + '/' + patch.id;
                let promise = axios.put(url, patch);

                const id = patch.id;

                const entityBefore = { ...getState().api[entitySchema.key].items[id] };
                const patchedProperties = Object.keys(patch);

                const normalizedPatch = normalize(patch, entitySchema);
                const apiEntities = Object.keys(getState().api).reduce((entities, entityKey) => {
                    entities[entityKey] = { ...getState().api[entityKey].items };
                    return entities;
                }, {});

                const denormalizedEntityBefore = denormalize(entityBefore, entitySchema, apiEntities);

                // Optimistically patch
                dispatch({
                    type: PATCH_ENTITIES,
                    payload: normalizedPatch,
                });

                promise = promise.then(
                    response => {
                        // Success
                        const normalizedData = normalize(
                            onlyKeys(response.data, ['id'].concat(patchedProperties)),
                            entitySchema
                        );
                        const changedEntity = normalizedData.entities[entitySchema.key][id];

                        const successPatch = onlyKeys(changedEntity, patchedProperties);

                        dispatch({
                            type: PATCH_ENTITIES,
                            payload: normalizedData,
                        });

                        // If the response to PUT contained other entities
                        // expanded on the result, we should add those to the
                        // store.
                        delete normalizedData.entities[entitySchema.key][id];
                        if (
                            Object.keys(normalizedData.entities).length > 1 ||
                            normalizedData.entities[entitySchema.key].length
                        ) {
                            dispatch({
                                type: ADD_ENTITIES,
                                requested: entityKey,
                                payload: normalizedData,
                            });
                        }
                    },
                    () => {
                        // Error. Rollback the optimistic patch we did...
                        const normalizedRollback = normalize(denormalizedEntityBefore, entitySchema);
                        dispatch({
                            type: PATCH_ENTITIES,
                            payload: normalizedRollback,
                        });
                    }
                );

                // We also dispatch a normal promise action. The
                // promiseMiddleware (see configureStore.jsx) will swallow this
                // and dispatch actions for PENDING and FULFILLED or FAILED.
                dispatch({
                    type: getActionType('update'),
                    payload: promise,
                });

                return nullifyPromise(promise);
            },

            delete: id => (dispatch, getState) => {
                const entity = { ...getState().api[entitySchema.key].items[id] };

                const url = _baseUrl + entityUrl + '/' + id;
                let promise = axios.delete(url);

                if (entity) {
                    // Optimistically delete
                    dispatch({
                        type: getActionType('remove'),
                        payload: id,
                    });

                    promise = promise.then(null, () => {
                        // Puth the entity back into store if it could not
                        // be deleted...
                        dispatch({
                            type: PATCH_ENTITIES,
                            payload: entity,
                        });
                    });
                }

                // We also dispatch a normal promise action. The
                // promiseMiddleware (see configureStore.jsx) will swallow this
                // and dispatch actions for PENDING and FULFILLED or FAILED.
                dispatch({
                    type: getActionType('delete'),
                    payload: promise,
                });

                return nullifyPromise(promise);
            },

            ...customActions,
        }),
    };
};
