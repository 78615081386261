import { configureApiEntity } from '../adapter';
import { schema } from 'normalizr';

const ticketSaleCorrectionSchema = new schema.Entity('ticketSaleCorrection');

export const ticketSaleCorrections = configureApiEntity(
    'TICKETSALECORRECTIONS',
    'ticketsalecorrections',
    ticketSaleCorrectionSchema
);
