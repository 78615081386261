import { configureApiEntity } from '../adapter/index';
import { schema } from 'normalizr';

import { priceZonesSchema } from './priceZone';

export const priceZoneMapSchema = new schema.Entity('priceZoneMaps', {
    priceZones: [priceZonesSchema],
});

export const priceZoneMaps = configureApiEntity('PRICEZONEMAPS', 'pricezonemaps', priceZoneMapSchema);
