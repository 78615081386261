import * as types from '../actions/productStatusType';

const initialMenuStatusType = {
    type: 'activeMenus',
};

const menuStatusType = (state = initialMenuStatusType, action) => {
    switch (action.type) {
        case types.GET_MENUS_STATUS_TYPE:
            return state;
        case types.SET_MENUS_STATUS_TYPE: {
            const type = action.payload;
            return {
                ...state,
                type,
            };
        }
        default:
            return state;
    }
};

export default menuStatusType;
