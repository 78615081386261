export const INTEGRATIONS_FETCH_REQUEST = 'INTEGRATIONS_FETCH_REQUEST';
export const INTEGRATIONS_FETCH_SUCCEEDED = 'INTEGRATIONS_FETCH_SUCCEEDED';
export const INTEGRATIONS_FETCH_FAILED = 'INTEGRATIONS_FETCH_FAILED';

export const INTEGRATION_REMOVE_REQUEST = 'INTEGRATION_REMOVE_REQUEST';
export const INTEGRATION_REMOVE_SUCCEEDED = 'INTEGRATION_REMOVE_SUCCEEDED';
export const INTEGRATION_REMOVE_FAILED = 'INTEGRATION_REMOVE_FAILED';

export const INTEGRATION_ERROR_CLEAR = 'INTEGRATION_ERROR_CLEAR';

export const INTEGRATION_ADD_REQUEST = 'INTEGRATION_ADD_REQUEST';
export const INTEGRATION_ADD_SUCCEEDED = 'INTEGRATION_ADD_SUCCEEDED';
export const INTEGRATION_ADD_FAILED = 'INTEGRATION_ADD_FAILED';
