import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, useRouteMatch } from 'react-router-dom';
import dx from '../../dx';
import $ from 'jquery';

export default class ModuleLink extends Component {
    static propTypes = {
        tooltip: PropTypes.bool,
        active: PropTypes.bool,
        label: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        aHrefLink: PropTypes.bool,
        icon: PropTypes.string.isRequired,
        partnerId: PropTypes.number,
        renterId: PropTypes.number,
        onClickLink: PropTypes.func.isRequired,
    };

    static defaultProps = {};

    componentDidMount() {
        this.componentDidUpdate();
    }

    componentDidUpdate() {
        if (this.props.tooltip) {
            $(this.listItem).tooltip({
                container: 'body',
                placement: 'right',
            });
        } else {
            $(this.listItem).tooltip('destroy');
        }
    }

    render() {
        return (
            <li ref={el => (this.listItem = el)} className={this.props.active ? 'active' : ''} title={this.props.label}>
                <MaybePageLoadLink
                    onClickLink={this.props.onClickLink}
                    url={this.props.url}
                    partnerId={this.props.partnerId}
                    renterId={this.props.renterId}
                    aHrefLink={this.props.aHrefLink}
                >
                    <i className={`fa fa-${this.props.icon} fa-fw`} />
                    <span className="main-sidebar-label">&nbsp;{this.props.label}</span>
                </MaybePageLoadLink>
            </li>
        );
    }
}

const MaybePageLoadLink = ({ url, partnerId, renterId, onClickLink, aHrefLink, children }) => {
    if (
        useRouteMatch(url) ||
        partnerId !== dx.utils.getPartnerIdFromUrl(url) ||
        renterId !== dx.utils.getRenterIdFromUrl(url) ||
        aHrefLink
    ) {
        return <a href={url}>{children}</a>;
    } else {
        return (
            <Link to={url} onClick={onClickLink}>
                {children}
            </Link>
        );
    }
};
MaybePageLoadLink.propTypes = {
    url: PropTypes.string.isRequired,
    partnerId: PropTypes.number,
    renterId: PropTypes.number,
    onClickLink: PropTypes.func.isRequired,
    aHrefLink: PropTypes.bool,
};
