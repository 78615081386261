import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Dropdown, MenuItem } from 'react-bootstrap';
import classnames from 'classnames';
import { toggleSidebar } from '../../actions/sidebarActions';

const sudoIcon = '/img/app/ninja.png';

const isNullOrEmpty = value => typeof value === 'undefined' || value === null || value.trim() === '';
const getDisplayName = (fName, lName) => fName.trim() || lName.trim();

class UserContextMenu extends Component {
    static propTypes = {
        username: PropTypes.string,
        sudo: PropTypes.bool,
    };

    static defaultProps = {};

    state = {
        expanded: false,
    };

    toggle = () => {
        this.setState(
            state => ({
                // toggle the expanded state of this context menu,
                // but if the sidebar is collapsed, always keep
                // the context menu open
                expanded: !state.expanded || !this.props.sidebarIsExpanded,
            }),
            () => {
                this.props.dispatch(toggleSidebar(true));
            }
        );
    };

    render() {
        const listClasses = classnames({
            expanded: this.state.expanded,
            'user-context-menu': true,
        });

        return (
            <li className={listClasses}>
                <a role="button" onClick={this.toggle}>
                    <span className="main-sidebar-label">
                        <div className="main-sidebar-label__display-name">{this.props.username}</div>
                    </span>
                </a>
                <ul>
                    <li>
                        <a href="/brukerkonto">{'Din profil'}</a>
                    </li>
                    {this.props.sudo && (
                        <li>
                            <a href="/">{'Avslutt sudo'}</a>
                        </li>
                    )}
                    <li>
                        <a href="/logout">{'Logg ut'}</a>
                    </li>
                </ul>
            </li>
        );
    }
}

const mapStateToProps = state => {
    const { sudo, email, firstName, lastName } = state.auth.user;
    const { expanded: sidebarIsExpanded } = state.ui.sidebar;
    const username = isNullOrEmpty(firstName) && isNullOrEmpty(lastName) ? email : getDisplayName(firstName, lastName);
    return {
        username,
        sudo: sudo,
        sidebarIsExpanded,
    };
};

export default connect(mapStateToProps)(UserContextMenu);
