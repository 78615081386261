import React from 'react';
import PropTypes from 'prop-types';
import 'bootstrap';
import store from '../../store/createStore';
import { connect } from 'react-redux';
import classnames from 'classnames';
import Toggler from './Toggler';
import ModuleList from './ModuleList';
import { toggleSidebar } from '../../actions/sidebarActions';
import Roadblock from '../../Roadblock';
import { displayGDPRRoadblock } from '../Agreement/actions';

function runMobileMediaQuery() {
    return window.matchMedia('only screen and (max-device-width: 1024px)').matches;
}

class SidebarComponent extends React.PureComponent {
    static propTypes = {
        partnerId: PropTypes.number,
        renterId: PropTypes.number,
        waitingForAuth: PropTypes.bool,
        items: PropTypes.arrayOf(
            PropTypes.shape({
                type: PropTypes.oneOf(['module', 'spacer']).isRequired,
                icon: PropTypes.string,
                url: PropTypes.string,
                aHrefLink: PropTypes.bool,
                label: PropTypes.string,
                active: PropTypes.bool,
            })
        ),
    };

    constructor(props) {
        super(props);

        const isMobile = runMobileMediaQuery();

        window.document.addEventListener('visibilitychange', this.documentDidChangeVisibility);

        this.state = {
            isMobile,
            displayRoadblock: true,
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.windowResized);

        this.componentDidUpdate();

        // Had to move these transitions out from css and into here so they
        // shouldn't trigger on pageload.
        window.setTimeout(() => {
            window.document.head.insertAdjacentHTML(
                'beforeend',
                `
				<style>
					.main, .navbar { transition: left 250ms; }
					.main-sidebar { transition: width 250ms, left 250ms; }
					.main-sidebar-label { transition: opacity 250ms; }
				</style>
			`
            );
        }, 1000);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.windowResized);
        window.removeEventListener('visibilitychange', this.documentDidChangeVisibility);
    }

    componentDidUpdate() {
        // Setting the .sidebar-collapsed class on the body element. The styling
        // is then controlled from CSS. Having side effects like this is not
        // ideal, but right now it is too much work to refactor this. The thing
        // is that the .sidebar-collapsed class also affects other elements like
        // navbar and content.
        window.document.body.classList[this.props.expanded ? 'remove' : 'add']('sidebar-collapsed');
        window.document.body.classList[this.state.isMobile ? 'add' : 'remove']('sidebar-mobile');

        // If on mobile, we'll render the close button inside the navbar.
        // ReactDOM.render(
        //     this.state.isMobile ? this.renderToggleButton() : <span />,
        //     this.navbarPlaceholder
        // );
    }

    // A event listener for document `visibilitychange`. So if the user opens
    // the tab with this page, we should update the expanded state in
    // localStorage, so if the user opens a link in a new tab, the state of the
    // sidebar should become the same as in this window.
    documentDidChangeVisibility = () => {
        if (window.document.visibilityState === 'visible') {
            this.setExpanded(this.props.expanded);
        }
    };

    windowResized = () => {
        const isMobile = runMobileMediaQuery();

        if (isMobile !== this.state.isMobile) {
            this.setState({ isMobile });
        }
    };

    // The expanded state is saved in both sessionStorage and localStorage. When
    // the user navigates to another page, we first check the sessionStorage, so
    // the state of the sidebar will be consistent per browser tab/window. But
    // if he opens a new window, it will be read from localStorage.
    setExpanded(value) {
        try {
            if (window.sessionStorage) {
                window.sessionStorage.setItem('sidebarExpanded', value);
            }

            if (window.localStorage) {
                window.localStorage.setItem('sidebarExpanded', value);
            }
        } catch (err) {
            // Gonna ignore this error. It is probably an error from Safari when
            // using private mode. And it is important that we don't break
            // execution here, because the user will not get the sidebar open
            // then.
        }

        if (this.props.expanded !== value) {
            this.props.dispatch(toggleSidebar(value));
        }
    }

    toggle = () => {
        this.setExpanded(!this.props.expanded);
    };

    handleUpdateGDPRDisplayRoadBlock = value => {
        this.setState({ displayRoadblock: value }, () => store.dispatch(displayGDPRRoadblock(value)));
    };

    onClickLink = () => {
        if (this.state.isMobile) {
            this.props.dispatch(toggleSidebar(false));
        }
    };

    render() {
        const classes = classnames({
            collapsed: !this.props.expanded,
        });
        return (
            <div className={classes}>
                {this.props.togglerVisible && (
                    <Toggler isMobile={this.state.isMobile} isExpanded={this.props.expanded} onToggle={this.toggle} />
                )}
                <div className="main-sidebar">
                    <ModuleList
                        partnerId={this.props.partnerId}
                        renterId={this.props.renterId}
                        modules={this.props.items}
                        tooltip={!this.props.expanded && !this.state.isMobile}
                        onClickLink={this.onClickLink}
                    />
                    {!this.props.sudo && !this.props.waitingForAuth && (
                        <Roadblock
                            displayRoadblock={this.state.displayRoadblock}
                            updateGDPRDisplayRoadBlock={this.handleUpdateGDPRDisplayRoadBlock}
                        />
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { expanded, togglerVisible } = state.ui.sidebar;
    const { sudo } = state.auth.user;
    return {
        expanded,
        togglerVisible,
        sudo,
    };
};

export default connect(mapStateToProps)(SidebarComponent);
