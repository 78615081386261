import { SCREEN_RESIZED } from '../../actionCreators/ui/screen';

const SMALL = 'SMALL';
const MEDIUM = 'MEDIUM';
const LARGE = 'LARGE';

export const sizes = {
    SMALL,
    MEDIUM,
    LARGE,
};

const getSizeFromPixelWidth = w => {
    if (w < 769) {
        return SMALL;
    }
    if (w < 1025) {
        return MEDIUM;
    }
    return LARGE;
};

const reducer = (state = {}, action) => {
    switch (action.type) {
        case SCREEN_RESIZED: {
            return {
                ...state,
                size: getSizeFromPixelWidth(action.payload.width),
                isMobile: action.payload.width <= 1024,
            };
        }
    }
    return state;
};

export default reducer;
