import store from '../store/createStore';
import { screenWasResized } from '../actionCreators/ui/screen';

// from https://stackoverflow.com/questions/1248081/get-the-browser-viewport-dimensions-with-javascript
export const getScreenSize = () => Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

store.dispatch(screenWasResized(getScreenSize()));

let window_resize_timeout;

export const windowResizeHandler = () => {
    clearTimeout(window_resize_timeout);
    window_resize_timeout = setTimeout(() => {
        const w = getScreenSize();
        store.dispatch(screenWasResized(w));
    }, 50);
};
