import rootReducer from '../reducers';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import { createStore, applyMiddleware } from 'redux';

const middleware = [thunk, promise];

const configureStore = initialState => createStore(rootReducer, initialState, applyMiddleware(...middleware));

export default configureStore;
