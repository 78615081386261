export const MODE_REPORTS_FETCH_PENDING = 'MODE_REPORTS_FETCH_PENDING';
export const MODE_REPORTS_FETCH_SUCCESS = 'MODE_REPORTS_FETCH_SUCCESS';
export const MODE_REPORTS_FETCH_FAILED = 'MODE_REPORTS_FETCH_FAILED';

export const MODE_REPORTS_CREATE_PENDING = 'MODE_REPORTS_CREATE_PENDING';
export const MODE_REPORTS_CREATE_SUCCESS = 'MODE_REPORTS_CREATE_SUCCESS';
export const MODE_REPORTS_CREATE_FAILED = 'MODE_REPORTS_CREATE_FAILED';

export const MODE_REPORTS_UPDATE_PENDING = 'MODE_REPORTS_UPDATE_PENDING';
export const MODE_REPORTS_UPDATE_SUCCESS = 'MODE_REPORTS_UPDATE_SUCCESS';
export const MODE_REPORTS_UPDATE_FAILED = 'MODE_REPORTS_UPDATE_FAILED';

export const MODE_REPORTS_ROLES_PENDING = 'MODE_REPORTS_ROLES_PENDING';
export const MODE_REPORTS_ROLES_SUCCESS = 'MODE_REPORTS_ROLES_SUCCESS';
export const MODE_REPORTS_ROLES_FAILED = 'MODE_REPORTS_ROLES_FAILED';

export const MODE_REPORTS_SET_SIGNED_URL = 'MODE_REPORTS_SET_SIGNED_URL';

export const MODE_REPORTS_PARAM_TYPES_FETCH_SUCCESS = 'MODE_REPORTS_PARAM_TYPES_FETCH_SUCCESS';
