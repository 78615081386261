export const REQUEST_KIOSKREPORT = 'REQUEST_KIOSKREPORT';
export const RECEIVE_KIOSKREPORT = 'RECEIVE_KIOSKREPORT';

export const REQUEST_LAST_YEAR_AND_CURRENT_MONTHS_LAST_YEAR_SALES_REPORT =
    'REQUEST_LAST_YEAR_AND_CURRENT_MONTHS_LAST_YEAR_SALES_REPORT';
export const RECEIVE_LAST_YEAR_AND_CURRENT_MONTHS_LAST_YEAR_SALES_REPORT =
    'RECEIVE_LAST_YEAR_AND_CURRENT_MONTHS_LAST_YEAR_SALES_REPORT';

export const REQUEST_SO_FAR_THIS_YEAR_SALES_REPORT = 'REQUEST_SO_FAR_THIS_YEAR_SALES_REPORT';
export const RECEIVE_SO_FAR_THIS_YEAR_SALES_REPORT = 'RECEIVE_SO_FAR_THIS_YEAR_SALES_REPORT';
