// Almost like Atoms CompositeDisposable, but it also works on objects that has
// a destroy() or remove() method.
//
// @see https://atom.io/docs/api/v0.196.0/CompositeDisposable

const disposeMethods = ['dispose', 'destroy', 'remove'];

let findMethod = (object, methods) => {
    let method = null;

    for (let i in methods) {
        if (typeof object[methods[i]] === 'function') {
            method = object[methods[i]].bind(object);
            break;
        }
    }

    return method;
};

export class Disposable {
    constructor(disposeFunc) {
        if (typeof disposeFunc === 'undefined') {
            let err = 'Made a undefined disposable';
            console.warn(err);
        }

        this.disposeFunc = disposeFunc;
    }

    dispose() {
        if (this.disposeFunc) {
            this.disposeFunc();
            delete this.disposeFunc;
        }
    }
}

export class CompositeDisposable extends Disposable {
    constructor(...disposables) {
        super(() => {});
        this.items = [];
        this.add(...disposables);
    }

    add(...disposables) {
        disposables.map(disposable => {
            if (disposable instanceof Array) {
                this.add(...disposable);
            } else {
                this.items.push(disposable);
            }
        });
        return this;
    }

    dispose() {
        this.items.map((disposable, idx) => {
            let method = findMethod(disposable, disposeMethods);
            if (method == null) {
                throw new Error('Tried to dispose a object, but it was not disposable.');
            }
            method();
        });
        this.items = [];
    }

    remove(disposable) {
        let idx = this.items.indexOf(disposable);
        if (idx !== -1) {
            this.items.splice(idx, 1);
        }
    }

    clear() {
        this.items = [];
    }
}

const addMethods = ['addEventListener', 'addListener', 'on'];
const removeMethods = ['removeEventListener', 'removeListener', 'off'];

export function makeDisposableListener(target, event, callback) {
    let add = findMethod(target, addMethods);

    if (add == null) {
        throw new Error('Target object needs to have one of these methods: ' + addMethods.join(', '));
    }

    add(event, callback);

    return new Disposable(() => {
        let remove = findMethod(target, removeMethods);
        remove(event, callback);
    });
}

export function listenOnce(target, event, callback) {
    let disposable = makeDisposableListener(target, event, function() {
        disposable.dispose();
        callback.apply(this, arguments);
    });
}
