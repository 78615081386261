import Backbone from 'backbone';

// Hijack Backbone.sync and add bookkeepling about requests. Used by
// EmptyGridMessage.jsx
let originalSync = Backbone.sync;
Backbone.sync = function() {
    if (!this.ongoingRequests) {
        this.ongoingRequests = 0;
    }

    if (!this.countSyncs) {
        this.countSyncs = 0;
    }

    this.ongoingRequests++;

    const promise = originalSync.apply(this, arguments);

    promise.then(
        () => {
            this.countSyncs++;
            this.ongoingRequests--;
        },
        () => {
            this.ongoingRequests++;
        }
    );

    return promise;
};
Backbone.Collection.prototype.hasSynced = Backbone.Model.prototype.hasSynced = function() {
    return this.countSyncs > 0;
};

const typeFromUrlRegex = /\/api\/v1\/(?:partners\/\d+\/|)(.*?)(\?|\/|$)/;

Backbone.Model = Backbone.Model.extend({
    //hans this used to be a constructor, will this work equally good?
    preinitialize() {
        this._data = null;
        this.listenTo(this, 'change', () => (this._data = null));
    },

    /**
     * Returns a immutable clone of the attributes on the model. And recursively
     * supports nested models and collections as attributes.
     *
     * @returns {object}
     */
    getData() {
        if (this._data === null) {
            this._data = {};

            for (const key in this.attributes) {
                if (this.attributes.hasOwnProperty(key)) {
                    const value = this.attributes[key];
                    this._data[key] = value && value.getData ? value.getData() : value;
                }
            }
        }

        return this._data;
    },

    /**
     * Properly triggers a change event without actually chaning anything.
     * Useful when we need to notify that something has changed in a nested
     * model/collection.
     */
    triggerChange(attribute) {
        const value = this.get(attribute);

        this._previousAttributes = { ...this.attributes };
        this.changed = { [attribute]: value };

        this.trigger('change:' + attribute, this, value, {});
        this.trigger('change', this, {});
    },

    /**
     * Overriding the default toJSON to support nested models and collections as
     * attributes.
     */
    toJSON() {
        const output = {};

        for (const key in this.attributes) {
            if (this.attributes.hasOwnProperty(key)) {
                const value = this.attributes[key];
                output[key] = value && value.toJSON ? value.toJSON() : value;
            }
        }

        return output;
    },

    /**
     * Extracts the model name from the url of the collection/model. Do not use this
     * in production code, but it is sometimes very handy when debugging.
     */
    getTypeForDebugging() {
        const url = typeof this.url === 'function' ? this.url() : this.url;
        return typeFromUrlRegex.exec(url)[1];
    },
});

Backbone.Collection = Backbone.Collection.extend({
    model: Backbone.Model,

    //hans this used to be a constructor, will this work equally good?
    preinitialize() {
        this._data = null;
        this.listenTo(this, 'update reset change', () => (this._data = null));
    },

    getData() {
        if (this._data === null) {
            this._data = this.models.map(model => model.getData());
        }

        return this._data;
    },

    /**
     * Extracts the model name from the url of the collection/model. Do not use this
     * in production code, but it is sometimes very handy when debugging.
     */
    getTypeForDebugging() {
        const url = typeof this.url === 'function' ? this.url() : this.url;
        return typeFromUrlRegex.exec(url)[1];
    },
});
