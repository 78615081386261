import _ from 'lodash';
import config from '../../config/dxconfig';
import dx from '../dx';
import Raven from 'raven-js';

import store from '../../global/store/createStore';
import { updateIntercomUnreadCount } from '../actionCreators/auth';

function subscribeToIntercomUnreadCount() {
    window.analytics.ready(() => {
        window.Intercom('onUnreadCountChange', unreadCount => {
            dx.authModel.set({
                intercomUnreadCount: unreadCount,
            });
            store.dispatch(updateIntercomUnreadCount(unreadCount));
        });
    });
}

function shouldHaveIntercom(rawAuthObject) {
    if (!rawAuthObject) {
        return false;
    }

    const roles = rawAuthObject.roles || [];
    const isVolunteerOnly = roles.length === 1 && roles[0] === 'volunteer';

    // Users who are volunteers only should not have intercom
    if (isVolunteerOnly) {
        return false;
    }

    // Disable intercom if we're in sudo mode
    const ninjaModeId = dx.utils.getNinjaId();

    if (ninjaModeId) {
        return false;
    }

    return true;
}

const Tracking = function() {
    this.lastPageTracked = null;

    const enableIntercom = shouldHaveIntercom(window.dxAuth);

    this.options = {
        integrations: {
            Intercom: enableIntercom,
        },
    };

    Raven.install();
    // Sentry/Raven
    if (config.sentryDsn) {
        Raven.config(config.sentryDsn, {
            release: config.releaseName,
        }).install();
    }

    window.intercomSettings = {
        custom_launcher_selector: '#intercom-launcher',
        hide_default_launcher: true,
    };

    // Segment
    if (config.segmentKey) {
        this.initSegment(config.segmentKey);
        window.analytics.ready(subscribeToIntercomUnreadCount);
    }
};

// Copy paste from "Getting started" in Segment docs.
Tracking.prototype.initSegment = function(segmentKey) {
    const analytics = (window.analytics = window.analytics || []);
    if (!analytics.initialize)
        if (analytics.invoked) window.console && console.error && console.error('Segment snippet included twice.');
        else {
            analytics.invoked = !0;
            analytics.methods = [
                'trackSubmit',
                'trackClick',
                'trackLink',
                'trackForm',
                'pageview',
                'identify',
                'reset',
                'group',
                'track',
                'ready',
                'alias',
                'debug',
                'page',
                'once',
                'off',
                'on',
                'addSourceMiddleware',
                'addIntegrationMiddleware',
                'setAnonymousId',
                'addDestinationMiddleware',
            ];
            analytics.factory = function(e) {
                return function() {
                    var t = Array.prototype.slice.call(arguments);
                    t.unshift(e);
                    analytics.push(t);
                    return analytics;
                };
            };
            for (let e = 0; e < analytics.methods.length; e++) {
                const key = analytics.methods[e];
                analytics[key] = analytics.factory(key);
            }
            analytics.load = function(key, e) {
                const t = document.createElement('script');
                t.type = 'text/javascript';
                t.async = !0;
                t.src = 'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js';
                const n = document.getElementsByTagName('script')[0];
                n.parentNode.insertBefore(t, n);
                analytics._loadOptions = e;
            };
            analytics._writeKey = segmentKey;
            analytics.SNIPPET_VERSION = '4.13.2';
            analytics.load(segmentKey);
            analytics.page();
        }
};

Tracking.prototype.identify = function(userAuthObject) {
    const moduleNames = _.map(userAuthObject.modules, mod => mod.name);

    const roles = _.get(userAuthObject, 'roles') || [];

    const id = userAuthObject.auth.id;

    const ninjaUrl = `${window.location.origin}/sudo/${id}`;

    const user = {
        id,
        name: userAuthObject.auth.displayName,
        email: userAuthObject.auth.email,
        isRenter: Boolean(userAuthObject.lastContext.renter.id),
        partnerName: userAuthObject.lastContext.partner.name,
        company: {
            name: userAuthObject.lastContext.partner.name,
            company_id: userAuthObject.lastContext.partner.id,
        },
        partnerId: userAuthObject.lastContext.partner.id,
        renterId: userAuthObject.lastContext.renter.id,
        is_ebill_partner: userAuthObject.lastContext.partner.id > 1000,
        has_Modules: moduleNames.join('|'),
        roles: roles.join('|'),
        timezoneOffset: new Date().getTimezoneOffset(),
        ninjaUrl,
    };

    if (config.sentryDsn) {
        Raven.setUserContext(user);
    }

    if (window.analytics) {
        this.options = _.extend({}, this.options, {
            integrations: {
                Intercom: shouldHaveIntercom(userAuthObject),
            },
        });

        window.analytics.identify(user.id, user, this.options);
    }
};

Tracking.prototype.action = function(args) {
    if (window.analytics) {
        window.analytics.track(args.title, args.props, _.extend({}, this.options));
    }
};

Tracking.prototype.page = function(path, opts = {}) {
    if (window.analytics) {
        let url = path;

        // Router triggers two navigate events, block the second one
        if (url === this.lastPageTracked) return;

        window.analytics.page(url, null, {}, _.extend(opts, this.options));
        this.lastPageTracked = url;
    }
};

// Track the user. Event can be whatever name you want. Properties can be used
// to attach data to the event.
Tracking.prototype.track = function(event, properties) {
    if (window.analytics) {
        window.analytics.track(event, properties);
    }
};

export default new Tracking();
