import Backbone from 'backbone';
import urijs from 'urijs';
import $ from 'jquery';
import dx from '../dx';

export default Backbone.Model.extend({
    url: '/api/v1/auth',

    defaults: {
        ninaModeId: null,
    },

    initialize(options) {
        Backbone.Model.prototype.initialize.apply(this, options);

        this.listenTo(this, 'change', () => {
            let userAuthObject = this.toJSON();
            if (!userAuthObject.auth) {
                userAuthObject = null;
            } else {
                $('html').toggleClass('ninja-mode', !!dx.utils.getNinjaId());
            }
            Backbone.trigger('contextSwitched', userAuthObject);
        });
    },

    enterNinja(id) {
        window.location.replace('/sudo/' + id);
    },

    exitNinja() {
        window.location.replace('/');
    },

    // Called from App.jsx on every API jquery calls...
    apiv1Middleware(jqxhr) {
        const partnerId = dx.utils.getPartnerId();
        const renterId = dx.utils.getRenterId();
        const ninjaId = dx.utils.getNinjaId();
        partnerId && jqxhr.setRequestHeader('X-Dx-Partner', partnerId);
        renterId && jqxhr.setRequestHeader('X-Dx-Renter', renterId);
        ninjaId && jqxhr.setRequestHeader('X-Dx-Ninja', ninjaId);
    },
});
