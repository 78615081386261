import { t } from '../../../global/locale';
import * as types from '../actions/period';
import { getPeriodTypeFromDates } from '../../../global/utils/period';
import { WEEK, MONTH, YEAR, PERIOD } from '../../../global/utils/period';

const initialState = {
    periodType: {
        id: 'lastThirtyDays',
        value: t('Siste 30 dager'),
    },
    from: '',
    to: '',
};

/**
 *
 * @param {periodReducer} state
 * @param action
 * @returns {periodReducer}
 */
const period = (state = initialState, action) => {
    if (!action.payload) {
        return state;
    }

    const periodType =
        action.payload.periodType &&
        (action.payload.periodType.type === YEAR ||
            action.payload.periodType.type === MONTH ||
            action.payload.periodType.type === WEEK ||
            action.payload.periodType.type === PERIOD)
            ? action.payload.periodType
            : getPeriodTypeFromDates(action.payload.from, action.payload.to);
    switch (action.type) {
        case types.CHANGE_PERIOD:
            return {
                ...state,
                from: action.payload.from,
                to: action.payload.to,
                periodType: periodType,
            };
        default:
            return state;
    }
};

export default period;
