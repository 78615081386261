import * as types from '../../components/Agreement/actions';

const initialState = {
    displayGDPRRoadblock: false,
};

const GDPRRoadblock = (state = initialState, action) => {
    switch (action.type) {
        case types.DISPLAY_GDPR_ROADBLOCK:
            return {
                ...state,
                displayGDPRRoadblock: action.payload.value,
            };
        default:
            return state;
    }
};

export default GDPRRoadblock;
