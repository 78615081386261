// This init script is a common startup script needed for all modules

import React from 'react';
import _ from 'lodash';
import dx from './dx';
import $ from 'jquery';
import nprogress from 'nprogress';
import tracking from './utils/tracking';
import keymapCommon from './keymaps/common';
import keymapDarwin from './keymaps/darwin';
import { BrowserRouter as Router } from 'react-router-dom';
import { withRouter } from 'react-router';
import DxPopup from './components/DxPopup';
import './backboneOverrides';
import { Provider, connect } from 'react-redux';
import Raven from 'raven-js';

import store from './store/createStore';
import { setInitialAuthData } from './actionCreators/auth';

import { windowResizeHandler } from './utils/screenUtils';

import moment from 'moment';
import 'moment/locale/nb';
import axios from 'axios';

moment.locale('nb');

// set default header for axios, so backend recognizes it as a ajax request,
// and will return 401 if unauthenticated, instead of redirecting to login, which wont work anyways because axios-request it not a pageload
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

axios.interceptors.request.use(
    function(config) {
        // Do something before request is sent
        const authStore = store.getState().auth;
        if (authStore.context.partner && authStore.context.partner.id) {
            config.headers['X-Dx-Partner'] = authStore.context.partner.id;
        }
        if (authStore.context.renter && authStore.context.renter.id) {
            config.headers['X-Dx-Renter'] = authStore.context.renter.id;
        }
        if (authStore.user.sudo) {
            config.headers['X-Dx-Ninja'] = authStore.user.sudoId;
        }
        return config;
    },
    function(error) {
        // Do something with request error
        return Promise.reject(error);
        // if (xhr.status === 503 && _.get(xhr, 'responseJSON.type') === 'hangar-is-down') {
        //     if (!isDisplayingError) {
        //         isDisplayingError = true;
        //
        //         DxPopup.errorMessage(_.get(xhr, 'responseJSON.message'), 'Feil', 'Lukk').then(() => {
        //             isDisplayingError = false;
        //         });
        //
        //         tracking.track('got-hangar-is-down-error');
        //     }
        // }
    }
);

axios.interceptors.response.use(null, error => {
    const status = error.response && error.response.status;
    const headers = (error.response && error.response.headers) || {};
    if (status === 401) {
        window.location.reload();
    }
    if (status === 503 && headers['x-dx-maintenance-mode'] === 'true') {
        document.write(error.response.data);
    }
    const data = {
        extra: {
            type: error.config.method,
            url: error.config.url,
            data: error.config.data,
            status,
            error: error,
            response: error.message.substring(0, 100),
        },
    };
    Raven.captureMessage(error, data);
    return Promise.reject(error.response);
});

window.addEventListener('resize', windowResizeHandler);

// Polyfill for matches
if (!Element.prototype.matches) {
    if (Element.prototype.matchesSelector) {
        Element.prototype.matches = Element.prototype.matchesSelector;
    } else if (document.querySelectorAll) {
        Element.prototype.matches = function(selector) {
            let matches = (this.document || this.ownerDocument).querySelectorAll(selector);
            let i = 0;

            while (matches[i] && matches[i] !== this) {
                i++;
            }

            return !!matches[i];
        };
    }
}

// Setup keymaps
dx.keymapResolver.add('common', keymapCommon);
dx.keymapResolver.add('darwin', keymapDarwin);
dx.keymapResolver.start();

// Setup progress bar (nprogress)
nprogress.configure({ showSpinner: false });
$(() => {
    let counter = 0;

    $(document)
        .ajaxStart(() => {
            if (counter < 0) {
                counter = 0;
            }
            if (counter === 0) {
                nprogress.start();
            }
            counter++;
        })
        .ajaxStop(() => {
            if (counter === 1) {
                nprogress.done();
            }
            counter--;
        });
});

$(() => {
    let isDisplayingError = false;

    $(document).ajaxError(function(event, xhr, ajaxSettings, thrownError) {
        if (xhr.status === 401) {
            window.location.reload();
        }
        if (xhr.status >= 500) {
            Raven.captureMessage(thrownError || xhr.statusText, {
                extra: {
                    type: ajaxSettings.type,
                    url: ajaxSettings.url,
                    data: ajaxSettings.data,
                    status: xhr.status,
                    error: thrownError || xhr.statusText,
                    response: xhr.responseText.substring(0, 100),
                },
            });
        }
        if (xhr.status === 503 && xhr.getResponseHeader('x-dx-maintenance-mode') === 'true') {
            document.write(xhr.responseText);
        }
        if (xhr.status === 503 && _.get(xhr, 'responseJSON.type') === 'hangar-is-down') {
            if (!isDisplayingError) {
                isDisplayingError = true;

                DxPopup.errorMessage(_.get(xhr, 'responseJSON.message'), 'Feil', 'Lukk').then(() => {
                    isDisplayingError = false;
                });

                tracking.track('got-hangar-is-down-error');
            }
        }
    });
});

// Function to call on each api request. Ensures that we ping the
// server when we are idle too long.
let pingIfIdle = (() => {
    let ping = () => {
        // This function should be further extended to do a login
        // if the session already is expired (that can happen if
        // the computer has been suspended).
        $.ajax(dx.config.url.api.auth);
    };

    let timeout = null;

    return () => {
        if (timeout !== null) {
            window.clearTimeout(timeout);
        }
        timeout = window.setTimeout(ping, dx.config.api.sessionTimeout);
    };
})();

// Hook up all ajax calls and send current partner & renter id.
$(document).ajaxSend((event, jqxhr, settings) => {
    if (settings.url.indexOf('/api/v1') !== -1) {
        if (settings.type && settings.type.toUpperCase() === 'PATCH') {
            settings.type = 'PUT';
        }

        pingIfIdle();
        dx.authModel.apiv1Middleware(jqxhr, settings);
    }
});

class HistoryListener extends React.Component {
    componentDidMount() {
        this.unlisten = this.props.history.listen((location, action) => {
            tracking.page(location, {
                integrations: {
                    'Google Analytics': true,
                },
            });
        });
    }

    componentWillUnmount() {
        this.unlisten();
    }
    render() {
        return <React.Fragment>{!this.props.displayGDPRRoadblock && this.props.children}</React.Fragment>;
    }
}

const ConnectedHistoryListener = connect(state => ({
    displayGDPRRoadblock: state.ui.GDPRRoadblock.displayGDPRRoadblock,
}))(HistoryListener);

const TrackerWithRoadblock = withRouter(ConnectedHistoryListener);

export function renderModuleRoutes(module, routes) {
    let url = '';

    const ninjaId = dx.utils.getNinjaId();
    if (ninjaId) {
        url = '/sudo/' + ninjaId;
    }

    const partnerId = dx.utils.getPartnerId();
    url += '/partners/' + partnerId;

    const renterId = dx.utils.getRenterId();
    if (renterId) {
        url += '/renters/' + renterId;
    }

    url += '/' + module + '/';

    return (
        <Provider store={store}>
            <Router basename={url} forceRefresh={!('pushState' in window.history)}>
                <TrackerWithRoadblock>{routes}</TrackerWithRoadblock>
            </Router>
        </Provider>
    );
}

export const NOT_LOGGED_IN = 'not_logged_in';

export const gotWindowAuth = dx.authModel.fetch().then(() => {
    window.dxAuth = dx.authModel.toJSON();
    if (typeof window.dxAuth.authenticated !== 'boolean') {
        throw new Error('Ukjent svar fra dxweb auth');
    } else if (window.dxAuth.authenticated === false) {
        window.location = `${process.env.REACT_APP_BACKEND_URL || ''}/login?url=${window.location.href}`;
        throw NOT_LOGGED_IN;
    }

    if (dx.utils.isMobile()) {
        $(document.body).addClass('is-mobile');
    }
    setupTracking();
    store.dispatch(setInitialAuthData(window.dxAuth));
    return window.dxAuth;
});

const setupTracking = () => {
    // Check if the user has another time zone and trigger a tracked event. We'll
    // use this event to display an intercom warning about being in a different
    // timezone.
    const context = dx.authModel.get('lastContext');
    tracking.identify(window.dxAuth);
    tracking.page();
    tracking.track('DxWebFrontEndVersion', { release_name: process.env.REACT_APP_SENTRY_RELEASE_NAME });
    if (context && context.partner && context.partner.timezoneOffset) {
        const partnerOffset = -(context.partner.timezoneOffset / 60);
        const currentOffset = new Date().getTimezoneOffset();

        if (partnerOffset !== currentOffset) {
            tracking.track('non-norwegian-timezone-offset', {
                currentOffset,
                partnerOffset,
            });
        }
    }
};
