import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';

import IntercomLauncher from '../../components/IntercomLauncher';
import dx from '../../../global/dx';
import { setTogglerVisibility } from '../../actions/sidebarActions';
import MainViewHeaderMenu from '../../../modules/culture/components/MainViewHeaderMenu';

/**
 * The new header component! Will render a white version with a green back
 * button if the `onBackButtonClick` prop is passed.
 */
class ActiveHeader extends React.Component {
    static propTypes = {
        /** @type {string} The main title for the header */
        title: PropTypes.string.isRequired,
        /** @type {string} The subtitle for the header, displayed smaller and over the main title */
        subtitle: PropTypes.string,
        /** @type {Function} The callback for the back button. If present, the back button will be rendered */
        onBackButtonClick: PropTypes.func,
        inactive: PropTypes.bool,
        /** @type {Function} Callback for toggle sidebar */
        toggleSidebarToggler: PropTypes.func,
        children: PropTypes.node,
        searchHasFocus: PropTypes.bool,
        screen: PropTypes.shape({
            isMobile: PropTypes.bool,
        }).isRequired,
        /** @type {array} The list of partners */
        partners: PropTypes.array.isRequired,
        /** @type {Object} Logged in user */
        user: PropTypes.shape({
            sudo: PropTypes.bool,
        }).isRequired,
        /** @type {object} The current logged in user context partner */
        partner: PropTypes.shape({
            id: PropTypes.number,
        }).isRequired,
    };

    static defaultProps = {
        subtitle: '',
        onBackButtonClick: undefined,
        inactive: false,
    };

    componentDidMount() {
        this.dispatchSidebarTogglerAction(this.props);
    }

    componentWillUnmount() {
        // in exactly ONE case, we are rendering the generic, green version of the header
        // underneath the single entity header, so when we unmount, always toggle the
        // sidebar toggle button on, so that the underlying header will render it
        this.props.toggleSidebarToggler(true);
    }

    UNSAFE_componentWillUpdate(nextProps) {
        this.dispatchSidebarTogglerAction(nextProps);
    }

    dispatchSidebarTogglerAction(nextProps) {
        if ((nextProps.onBackButtonClick !== undefined || nextProps.searchHasFocus) && dx.utils.isMobile()) {
            nextProps.toggleSidebarToggler(false);
        } else {
            nextProps.toggleSidebarToggler(true);
        }
    }

    handleBackButtonClick = () => {
        this.props.onBackButtonClick();
    };

    render() {
        const dxUserPartnerId = 1;
        const isProduction = process.env.NODE_ENV === 'production';
        const isSudo = this.props.user.sudo;
        const isNotATestPartner = this.props.partner.id >= 100;
        const isDxUser = this.props.partners.filter(partner => partner.partnerID === dxUserPartnerId).length > 0;
        const hasDxUserAsPartner = isProduction && isNotATestPartner && (isSudo || isDxUser);

        const classes = classnames({
            'active-header': true,
            'with-back-button': this.props.onBackButtonClick !== undefined,
            'search-has-focus': this.props.searchHasFocus,
            inactive: this.props.inactive,
            'has-dx-user-as-partner': hasDxUserAsPartner,
        });
        // AWFUL! This is only because the header sometimes has a main menu (i.e for the calendar),
        // and in those cases we should hide the title, but if the header me)nu isn't present, we
        // should display the title in all its glory
        const children = React.Children.toArray(this.props.children);
        const hasMainMenu = children && children.some(child => child.type === MainViewHeaderMenu);
        const hasBackButton = this.props.onBackButtonClick !== undefined;
        const shouldDisplayTitle = !this.props.screen.isMobile || !hasMainMenu || hasBackButton;

        return (
            <div className={classes}>
                {this.props.onBackButtonClick && (
                    <div className="back-button" role="button" onClick={this.handleBackButtonClick}>
                        <i className="fa fa-angle-left" />
                    </div>
                )}
                <div className="active-header-content">
                    {shouldDisplayTitle && (
                        <div className="active-header-title-container">
                            {this.props.subtitle && <p>{this.props.subtitle}</p>}
                            <h3 className="active-header-title">{this.props.title}</h3>
                        </div>
                    )}
                    {this.props.children}
                    <IntercomLauncher />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { searchHasFocus } = state.ui.sidebar;
    const { screen } = state.ui;
    const { partners, user } = state.auth;
    const { partner } = state.auth.context;
    return {
        searchHasFocus,
        screen,
        partners,
        user,
        partner,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        toggleSidebarToggler: value => {
            dispatch(setTogglerVisibility(value));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveHeader);
