import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class Button extends Component {
    static propTypes = {
        text: PropTypes.string,
        icon: PropTypes.string,
        onClick: PropTypes.func,
        data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        large: PropTypes.bool,
        shrink: PropTypes.bool,
        disabled: PropTypes.bool,
        type: PropTypes.oneOf(['primary', 'secondary', 'delete']),
        tooltip: PropTypes.string,
    };

    static defaultProps = {
        text: 'Click me',
        icon: '', //font-awesome icon or similar
        onClick: () => {},
        disabled: false,
        type: 'primary',
    };

    onClick = e => {
        this.props.onClick(e);
    };

    render() {
        const classes = classNames({
            'dx-button': true,
            ['dx-button--' + this.props.type]: true,
        });
        return (
            <button
                className={classes}
                type="button"
                id={this.props.id}
                onClick={this.onClick}
                disabled={this.props.disabled}
                title={this.props.tooltip}
            >
                {Boolean(this.props.icon) && <i className={this.props.icon} />}
                {this.props.text}
            </button>
        );
    }
}
