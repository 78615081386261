import { connect } from 'react-redux';
import React from 'react';
import PrimaryButton from '../global/components/ui/buttons/PrimaryButton';
import PropTypes from 'prop-types';
import { t } from '../global/locale';

class ServiceWorkerUpdate extends React.Component {
    activateNewSwWorker = () => this.props.registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
    render() {
        return (
            <React.Fragment>
                {this.props.registrationWaiting && (
                    <Notification
                        header={<h4 style={{ fontWeight: 600 }}>Ny oppdatering!</h4>}
                        text={<p>{t('DxWeb har en oppdatering som venter')}</p>}
                        buttonText={t('Oppdater')}
                        onClick={this.activateNewSwWorker}
                        remindAgainInMs={3600000}
                        remindMeLaterText={t('Utsett')}
                    />
                )}
                {this.props.serviceWorkerFirstTimeInstallation && (
                    <Notification text={t('DxWeb er lagret')} noticeLengthMs={4000} />
                )}
            </React.Fragment>
        );
    }
}
export default connect(state => ({ ...state.serviceWorkerStatus }))(ServiceWorkerUpdate);

class Notification extends React.Component {
    static propTypes = {
        header: PropTypes.node,
        text: PropTypes.node,
        buttonText: PropTypes.string,
        onClick: PropTypes.func,
        noticeLengthMs: PropTypes.number,
        remindAgainInMs: PropTypes.number,
        remindMeLaterText: PropTypes.string,
    };
    state = { visible: true };
    componentDidMount() {
        if (this.props.noticeLengthMs) {
            setTimeout(() => this.setState({ visible: false }), this.props.noticeLengthMs);
        }
    }
    onRemindLater = () => {
        this.setState({ visible: false });
        if (this.props.remindAgainInMs) {
            setTimeout(() => this.setState({ visible: true }), this.props.remindAgainInMs);
        }
    };
    render() {
        if (!this.state.visible) {
            return null;
        }
        return (
            <div className="fx-notification-container">
                {this.props.header}
                {this.props.text}
                {this.props.onClick && <PrimaryButton text={this.props.buttonText} onClick={this.props.onClick} />}
                <span className="fx-notification-remind-me-later" role="button" onClick={this.onRemindLater}>
                    {this.props.remindMeLaterText}
                </span>
            </div>
        );
    }
}
