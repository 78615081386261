import { t } from '../locale';
import moment from 'moment';

export const SO_FAR_THIS_YEAR = 'soFarThisYear';
export const SO_LANGT_I_AR = 'Så langt i år';

export const LAST_THIRTY_DAYS = 'lastThirtyDays';
export const SIST_30_DAGER = 'Siste 30 dager';

export const LAST_SEVEN_DAYS = 'lastSevenDays';
export const SIST_7_DAGER = 'Siste 7 dager';

export const PERIOD = 'period';

export const WEEK = 'week';
export const MONTH = 'month';
export const YEAR = 'year';

export const getPeriodTypeFromDates = (from, to) => {
    if (
        moment(from).isSame(
            moment()
                .date(1)
                .month(0)
                .startOf('day')
        ) &&
        moment(to).isSame(moment().startOf('day'))
    ) {
        return {
            id: SO_FAR_THIS_YEAR,
            value: t(SO_LANGT_I_AR),
        };
    }
    if (
        moment(from).isSame(
            moment()
                .subtract(30, 'days')
                .startOf('day')
        ) &&
        moment(to).isSame(moment().startOf('day'))
    ) {
        return {
            id: LAST_THIRTY_DAYS,
            value: t(SIST_30_DAGER),
        };
    }
    if (
        moment(from).isSame(
            moment()
                .subtract(7, 'days')
                .startOf('day')
        ) &&
        moment(to).isSame(moment().startOf('day'))
    ) {
        return {
            id: LAST_SEVEN_DAYS,
            value: t(SIST_7_DAGER),
        };
    } else {
        return {
            id: PERIOD,
            value: t(PERIOD),
        };
    }
};

export const getPeriodValueFromPeriodType = period => {
    switch (period) {
        case LAST_SEVEN_DAYS:
            return { id: LAST_SEVEN_DAYS, value: t(SIST_7_DAGER) };
        case SO_FAR_THIS_YEAR:
            return { id: SO_FAR_THIS_YEAR, value: t(SO_LANGT_I_AR) };
        default:
            return { id: LAST_THIRTY_DAYS, value: t(SIST_30_DAGER) };
    }
};

export const getPeriod = period => {
    switch (period.key) {
        case YEAR:
            return {
                id: YEAR,
                value: moment(period.from).format('YYYY'),
                type: YEAR,
            };
        case MONTH:
            return {
                id: MONTH,
                value: moment(period.from).format('MMMM') + ' ' + moment(period.from).format('YYYY'),
                type: MONTH,
            };
        case WEEK:
            return {
                id: WEEK,
                value: moment(period.from).format('DD.MM.YYYY') + '-' + moment(period.to).format('DD.MM.YYYY'),
                type: WEEK,
            };
        default:
            return {
                id: PERIOD,
                value: moment(period.from).format('DD.MM.YYYY') + '-' + moment(period.to).format('DD.MM.YYYY'),
                type: PERIOD,
            };
    }
};

export const getDate = key => {
    switch (key) {
        case SO_FAR_THIS_YEAR:
            return {
                from: moment()
                    .date(1)
                    .month(0)
                    .startOf('day')
                    .valueOf(),
                to: moment()
                    .startOf('day')
                    .valueOf(),
            };
        case LAST_THIRTY_DAYS:
            return {
                from: moment()
                    .subtract(30, 'days')
                    .startOf('day')
                    .valueOf(),
                to: moment()
                    .startOf('day')
                    .valueOf(),
            };
        case LAST_SEVEN_DAYS:
            return {
                from: moment()
                    .subtract(7, 'days')
                    .startOf('day')
                    .valueOf(),
                to: moment()
                    .startOf('day')
                    .valueOf(),
            };
        default:
            return {
                from: moment()
                    .subtract(30, 'days')
                    .startOf('day')
                    .valueOf(),
                to: moment()
                    .startOf('day')
                    .valueOf(),
            };
    }
};

export const getPeriodLabel = selectedItem => {
    if (!selectedItem) return null;
    switch (selectedItem.key) {
        case YEAR:
            return moment(selectedItem.from).format('YYYY');
        case MONTH:
            return moment(selectedItem.from).format('MMMM YYYY');
        case WEEK:
            return `${moment(selectedItem.from).format('DD.MM.YY')} - ${moment(selectedItem.to).format('DD.MM.YY')}`;
        case SO_FAR_THIS_YEAR:
            return t(SO_LANGT_I_AR);
        case LAST_THIRTY_DAYS:
            return t(SIST_30_DAGER);
        case LAST_SEVEN_DAYS:
            return t(SIST_7_DAGER);
        case PERIOD:
            if (
                moment(selectedItem.from).isSame(
                    moment()
                        .date(1)
                        .month(0)
                        .startOf('day')
                ) &&
                moment(selectedItem.to).isSame(moment().startOf('day'))
            ) {
                return t(SO_LANGT_I_AR);
            }
            if (
                moment(selectedItem.from).isSame(
                    moment()
                        .subtract(30, 'days')
                        .startOf('day')
                ) &&
                moment(selectedItem.to).isSame(moment().startOf('day'))
            ) {
                return t(SIST_30_DAGER);
            }
            if (
                moment(selectedItem.from).isSame(
                    moment()
                        .subtract(7, 'days')
                        .startOf('day')
                ) &&
                moment(selectedItem.to).isSame(moment().startOf('day'))
            ) {
                return t(SIST_7_DAGER);
            } else {
                return `${moment(selectedItem.from).format('DD.MM.YY')} - ${moment(selectedItem.to).format(
                    'DD.MM.YY'
                )}`;
            }
        default:
            return null;
    }
};
