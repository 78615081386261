export const SIDEBAR_TOGGLED = 'SIDEBAR_TOGGLED';
export const TOGGLER_TOGGLED = 'TOGGLER_TOGGLED';

export const SEARCH_INPUT_WAS_FOCUSED = 'SEARCH_INPUT_WAS_FOCUSED';
export const SEARCH_INPUT_WAS_UNFOCUSED = 'SEARCH_INPUT_WAS_UNFOCUSED';

export const toggleSidebar = isExpanded => ({
    type: SIDEBAR_TOGGLED,
    isExpanded,
});

export const setTogglerVisibility = visible => ({
    type: TOGGLER_TOGGLED,
    visible,
});

export const searchFocused = () => ({
    type: SEARCH_INPUT_WAS_FOCUSED,
});

export const searchUnFocused = () => ({
    type: SEARCH_INPUT_WAS_UNFOCUSED,
});
