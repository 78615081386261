import React from 'react';
import DxPopup from '../../components/DxPopup';
import PropTypes from 'prop-types';
import moment from 'moment';
import { t } from '../../locale';
import axios from 'axios';

const AGREEMENTID = 4;
const GDPRROADBLOCKLASTVISITDATE = 'GDPRRoadblockLastVisitDate';

class GDPR extends React.PureComponent {
    state = {
        content: '',
        title: '',
        displayGDPRRoadblock: false,
    };
    static propTypes = {
        /* {func} Update state of road block **/
        updateGDPRDisplayRoadBlock: PropTypes.func.isRequired,
    };

    componentDidMount = () => {
        const shouldDisplayGDPRRoadBlock = this.shouldDisplayGDPRRoadBlock();
        this.setState({ displayGDPRRoadblock: shouldDisplayGDPRRoadBlock }, () => {
            if (shouldDisplayGDPRRoadBlock) {
                const baseUrl = '/api/v1/account/' + AGREEMENTID;
                axios.get(baseUrl).then(response => {
                    this.setState({
                        content: response.data.content,
                        title: response.data.title,
                    });
                });
            }
        });
    };

    isGDPRRoadblockFirstTimeVisit = () => {
        const lastVisit = localStorage.getItem(GDPRROADBLOCKLASTVISITDATE);
        return !lastVisit;
    };

    hasOneDayPassed = () => {
        const GDPRLastVisitDate = new moment(JSON.parse(localStorage.getItem(GDPRROADBLOCKLASTVISITDATE)));
        const todayDate = new moment();
        const duration = moment.duration(todayDate.diff(GDPRLastVisitDate)).asHours();
        return duration > 24;
    };

    isGDPRDeadline = () => moment().isSameOrAfter('2018-09-01');

    shouldDisplayGDPRRoadBlock = () => {
        const dxwebAgreements = window.dxAuth.dxwebAgreements;
        if (dxwebAgreements.length === 0) {
            return false;
        }
        const userApprovedAgreements = window.dxAuth.userApprovedAgreements;

        const result = dxwebAgreements.filter(dxwebAgg => {
            return userApprovedAgreements.some(
                userAppAgg => userAppAgg.id === dxwebAgg.id && userAppAgg.id === AGREEMENTID
            );
        });

        const consentGiven = result.length > 0;
        const isGDPRRoadblockFirstTimeVisit = this.isGDPRRoadblockFirstTimeVisit();
        const hasOneDayPassed = this.hasOneDayPassed();

        const displayGDPRRoadBlock =
            (!consentGiven && isGDPRRoadblockFirstTimeVisit) ||
            (!consentGiven && hasOneDayPassed) ||
            (!consentGiven && this.isGDPRDeadline());

        this.props.updateGDPRDisplayRoadBlock(displayGDPRRoadBlock);
        return displayGDPRRoadBlock;
    };

    handleOnCloseAgreement = () => {
        const date = new moment().valueOf();
        localStorage.setItem(GDPRROADBLOCKLASTVISITDATE, date);
        this.props.updateGDPRDisplayRoadBlock(false);
    };

    handleOnSubmitAgreement = () => {
        const date = new moment().valueOf();
        localStorage.setItem(GDPRROADBLOCKLASTVISITDATE, date);
        const baseUrl = '/api/v1/account';
        axios.post(baseUrl, { agreementId: AGREEMENTID });
        this.props.updateGDPRDisplayRoadBlock(false);
    };
    createMarkup = () => {
        return { __html: this.state.content };
    };

    render() {
        return (
            <React.Fragment>
                {this.state.displayGDPRRoadblock && (
                    <DxPopup
                        title={this.state.title}
                        onPopupClose={this.handleOnCloseAgreement}
                        onPopupConfirm={this.handleOnSubmitAgreement}
                        confirmText={t('Jeg forstår')}
                    >
                        <div className="container-fluid">
                            <div>
                                <ul>
                                    <li>
                                        {' '}
                                        <div dangerouslySetInnerHTML={this.createMarkup()} />{' '}
                                    </li>
                                </ul>
                            </div>
                            <br />
                            <div>
                                <small>
                                    Disse vilkårene må godkjennes dersom du vil fortsette å bruke dxweb. Dersom du ikke
                                    godkjenner disse vilkårene, vil du ikke lenger kunne logge inn i dxweb etter
                                    01.09.2018. Frem til da vil vi vise denne dialogen maks én gang i døgnet.
                                </small>
                            </div>
                        </div>
                    </DxPopup>
                )}
            </React.Fragment>
        );
    }
}

export default GDPR;
