import { referenceMaps } from '../global/utils/backboneUtils';
import React, { useState, useEffect } from 'react';
import DxPopup from '../global/components/DxPopup';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import dx from '../global/dx';
import RenderServerSidePage from './RenderServerSidePage';
import { Provider } from 'react-redux';
import store from '../global/store/createStore';
import ActiveHeader from '../global/components/ActiveHeader';
import LoadingIndicator from '../global/components/LoadingIndicator';
import { t } from '../global/locale';
import ErrorBoundary from '../global/ErrorBoundary';

const getDefaultPartnerRedirect = baseUrl => {
    if (dx.utils.hasRole(['administrator']) && dx.utils.getPartnerId() === 1) {
        return '/dashboard?1';
    } else if (dx.utils.hasModule('dashboard') && dx.utils.hasRole(['kulturhussjef']) && dx.utils.hasSSH()) {
        return `${baseUrl}/dashboard`;
    } else if (dx.utils.hasModule('culture') && dx.utils.hasRole(['kulturhussjef', 'culture_full_read_only'])) {
        return `${baseUrl}/culture`;
    } else {
        return `${baseUrl}/shows`;
    }
};

const AsyncModuleLoading = (Component, title) => () => {
    return (
        <ErrorBoundary title={title}>
            <React.Suspense
                fallback={
                    <Provider store={store}>
                        <ActiveHeader title={title} />
                        <LoadingIndicator message={t('Laster inn modul')} />
                    </Provider>
                }
            >
                <Component />
            </React.Suspense>
        </ErrorBoundary>
    );
};

const AsyncCultureModule = AsyncModuleLoading(
    React.lazy(() => import('../modules/culture/main')),
    t('Arrangementer')
);
const AsyncCrmModule = AsyncModuleLoading(
    React.lazy(() => import('../modules/crm/main')),
    t('Kunder')
);
const AsyncShowsModule = AsyncModuleLoading(
    React.lazy(() => import('../modules/shows/main')),
    t('Arrangementer')
);
const AsyncOrdersModule = AsyncModuleLoading(
    React.lazy(() => import('../modules/orders/main')),
    t('Prosjekt')
);
const AsyncUserAdminModule = AsyncModuleLoading(
    React.lazy(() => import('../modules/useradmin/main')),
    t('Brukere')
);
const AsyncCampaignsModule = AsyncModuleLoading(
    React.lazy(() => import('../modules/campaigns/main')),
    t('Markedsføring')
);
const AsyncProductsModule = AsyncModuleLoading(
    React.lazy(() => import('../modules/products/main')),
    t('Produkter')
);
const AsyncFilmSchedulerModule = AsyncModuleLoading(
    React.lazy(() => import('../modules/filmscheduler/main')),
    t('Filmprogrammering')
);
const AsyncRentersModule = AsyncModuleLoading(
    React.lazy(() => import('../modules/renters/main')),
    t('Leietakere')
);
const AsyncMediaBaseModule = AsyncModuleLoading(
    React.lazy(() => import('../modules/mediabase/main')),
    t('Mediabase')
);
const AsyncPartnerAdminModule = AsyncModuleLoading(
    React.lazy(() => import('../modules/partneradmin/main')),
    t('Partnere')
);
const AsyncReportsModule = AsyncModuleLoading(
    React.lazy(() => import('../modules/reports/main')),
    t('Rapporter')
);
const AsyncPartnerProfileModule = AsyncModuleLoading(
    React.lazy(() => import('../modules/partnerprofile/main')),
    t('Innstillinger')
);
const AsyncReportsAdminModule = AsyncModuleLoading(
    React.lazy(() => import('../modules/reportsadmin/main')),
    t('Rapportadmin')
);

const RenderModule = () => {
    const [prevModule, setPrevModule] = useState(null);
    const { path, url } = useRouteMatch();
    const matchModule = useRouteMatch({
        path: `${path}/:module`,
        strict: false,
        sensitive: false,
    });
    useEffect(() => {
        if (matchModule && matchModule.params && matchModule.params.module !== prevModule) {
            referenceMaps.clearAllRefMaps();
            setPrevModule(matchModule.params.module);
        }
    });
    return (
        <Switch>
            <Route path={`${path}/default`}>
                <Redirect to={getDefaultPartnerRedirect(url)} />
            </Route>
            <Route path={`${path}/dashboard`}>
                <RenderServerSidePage url={`${url}/dashboardhtml`} title="Dashboard" />
            </Route>
            <Route path={`${path}/culture`}>
                <AsyncCultureModule />
            </Route>
            <Route path={`${path}/crm`}>
                <AsyncCrmModule />
            </Route>
            <Route path={`${path}/renters/:renterId/shows`}>
                <AsyncShowsModule />;
            </Route>
            <Route path={`${path}/shows`}>
                <AsyncShowsModule />
            </Route>
            <Route path={`${path}/orders`}>
                <AsyncOrdersModule />
            </Route>
            <Route path={`${path}/useradmin`}>
                <AsyncUserAdminModule />
            </Route>
            <Route path={`${path}/campaigns`}>
                <AsyncCampaignsModule />
            </Route>
            <Route path={`${path}/products`}>
                <AsyncProductsModule />
            </Route>
            <Route path={`${path}/filmscheduler`}>
                <AsyncFilmSchedulerModule />
            </Route>
            <Route path={`${path}/renteradmin`}>
                <AsyncRentersModule />
            </Route>
            <Route path={`${path}/mediabase`}>
                <AsyncMediaBaseModule />
            </Route>
            <Route path={`${path}/partneradmin`}>
                <AsyncPartnerAdminModule />
            </Route>
            <Route path={`${path}/report`}>
                <AsyncReportsModule />
            </Route>
            <Route path={`${path}/partnerprofile`}>
                <AsyncPartnerProfileModule />
            </Route>
            <Route path={`${path}/reportsadmin`}>
                <AsyncReportsAdminModule />
            </Route>
            <Route>
                <h1>{t('Ukjent adresse (RenderModule)')}</h1>
            </Route>
        </Switch>
    );
};
export default RenderModule;
