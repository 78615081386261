import {
    LIST_PRICE_SHOW_CREATE_POPUP,
    LIST_PRICE_SHOW_EDIT_POPUP,
    LIST_PRICE_HIDE_ALL_POPUPS,
} from '../actions/listPriceCategoryDefinitions';
import {
    OPTIMISTIC_CREATE_PRICE_CATEGORY,
    OPTIMISTIC_UPDATE_PRICE_CATEGORY,
    CREATE_PRICE_CATEGORY_FAILED,
    CREATE_PRICE_CATEGORY_SUCCESS,
} from '../../../global/actions/priceCategory';
const initialState = {
    showEditPopup: false,
    showCreatePopup: false,
    cellValueStateBuster: 1,
    lastCreatedId: null,
};

const listPriceCategoryDefinitions = (state = initialState, action) => {
    switch (action.type) {
        case LIST_PRICE_SHOW_CREATE_POPUP:
            return {
                ...state,
                showCreatePopup: true,
            };
        case LIST_PRICE_SHOW_EDIT_POPUP:
            return {
                ...state,
                showEditPopup: action.id,
            };
        case OPTIMISTIC_CREATE_PRICE_CATEGORY:
            return {
                ...state,
                showCreatePopup: false,
                showEditPopup: false,
                cellValueStateBuster: state.cellValueStateBuster + 1,
                lastCreatedId: action.attributes.id,
            };
        case OPTIMISTIC_UPDATE_PRICE_CATEGORY:
        case LIST_PRICE_HIDE_ALL_POPUPS:
            return {
                ...state,
                showCreatePopup: false,
                showEditPopup: false,
                cellValueStateBuster: state.cellValueStateBuster + 1,
            };
        case CREATE_PRICE_CATEGORY_SUCCESS:
            return {
                ...state,
                lastCreatedId: action.response.id,
            };
        case CREATE_PRICE_CATEGORY_FAILED:
            return {
                lastCreatedId: null,
            };
        default:
            return state;
    }
};

export default listPriceCategoryDefinitions;
