import React from 'react';
import Sidebar from '../global/components/Sidebar';
import { BrowserRouter as Router, Switch, Route, withRouter } from 'react-router-dom';
import dx from '../global/dx';
import LoadingIndicator from '../global/components/LoadingIndicator';
import { Provider } from 'react-redux';
import store from '../global/store/createStore';
import { t } from '../global/locale';
import { gotWindowAuth, NOT_LOGGED_IN } from '../global/init';
import RenderHome from './Home';
import RenderServerSidePage from './RenderServerSidePage';
import ServiceWorkerUpdate from './ServiceWorkerUpdate';
import AutoSudoComponent from './AutoSudoComponent';
import RenderModule from './RenderModule';
import ErrorView from '../global/ErrorView';

export const InterModuleNavigatorContext = React.createContext();

const nav = history => path => {
    history.push(path);
};

const InterModuleNavigator = withRouter(props => {
    return (
        <InterModuleNavigatorContext.Provider
            value={{
                navigate: nav(props.history),
            }}
        >
            {props.children}
        </InterModuleNavigatorContext.Provider>
    );
});

class App extends React.Component {
    state = { waitingForAuth: true, error: null };
    componentDidMount() {
        gotWindowAuth
            .then(() => this.setState({ waitingForAuth: false }))
            .catch(e => {
                if (e === NOT_LOGGED_IN) {
                    //if user is NOT_LOGGED_IN gotWindowAuth will send them to auth0 login, so well just keep state waitingForAuth true until they arrive at auth0 login
                    return;
                } else {
                    // we cant set waitingForAuth to false here, cause sidebar etc will expect dx.utils to have an authUser, which it does not
                    this.setState({ error: { ...e } });
                }
            });
    }
    renderBody(sudoPrefix) {
        if (this.state.error) {
            return <ErrorView title={t('Feil ved autentisering')} error={this.state.error} />;
        }
        if (this.state.waitingForAuth) {
            return <LoadingIndicator message={t('Autentiserer..')} />;
        }
        return (
            <Switch>
                <Route path={`${sudoPrefix}/partners/:partnerId`}>
                    <RenderModule />
                </Route>
                <Route path="/dashboard">
                    <RenderServerSidePage url="/dxdashboardhtml" title="Dashboard" />
                </Route>
                <Route path="/brukerkonto">
                    <RenderServerSidePage url="/useraccounthtml" title={t('Brukerkonto')} />
                </Route>
                <Route path="/">
                    <RenderHome sudoPrefix={sudoPrefix} />
                </Route>
                <Route>
                    <h1>{t('Ukjent adresse. (app)')}</h1>
                </Route>
            </Switch>
        );
    }
    render() {
        const ninjaId = dx.utils.getNinjaId();
        const sudoPrefix = ninjaId ? `/sudo/${ninjaId}` : '';
        return (
            <Router>
                <InterModuleNavigator>
                    <Switch>
                        <Route path="/autosudo/">
                            <AutoSudoComponent />
                        </Route>
                        <Route path="/">
                            <div className="main" id="main-content">
                                {this.renderBody(sudoPrefix)}
                                <Provider store={store}>
                                    <ServiceWorkerUpdate />
                                </Provider>
                            </div>
                            <Sidebar
                                sudoPrefix={sudoPrefix}
                                partnerId={dx.utils.getPartnerId()}
                                renterId={dx.utils.getRenterId()}
                                waitingForAuth={this.state.waitingForAuth}
                            />
                        </Route>
                    </Switch>
                </InterModuleNavigator>
            </Router>
        );
    }
}

export default App;
