function getLocalStorage(key) {
    try {
        if (window.localStorage) {
            return JSON.parse(window.localStorage.getItem(key));
        }
    } catch (err) {
        // The JSON was probably invalid, so we'll have to trash/ignore the data...
    }
}
function setLocalStorage(key, data) {
    try {
        if (window.localStorage) {
            window.localStorage.setItem(key, JSON.stringify(data));
        }
    } catch (err) {
        // This is probably just Safari in Private Mode whining about
        // QuotaExceeded. It is safe to ignore that error, but it is almost
        // impossible to detect it:
        // http://chrisberkhout.com/blog/localstorage-errors/
    }
}

// -- specific for OrderSettlement.jsx and OrderSalesButton.jsx
const ORDER_SETTLEMENT_STORAGE_KEY = 'OrderSettlement';

const applyUserStoredSettlementReportArgs = reportArgs => {
    let storedReportArgs = getLocalStorage(ORDER_SETTLEMENT_STORAGE_KEY);
    storedReportArgs = Array.isArray(storedReportArgs) ? storedReportArgs : [];
    return reportArgs.map(reportArg => ({
        ...reportArg,
        value: (storedReportArgs.find(storedReportArg => storedReportArg.name === reportArg.name) || reportArg).value,
    }));
};

const patchUserStoredSettlementReportArgs = patchObject => {
    let storedArray = getLocalStorage(ORDER_SETTLEMENT_STORAGE_KEY);
    storedArray = Array.isArray(storedArray) ? storedArray : [];
    const namesToPatch = patchObject.map(a => a.name);
    setLocalStorage(ORDER_SETTLEMENT_STORAGE_KEY, [
        ...storedArray.filter(a => !namesToPatch.includes(a.name)),
        ...patchObject,
    ]);
};

export { getLocalStorage, setLocalStorage, applyUserStoredSettlementReportArgs, patchUserStoredSettlementReportArgs };
