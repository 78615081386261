export const REQUEST_PRICE_CATEGORIES = 'REQUEST_PRICE_CATEGORIES';
export const RECEIVE_PRICE_CATEGORIES = 'RECEIVE_PRICE_CATEGORIES';

export const OPTIMISTIC_UPDATE_PRICE_CATEGORY = 'OPTIMISTIC_UPDATE_PRICE_CATEGORY';
export const UPDATE_PRICE_CATEGORY_SUCCESS = 'UPDATE_PRICE_CATEGORY_SUCCESS';
export const UPDATE_PRICE_CATEGORY_FAILED = 'UPDATE_PRICE_CATEGORY_FAILED';

export const OPTIMISTIC_CREATE_PRICE_CATEGORY = 'OPTIMISTIC_CREATE_PRICE_CATEGORY';
export const CREATE_PRICE_CATEGORY_SUCCESS = 'CREATE_PRICE_CATEGORY_SUCCESS';
export const CREATE_PRICE_CATEGORY_FAILED = 'CREATE_PRICE_CATEGORY_FAILED';
