import {
    REQUEST_EVENT_TYPE,
    RECEIVE_EVENT_TYPE,
    REQUEST_EVENT_TYPES,
    RECEIVE_EVENT_TYPES,
    OPTIMISTIC_UPDATE_EVENT_TYPE,
    UPDATE_EVENT_TYPE_SUCCESS,
    UPDATE_EVENT_TYPE_FAILED,
    OPTIMISTIC_CREATE_EVENT_TYPE,
    CREATE_EVENT_TYPE_SUCCESS,
    CREATE_EVENT_TYPE_FAILED,
    OPTIMISTIC_DELETE_EVENT_TYPE,
    DELETE_EVENT_TYPE_SUCCESS,
    DELETE_EVENT_TYPE_FAILED,
} from '../actions/eventType';

const initialState = {
    items: [],
    isLoading: false,
};

const eventType = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_EVENT_TYPE:
            return {
                ...state,
                itemsLoading: false,
            };
        case RECEIVE_EVENT_TYPE:
            return {
                ...state,
                itemsLoading: false,
                items: state.items.map(item => {
                    if (action.response.id !== item.id) {
                        return {
                            ...item,
                        };
                    }
                    return action.response;
                }),
            };
        //FETCH all eventType categories
        case REQUEST_EVENT_TYPES:
            return {
                ...state,
                itemsLoading: true,
            };
        case RECEIVE_EVENT_TYPES:
            return {
                ...state,
                items: action.response.data,
                itemsLoading: false,
            };

        //UPDATE single category
        case OPTIMISTIC_UPDATE_EVENT_TYPE: //replace old attributes with new optimistic attributes
            return {
                ...state,
                items: state.items.map(i => {
                    if (action.id !== i.id) {
                        return i;
                    }
                    return {
                        ...i, // has a lot more attributes than what may get sent in attributes
                        ...action.attributes, // so we need to merge (also id)
                    };
                }),
            };
        case UPDATE_EVENT_TYPE_SUCCESS: // replace optimistic attributes with actual response
            return {
                ...state,
                items: state.items.map(i => {
                    if (action.response.id !== i.id) {
                        return i;
                    }
                    return {
                        ...i,
                        ...action.response,
                        // we should be able to only set response here,
                        // but 'i' that has arrived from REQUEST_EVENT_TYPES has some properties that response doesn't have,
                        // so for now I merged them
                    };
                }),
            };
        case UPDATE_EVENT_TYPE_FAILED: // replace optimistic new attributes with old attributes
            return {
                ...state,
                items: state.items.map(i => {
                    if (action.oldAttributes.id !== i.id) {
                        return i;
                    }
                    return {
                        ...action.oldAttributes,
                    };
                }),
            };

        //CREATE single category
        case OPTIMISTIC_CREATE_EVENT_TYPE: // add optimistic new eventType
            return {
                ...state,
                items: [...state.items, action.attributes],
            };
        case CREATE_EVENT_TYPE_SUCCESS: //replace optimistic new eventType with real response
            return {
                ...state,
                items: [...state.items.filter(i => i.id !== action.optimisticId), action.response],
            };
        case CREATE_EVENT_TYPE_FAILED: // remove optimistic new eventType
            return {
                ...state,
                items: state.items.filter(i => i.id !== action.optimisticId),
            };

        //DELETE single type
        case OPTIMISTIC_DELETE_EVENT_TYPE: // optimistically remove eventType from list
            return {
                ...state,
                items: state.items.filter(i => i.id !== action.id),
            };
        case DELETE_EVENT_TYPE_SUCCESS:
            return state; // hmm nothing to do here i think
        case DELETE_EVENT_TYPE_FAILED: {
            // add the previously removed eventType back in list
            const items = state.items.slice();
            items.splice(action.index, 0, action.eventType);
            return {
                // at the index that it was previously
                ...state,
                items,
            };
        }
        default:
            return state;
    }
};

export default eventType;
