import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class IntercomLauncher extends Component {
    static propTypes = {
        /** @type {number} unreadCount - the number of unread messages we should display in the badge */
        unreadCount: PropTypes.number,
        /** @type {boolean} ninjaMode - are we ninja, or are we dancer? */
        ninjaMode: PropTypes.bool,
    };

    static defaultProps = {
        ninjaMode: false,
        unreadCount: 0,
    };

    render() {
        if (this.props.ninjaMode) {
            return null;
        }

        return (
            <div className="intercom-container">
                <a className="btn intercom-button" id="intercom-launcher" onClick={this.handleClick}>
                    <i className="fa fa-comments fa" />

                    {this.props.unreadCount > 0 && <div className="unread-count-badge">{this.props.unreadCount}</div>}
                </a>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { auth } = state;

    // we're only interested in the unread count here (and ninjaMode, later),
    // so we won't pass anything else
    return {
        unreadCount: auth.intercom.unread,
    };
};

export default connect(mapStateToProps)(IntercomLauncher);
