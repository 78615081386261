import React from 'react';
import PropTypes from 'prop-types';

const MenuComponent = ({ children, onClick, active, disabled, ...rest }) => (
    <div
        className={`menu-component${active ? ' active' : ''}${disabled ? ' disabled' : ''}`}
        role="button"
        onClick={!disabled && onClick}
        {...rest}
    >
        <p>{children}</p>
    </div>
);

MenuComponent.propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func.isRequired,
    active: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default MenuComponent;
