import * as types from '../components/TreeView/actions';

const initialState = {
    activeItems: [{ id: 0, view: '' }],
};

const accordion = (state = initialState, action) => {
    switch (action.type) {
        case types.SAVE_ACTIVE_ITEMS:
            return {
                activeItems: action.payload.items,
            };
        case types.REMOVE_ACTIVE_ITEM:
            return {
                activeItems: state.activeItems.filter(
                    a => a.id !== action.payload.id && a.view !== action.payload.view
                ),
            };
        default:
            return state;
    }
};

export default accordion;
