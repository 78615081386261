export const REQUEST_EVENT_TYPE = 'REQUEST_EVENT_TYPE';
export const RECEIVE_EVENT_TYPE = 'RECEIVE_EVENT_TYPE';

export const REQUEST_EVENT_TYPES = 'REQUEST_EVENT_TYPES';
export const RECEIVE_EVENT_TYPES = 'RECEIVE_EVENT_TYPES';

export const OPTIMISTIC_UPDATE_EVENT_TYPE = 'OPTIMISTIC_UPDATE_EVENT_TYPE';
export const UPDATE_EVENT_TYPE_SUCCESS = 'UPDATE_EVENT_TYPE_SUCCESS';
export const UPDATE_EVENT_TYPE_FAILED = 'UPDATE_EVENT_TYPE_FAILED';

export const OPTIMISTIC_CREATE_EVENT_TYPE = 'OPTIMISTIC_CREATE_EVENT_TYPE';
export const CREATE_EVENT_TYPE_SUCCESS = 'CREATE_EVENT_TYPE_SUCCESS';
export const CREATE_EVENT_TYPE_FAILED = 'CREATE_EVENT_TYPE_FAILED';

export const OPTIMISTIC_DELETE_EVENT_TYPE = 'OPTIMISTIC_DELETE_EVENT_TYPE';
export const DELETE_EVENT_TYPE_SUCCESS = 'DELETE_EVENT_TYPE_SUCCESS';
export const DELETE_EVENT_TYPE_FAILED = 'DELETE_EVENT_TYPE_FAILED';
