import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import DxButton from './DxButton';
import DeleteButton from './ui/buttons/DeleteButton';
import CancelButton from './ui/buttons/CancelButton';
import ConfirmButton from './ui/buttons/ConfirmButton';
import Modal from 'react-bootstrap/lib/Modal';
import renderModal from '../utils/renderModal';
import SecondaryButton from './ui/buttons/SecondaryButton';

class DxPopup extends React.PureComponent {
    UNSAFE_componentWillMount() {
        // Create a element that we can render to in renderPopup
        this.element = document.createElement('div');
        document.body.appendChild(this.element);
    }

    componentDidMount() {
        // Render the popup itself which is rendered on its own DOM root node
        this.renderPopup();
    }

    componentDidUpdate() {
        // Always rerender the popup
        this.renderPopup();
    }

    // The popup will be rendered in a portal in `renderPopup`
    render() {
        return <div />;
    }

    renderPopup(hide = false) {
        // Need to keep an reference to this.element so the `onExited` function
        // can reference it. Note that if we used `this.element` directly in
        // onExited, that would be set to undefined since React does some
        // cleanup after this component has unmounted.
        const el = this.element;

        ReactDOM.render(
            <div className={this.props.className}>
                <Modal
                    show={!hide}
                    animation={this.props.animation}
                    onHide={this.props.onPopupClose}
                    backdrop={this.props.staticBackdrop ? 'static' : true}
                    keyboard={!this.props.preventEscape}
                    bsSize={this.props.large ? 'large' : null}
                    onExited={() => {
                        // Umount the portal and remove it
                        ReactDOM.unmountComponentAtNode(el);
                        document.body.removeChild(el);
                    }}
                >
                    {this.props.title && (
                        <Modal.Header closeButton>
                            <Modal.Title>{this.props.title}</Modal.Title>
                        </Modal.Header>
                    )}
                    {this.props.children && <div className="modal-body">{this.props.children}</div>}
                    {this.renderFooter()}
                </Modal>
            </div>,
            this.element
        );
    }

    renderConfirmButton = () => (
        <ConfirmButton
            onClick={this.props.onPopupConfirm}
            disabled={this.props.confirmDisabled}
            text={this.props.confirmText}
            tooltip={this.props.confirmTooltip}
        />
    );
    renderCancelButton = () => <CancelButton onClick={this.props.onPopupClose} text={this.props.cancelText} />;
    renderDeleteButton = () => (
        <DeleteButton
            onClick={this.props.onDeleteBtnClicked}
            text={this.props.deleteText}
            disabled={this.props.deleteDisabled}
        />
    );
    renderExtraButton = () => <SecondaryButton {...this.props.extraBtn} />;
    renderFooter() {
        if (!this.props.showCancelBtn && !this.props.showConfirmBtn) return null; // If neither button should be shown
        return (
            <div className="modal-footer">
                {this.props.extraBtn && this.renderExtraButton()}
                {this.props.showCancelBtn && this.renderCancelButton()}
                {this.props.showDeleteBtn && this.renderDeleteButton()}
                {this.props.showConfirmBtn && this.renderConfirmButton()}
            </div>
        );
    }

    componentWillUnmount() {
        // Call renderPopup and set hide to true. The Backbone.Popup will
        // run the close animation and then the portal will be unmounted.
        this.renderPopup(true);
    }
}

// Static methods

// Simple confirm popup with green button. Returns a Promise
DxPopup.confirm = (title, message, confirmText, cancelText = 'Avbryt') =>
    renderModal((resolve, reject) => (
        <DxPopup
            confirmText={confirmText}
            cancelText={cancelText}
            title={title}
            onPopupClose={reject}
            onPopupConfirm={resolve}
        >
            {message}
        </DxPopup>
    ));

/**
 * @param {JSX.Element|string} message
 * @returns Promise<any>
 */
DxPopup.confirmDeletion = (
    message = 'Vil du virkelig slette objektet? Du kan ikke gjøre om på dette senere.',
    confirmText = 'Slett'
) =>
    renderModal((resolve, reject) => (
        <DxPopup
            confirmText={confirmText}
            confirmIcon="fa fa-trash-o"
            confirmColor="red"
            cancelText="Avbryt"
            title="Bekreft sletting"
            onPopupClose={reject}
            onPopupConfirm={resolve}
        >
            {message}
        </DxPopup>
    ));

DxPopup.errorMessage = (
    message = 'En feil oppstod. Vennligst prøv igjen senere.',
    title = 'Feil',
    confirmText = 'Lukk',
    confirmColor = 'red'
) =>
    renderModal((resolve, reject) => (
        <DxPopup
            confirmText={confirmText}
            confirmIcon=""
            confirmColor={confirmColor}
            showCancelBtn={false}
            title={title}
            onPopupClose={reject}
            onPopupConfirm={resolve}
        >
            {message}
        </DxPopup>
    ));

DxPopup.loadingIndicator = (title = 'Laster inn...', callbackHandler) =>
    renderModal((resolve, reject) => {
        if (callbackHandler !== undefined && callbackHandler !== null) {
            callbackHandler.close = resolve;
        }

        return (
            <DxPopup showCancelBtn={false} showConfirmBtn={false} onPopupClose={() => {}} title={title}>
                <div style={{ textAlign: 'center', fontSize: 22, padding: '5px 5px 15px' }}>
                    <i className="fa fa-spinner fa-spin" />
                </div>
            </DxPopup>
        );
    });

DxPopup.CloseButton = ({ onClick }) => (
    <button type="button" className="close" onClick={onClick}>
        <span aria-hidden="true">&times;</span>
    </button>
);

DxPopup.propTypes = {
    title: PropTypes.string, // If title is present, will display header with title
    animation: PropTypes.bool, // Whether or not the modal should animate in

    large: PropTypes.bool,

    onPopupConfirm: PropTypes.func, // When user clicks on confirm button
    onPopupClose: PropTypes.func, // When user closes popup using esc or x-icon or the cancel button
    onDeleteBtnClicked: PropTypes.func, // When user clicks the delete price category button

    confirmColor: PropTypes.string,
    confirmText: PropTypes.string,
    confirmIcon: PropTypes.string,
    confirmDisabled: PropTypes.bool,
    confirmTooltip: PropTypes.string,

    deleteText: PropTypes.string,
    deleteDisabled: PropTypes.bool,

    cancelColor: PropTypes.string,

    showCancelBtn: PropTypes.bool,
    showConfirmBtn: PropTypes.bool,
    showDeleteBtn: PropTypes.bool,

    className: PropTypes.string,
    children: PropTypes.any,

    staticBackdrop: PropTypes.bool, // Backdrop is always included, but if true, clicking on backdrop will NOT close popup. Default: false
    preventEscape: PropTypes.bool, // Prevents esc from closing the popup. Default: false

    extraBtn: PropTypes.object,
};

DxPopup.defaultProps = {
    title: null,
    animation: true,

    onPopupConfirm: e => {},
    onPopupClose: e => {},
    onDeleteBtnClicked: e => {},

    confirmColor: 'green',
    confirmText: 'OK',
    confirmIcon: 'fa fa-check',

    cancelColor: 'gray',

    showCancelBtn: true,
    showConfirmBtn: true,
    showDeleteBtn: false,

    className: '',

    staticBackdrop: false,
    preventEscape: false,

    extraBtn: null,
};

export default DxPopup;
