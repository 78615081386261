import config from '../config/dxconfig';
import utils from './dxutils';
import CommandRegistry from './utils/CommandRegistry';
import KeymapResolver from './utils/KeymapResolver';
import AuthModel from './models/AuthModel';

// This object can be used for additional singletons if needed.

let commandRegistry = new CommandRegistry();

let authModel = new AuthModel(window.dxAuth);

let dx = {
    config: config,
    utils: utils(authModel),

    commands: commandRegistry,
    keymapResolver: new KeymapResolver(commandRegistry),
    authModel: authModel,
};

export default dx;
