import 'react-app-polyfill/stable';
import * as serviceWorker from './serviceWorker';

import React from 'react';
import ReactDOM from 'react-dom';
import './less/app.less';
import App from './js/SpaTopLevel/App';
import store from './js/global/store/createStore';
import { SW_UPDATED, SW_INIT, SW_WAITING } from './js/global/reducers/serviceWorkerStatus';
import ErrorBoundary from './js/global/ErrorBoundary';

const reloadWhenActivated = e => {
    if (e.target.state === 'activated') {
        window.location.reload();
    }
};

let intervalId;
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
    onSuccess: () => store.dispatch({ type: SW_INIT }),
    onUpdate: registration => {
        registration.waiting.addEventListener('statechange', reloadWhenActivated);
        store.dispatch({ type: SW_UPDATED, payload: registration.waiting });
    },
    onWaiting: registration => {
        registration.waiting.addEventListener('statechange', reloadWhenActivated);
        store.dispatch({ type: SW_WAITING, payload: registration.waiting });
    },
    onRegistered: serviceWorkerRegistration => {
        clearInterval(intervalId);
        intervalId = setInterval(() => {
            serviceWorkerRegistration && serviceWorkerRegistration.update && serviceWorkerRegistration.update();
            // 60 minutes (* 60 seconds in a minute * 1000 ms in a second)
        }, 30 * 60 * 1000);
    },
});

ReactDOM.render(
    <ErrorBoundary title="DxWeb">
        <App />
    </ErrorBoundary>,
    document.getElementById('root')
);
