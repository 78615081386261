import React from 'react';
import PropTypes from 'prop-types';

/*
 *	General DX styled button
 */

export default class DxButton extends React.PureComponent {
    static propTypes = {
        text: PropTypes.string,
        icon: PropTypes.string,
        color: PropTypes.string,
        onClick: PropTypes.func,
        classes: PropTypes.string,
        data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        style: PropTypes.object,
        large: PropTypes.bool,
        shrink: PropTypes.bool,
        disabled: PropTypes.bool,
    };

    static defaultProps = {
        text: 'Click me',
        icon: 'fa fa-plus', //font-awesome icon or similar
        color: 'blue', // gray, green, blue, red, orange
        onClick: e => {},
        classes: null, // Extra class specifications
        data: null, // Extra info for data-attribute
        id: null,
        style: {}, // Additional styling info in object form, ex: {'float': 'right'}
        large: false, // Giant button
        shrink: false, // Makes the button shrink when small size screen
        disabled: false,
    };

    colorClasses = {
        blue: 'btn-primary',
        green: 'btn-success',
        red: 'btn-danger',
        orange: 'btn-warning',
        gray: 'btn-default',
    };

    render() {
        let classes = 'btn dx-btn ' + this.colorClasses[this.props.color];
        if (this.props.large) classes += ' dx-btn-huge';
        if (this.props.classes) classes += ' ' + this.props.classes;
        if (this.props.shrink) classes += ' shrink-btn';
        if (this.props.mobileFullWidth) classes += ' mobile-full-width';

        return (
            <button
                className={classes}
                type="button"
                id={this.props.id}
                data-attribute={this.props.data}
                style={this.props.style}
                onClick={this.onClick}
                disabled={this.props.disabled}
            >
                {Boolean(this.props.icon) && <i className={this.props.icon} />}
                <h3>{this.props.text}</h3>
            </button>
        );
    }

    onClick = e => {
        this.props.onClick(e);
    };
}
