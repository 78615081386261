import {
    REQUEST_PRICE_CATEGORIES,
    RECEIVE_PRICE_CATEGORIES,
    OPTIMISTIC_UPDATE_PRICE_CATEGORY,
    UPDATE_PRICE_CATEGORY_SUCCESS,
    UPDATE_PRICE_CATEGORY_FAILED,
    OPTIMISTIC_CREATE_PRICE_CATEGORY,
    CREATE_PRICE_CATEGORY_SUCCESS,
    CREATE_PRICE_CATEGORY_FAILED,
} from '../actions/priceCategory';

const initialState = {
    items: [],
    isLoading: false,
};

const priceCategory = (state = initialState, action) => {
    switch (action.type) {
        //FETCH all price categories
        case REQUEST_PRICE_CATEGORIES:
            return {
                ...state,
                isLoading: true,
            };
        case RECEIVE_PRICE_CATEGORIES:
            return {
                ...state,
                items: action.response.data.filter(priceCategory => !priceCategory.deleted),
                isLoading: false,
            };
        //UPDATE single category
        case OPTIMISTIC_UPDATE_PRICE_CATEGORY: //replace old attributes with new optimistic attributes
            return {
                ...state,
                items: state.items.map(i => {
                    if (action.id !== i.id) {
                        return i;
                    }
                    return {
                        ...i, // has a lot more attributes than what may get sent in attributes
                        ...action.attributes, // so we need to merge (also id)
                    };
                }),
            };
        case UPDATE_PRICE_CATEGORY_SUCCESS: // replace optimistic attributes with actual response
            return {
                ...state,
                items: state.items
                    .filter(priceCategory => !priceCategory.deleted)
                    .map(i => {
                        if (action.response.id !== i.id) {
                            return i;
                        }
                        return {
                            ...i, // <== I don't know why this for instance can have cinemaPopularityIndex
                            ...action.response, // <== while this doesn't have
                            // we should be able to only set response here,
                            // but 'i' that has arrived from REQUEST_PRICE_CATEGORIES has some properties that response doesn't have, (ex. cinemaPopularityIndex)
                            // so for now I merged them
                        };
                    }),
            };
        case UPDATE_PRICE_CATEGORY_FAILED: // replace optimistic new attributes with old attributes
            return {
                ...state,
                items: state.items.map(i => {
                    if (action.oldAttributes.id !== i.id) {
                        return i;
                    }
                    return {
                        ...action.oldAttributes,
                    };
                }),
            };
        //CREATE single category
        case OPTIMISTIC_CREATE_PRICE_CATEGORY: // add optimistic new priceCategory
            return {
                ...state,
                items: [...state.items, action.attributes],
            };
        case CREATE_PRICE_CATEGORY_SUCCESS: //replace optimistic new priceCategory with real response
            return {
                ...state,
                items: [...state.items.filter(i => i.id !== action.optimisticId), action.response],
            };
        case CREATE_PRICE_CATEGORY_FAILED: // remove optimistic new priceCategory
            return {
                ...state,
                items: state.items.filter(i => i.id !== action.optimisticId),
            };
        default:
            return state;
    }
};

export default priceCategory;
