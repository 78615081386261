import React from 'react';
import PropTypes from 'prop-types';
import dx from '../global/dx';
import { Provider } from 'react-redux';
import store from '../global/store/createStore';
import ActiveHeader from '../global/components/ActiveHeader';
import { t } from '../global/locale';

export default class RenderHome extends React.Component {
    static propTypes = { sudoPrefix: PropTypes.string.isRequired };
    getDefaultLink(partner) {
        const baseUrl = `${this.props.sudoPrefix}/partners/${partner.partnerID}`;
        if (partner.renterID) {
            return `${baseUrl}/renters/${partner.renterID}/shows`;
        }
        return `${baseUrl}/default`;
    }
    renderTitle(partner) {
        if (partner.renterID) {
            return (
                <span>
                    {partner.partnerName}
                    <small> @ {partner.renterName}</small>
                </span>
            );
        }
        return <span>{partner.partnerName}</span>;
    }
    render() {
        const authUser = dx.utils.getAuthUser();
        const availablePartners = authUser.partners;
        if (availablePartners.length === 1) {
            window.location.replace(this.getDefaultLink(availablePartners[0]));
        }
        return (
            <div>
                <Provider store={store}>
                    <ActiveHeader title={t('Hjem')} />
                </Provider>
                <div className="container base-container-mobile">
                    <br />
                    <br />
                    <div className="row">
                        <div className="col-sm-8">
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    <h3 className="panel-title">{t('Dine organisasjoner')}</h3>
                                </div>
                                {availablePartners
                                    .map(p => ({
                                        link: this.getDefaultLink(p),
                                        title: this.renderTitle(p),
                                    }))
                                    //filter so only unique links-title combination will be rendered
                                    //so if a user has culture_full_read_only and kulturhussjef on a partner, it will only show 1 link
                                    //but if you have renter-role and culture_full_read_only you will have two
                                    .filter(
                                        (p, i, a) =>
                                            !a.slice(0, i).some(ip => ip.link === p.link && ip.title === ip.title)
                                    )
                                    .map((p, i) => {
                                        return (
                                            <a key={i} className="list-group-item" href={p.link}>
                                                {p.title}
                                            </a>
                                        );
                                    })}
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    <h3 className="panel-title">{t('Logget inn som')}</h3>
                                </div>
                                <div className="panel-body">{authUser.auth.displayName}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
