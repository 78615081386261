/**
 * We need to be able to import the store at various locations.
 *
 * NEVER EVER IMPORT THE STORE DIRECTLY INSIDE REACT COMPONENTS
 */

import configureStore from './configureStore';

const store = configureStore();

export default store;
