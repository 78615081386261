import { FETCH_PARTNERS_FAILED, FETCH_PARTNERS_PENDING, FETCH_PARTNERS_SUCCESS } from '../actions/partners';

const initialState = {
    items: {},
    notAsked: true,
    isPending: false,
    error: null,
};

const partners = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PARTNERS_PENDING:
            return {
                ...state,
                isPending: true,
                error: null,
            };
        case FETCH_PARTNERS_FAILED:
            return {
                ...state,
                isPending: false,
                notAsked: false,
                error: action.payload,
            };
        case FETCH_PARTNERS_SUCCESS:
            return {
                ...state,
                items: action.payload.items,
                notAsked: false,
                isPending: false,
                error: null,
            };
    }

    return state;
};

export default partners;
