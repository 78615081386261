import { combineReducers } from 'redux';

import eventType from './eventType';
import nknCategory from './nknCategory';
import mediabasePartnerLanguage from './mediabasePartnerLanguage';
import listEventTypes from '../../modules/partnerprofile/reducers/listEventTypes';

import priceCategory from './priceCategory';
import listPriceCategoryDefinitions from '../../modules/partnerprofile/reducers/listPriceCategoryDefinitions';
import auth from './auth';
import ui from './ui';

import seatmap, {
    pricezoneTemplateReducer,
} from '../../modules/partnerprofile/components/EditLocationView/Pricezones/duck';
import apiReducers from '../api/reducers';
import mailChimpSettings from '../../modules/partnerprofile/reducers/mailChimpSettings';
import integrations from './integrations';
import reports from './reports/index';
import accordion from './accordion';
import touchGroups from '../../modules/products/reducers/touchGroups';
import period from '../../modules/products/reducers/period';
import productStatusType from '../../modules/products/reducers/productStatusType';
import menuStatusType from '../../modules/products/reducers/menuStatusType';
import modeReports from './modeReports';
import partners from './partners';
import { serviceWorkerStatus } from './serviceWorkerStatus';

export default combineReducers({
    eventType,
    nknCategory,
    mediabasePartnerLanguages: mediabasePartnerLanguage,
    listEventTypes,
    priceCategory,
    listPriceCategoryDefinitions,
    auth,
    ui,
    seatmap,
    pricezoneTemplates: pricezoneTemplateReducer,
    api: combineReducers(apiReducers),
    mailChimpSettings,
    integrations,
    reports,
    accordion,
    touchGroups,
    period,
    productStatusType,
    menuStatusType,
    modeReports,
    partners,
    serviceWorkerStatus,
});
