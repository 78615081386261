import { configureApiEntity, baseUrl, PATCH_ENTITIES } from '../adapter/index';
import { schema } from 'normalizr';
import { resourceSchema } from './resources';
import axios from 'axios';
import { normalize } from 'normalizr';

export const resourceGroupSchema = new schema.Entity(
    'resourceGroups',
    {
        resources: [resourceSchema],
    },
    {
        processStrategy: entity => {
            const ent = { ...entity };
            if (!ent.resources) {
                ent.resources = [];
            }
            return ent;
        },
    }
);

export const resourceGroups = configureApiEntity('RESOURCEGROUPS', 'resourcegroups', resourceGroupSchema, {
    moveResources: (fromGroupId, toGroupId) => (dispatch, getState) => {
        const url = baseUrl + `resourcegroups/${fromGroupId}/moveresources`;
        const promise = axios.post(url, {
            toGroupId,
        });
        //if promise rejects, we don't need to do anything, because we don't patch this optimistically
        //in ResourceGroupDeleteOptionsPopup.jsx we do dispatch(moveResources).then(dispatch(deleteGroup)),
        //so we will anyways have to wait for moveResources to resolve promise, before we dispatch deleteResourceGroup
        //(so we know the moving of resources was successful)
        promise.then(response =>
            dispatch({
                type: PATCH_ENTITIES,
                payload: normalize(response.data, [resourceGroupSchema]),
            })
        );
        return promise;
    },
});
